import { BehaviorSubject } from "rxjs";

import { vec2, mat4 } from "gl-matrix";

import Sprite from "../sprite";
import { loadTexture } from "../helpers/webgl-helpers";

import spriteSheetImageUrl from "../../assets/sprites/chara5.png";
import spriteSheetNormalImageUrl from "../../assets/sprites/chara5-n.png";

import { Directions } from "../interfaces";

export default class Ttam extends Sprite {
  tileIndex: number;
  tileSize: vec2;
  tileSetDimensions: vec2;
  texture: WebGLTexture | null;
  textureUrl: string;
  normalTexture: WebGLTexture | null;
  animations: { [key: string]: number[] };
  animationName$: BehaviorSubject<string>;
  modelMatrix: number[];
  name: string;

  constructor(gl: WebGLRenderingContext) {
    super(gl);
    this.modelMatrix = <number[]>mat4.create();
    this.tileSize = [26, 36];
    this.tileSetDimensions = [312, 288];
    this.tileIndex = 0;
    this.name = "ttam";
    this.textureUrl = spriteSheetImageUrl;
    this.animations = {
      left: [69, 70, 71],
      up: [93, 94, 95],
      down: [57, 58, 59],
      right: [81, 82, 83],
      downIdle: [58],
      upIdle: [94],
      leftIdle: [70],
      rightIdle: [82]
    };
    this.setScale(this.tileSize[0] / this.tileSize[1], 1);
    this.setDirection(Directions.DownIdle);
  }

  async load() {
    const { gl } = this;
    this.texture = await loadTexture(gl, spriteSheetImageUrl);
    this.normalTexture = await loadTexture(gl, spriteSheetNormalImageUrl);
    return true;
  }
}
