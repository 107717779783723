export function randomIndex(arrayToPullFrom: any[]) {
  return Math.floor(Math.random() * arrayToPullFrom.length);
}

export function randomElement(arrayToPullFrom: any[]) {
  return arrayToPullFrom[randomIndex(arrayToPullFrom)];
}

export function removeElementFromArray(array, element) {
  if (!array.includes(element)) return -1;
  return array.splice(array.indexOf(element), 1);
}

export function lastElementFromArray(array) {
  const length = array.length;
  return array[array.length - 1];
}
