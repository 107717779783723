import { fromEvent, Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";

import { Directions, GameState } from "../interfaces";
import { makeMoveCommand, makeInteractionCommand } from "../helpers/input-helpers";
import { pointInRect } from "../helpers/math-helpers";

const playerKeys = ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", " ", "Enter"];
const cameraKeys = ["[", "]"];

export default class KeyboardInput {
  state: GameState;

  private subscriptions: Subscription[];

  constructor(state: GameState) {
    this.state = state;
    const notPaused = filter(() => !state.paused);
    const moveable = filter(() => state.moveable);
    const keyDown$ = fromEvent(document, "keydown").pipe(notPaused, moveable);
    const keyUp$ = fromEvent(document, "keyup").pipe(notPaused, moveable);

    const cameraKeyDown$ = keyDown$.pipe(
      filter((e: KeyboardEvent) => cameraKeys.includes(e.key)),
      map(e => e.key)
    );

    const playerKeyDown$ = keyDown$.pipe(
      filter((e: KeyboardEvent) => playerKeys.includes(e.key)),
      map(e => e.key)
    );

    const playerKeyUp$ = keyUp$.pipe(
      filter((e: KeyboardEvent) => playerKeys.includes(e.key)),
      map(e => e.key)
    );

    this.moveCameraOnKeyDown = this.moveCameraOnKeyDown.bind(this);
    this.movePlayerKeyDown = this.movePlayerKeyDown.bind(this);
    this.movePlayerKeyUp = this.movePlayerKeyUp.bind(this);

    this.subscriptions = [];
    this.subscriptions.push(cameraKeyDown$.subscribe(this.moveCameraOnKeyDown));
    this.subscriptions.push(playerKeyDown$.subscribe(this.movePlayerKeyDown));
    this.subscriptions.push(playerKeyUp$.subscribe(this.movePlayerKeyUp));
  }

  tearDown() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  moveCameraOnKeyDown(key: String) {
    const { viz } = this.state;
    const { camera } = viz;
    switch (key) {
      case "[":
        camera.zoom(0.1);
        break;
      case "]":
        camera.zoom(-0.1);
        break;
    }
  }

  movePlayerKeyUp(key: string) {
    const { state } = this;
    const { currentPlayer, currentTown } = state;

    if (!currentPlayer) {
      console.warn("No current player in movePlayerKeyUp...");
      return;
    }

    switch (key) {
      case "ArrowUp":
        state.input.last = Directions.UpIdle;
        break;
      case "ArrowDown":
        state.input.last = Directions.DownIdle;
        break;
      case "ArrowLeft":
        state.input.last = Directions.LeftIdle;
        break;
      case "ArrowRight":
        state.input.last = Directions.RightIdle;
        break;
    }

    if (key === " " || key === "Enter") {
      if (!currentTown) return;
      const playerPos = currentPlayer.pos;
      const { townPlay } = currentTown;

      const target = townPlay.characters
        .filter(c => c.sprite)
        .filter(c => c.sprite.interactable)
        .filter(c => c.sprite !== currentPlayer)
        .find(c => {
          const pos = c.sprite.pos;
          const size = c.sprite.size;
          const x1 = pos[0] - size[0] / 2;
          const x2 = pos[0] + size[0] / 2;
          const y1 = pos[1] + size[1] / 2;
          const y2 = pos[1] - size[1] / 2;

          return pointInRect(playerPos[0], playerPos[1], x1, y1, x2, y2);
        });

      if (target) makeInteractionCommand(target.sprite, townPlay)();
    }
  }

  movePlayerKeyDown(key: string) {
    const { state } = this;
    const { currentPlayer } = state;

    if (!currentPlayer) {
      console.warn("No current player in movePlayerKeyDown...");
      return;
    }

    switch (key) {
      case "ArrowUp":
        state.input.last = Directions.Up;
        break;
      case "ArrowDown":
        state.input.last = Directions.Down;
        break;
      case "ArrowLeft":
        state.input.last = Directions.Left;
        break;
      case "ArrowRight":
        state.input.last = Directions.Right;
        break;
    }
  }
}
