import { vec2, vec3, vec4 } from "gl-matrix";

import { QUAD_POSITIONS, TILE_TEXTURE_COORDS, QUAD_WIDTH, QUAD_TEXTURE_COORDS } from "../models";
import { GameState } from "../interfaces";
import { state } from "../index";

export function setDisableDepthTesting(gl: WebGLRenderingContext) {
  gl.disable(gl.DEPTH_TEST);
}

export function setDrawToTexture(gl: WebGLRenderingContext, fb: WebGLFramebuffer) {
  gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
}

export function setDrawToScreen(gl: WebGLRenderingContext) {
  gl.bindFramebuffer(gl.FRAMEBUFFER, null);
}

export function clearScreen(gl: WebGLRenderingContext) {
  gl.clearColor(0.0, 0.0, 0.0, 0.0);
  gl.clearDepth(1.0);
  gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
}

export function clearScreenWithColor(gl: WebGLRenderingContext, color: vec4) {
  gl.clearColor(color[0], color[1], color[2], color[3]);
  gl.clearDepth(1.0);
  gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
}

const black: vec4 = [0, 0, 0, 1];
export function clearBlack(gl: WebGLRenderingContext) {
  clearScreenWithColor(gl, black);
}

export function compiledProgram(
  gl: WebGLRenderingContext,
  vertexShader: string,
  fragmentShader: string
) {
  const compiledVertexShader = compileShader(gl, gl.VERTEX_SHADER, vertexShader);
  const compiledFragmentShader = compileShader(gl, gl.FRAGMENT_SHADER, fragmentShader);
  const newProgram = linkShader(gl, compiledVertexShader, compiledFragmentShader);
  return newProgram;
}

// https://developer.mozilla.org/en-US/docs/Web/API/WebGL_API/WebGL_best_practices
// Probably already is enabled, but I'll call this just in case...
export function enableVertexAttribArray0(gl: WebGLRenderingContext) {
  gl.enableVertexAttribArray(0);
}

// modified from https://nickdesaulniers.github.io/RawWebGL/#/51
export function configureBuffer(
  gl: WebGLRenderingContext,
  programName: string,
  gameState: GameState,
  buffer: WebGLBuffer,
  data: ArrayBuffer,
  elemPerVertex: number,
  attributeName: string,
  instanced = false
) {
  const attributeLocation = gameState.programWrappers[programName].cache.attributes[attributeName];
  if (attributeLocation === undefined) console.warn(`No attribute location for ${attributeName}!!`);

  if (!gameState.programWrappers[programName].cache.enabled[attributeLocation]) {
    gl.enableVertexAttribArray(attributeLocation);
    gameState.programWrappers[programName].cache.enabled[attributeLocation] = true;
  }

  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.bufferData(gl.ARRAY_BUFFER, data, gl.STATIC_DRAW);
  gl.vertexAttribPointer(attributeLocation, elemPerVertex, gl.FLOAT, false, 0, 0);
  if (instanced) enableInstancingForLocation(gl, attributeLocation);
}

export function cacheUniformLocations(
  gl: WebGLRenderingContext,
  programName: string,
  program: WebGLProgram,
  gameState: GameState,
  uniformNames: string[]
) {
  uniformNames.forEach(function(name) {
    cacheUniformLocation(gl, programName, program, gameState, name);
  });
}

export function cacheAttributeLocations(
  gl: WebGLRenderingContext,
  programName: string,
  program: WebGLProgram,
  gameState: GameState,
  attributeNames: string[]
) {
  attributeNames.forEach(function(name) {
    cacheAttributeLocation(gl, programName, program, gameState, name);
  });
}

// http://mrdoob.com/projects/glsl_sandbox/
function cacheUniformLocation(
  gl: WebGLRenderingContext,
  programName: string,
  program: WebGLProgram,
  gameState: GameState,
  label: string
) {
  const location = gl.getUniformLocation(program, label);
  gameState.programWrappers[programName].cache.uniforms[label] = location;

  if (!location) {
    console.log(location);
    console.warn(`Uniform ${label} doesn't have a location, so it's probably not going to work...`);
  } else {
    // console.log(`Successfully cached ${label} for program ${programName}...`);
  }
}

function cacheAttributeLocation(
  gl: WebGLRenderingContext,
  programName: string,
  program: WebGLProgram,
  gameState: GameState,
  label: string
) {
  const location = gl.getAttribLocation(program, label);
  gameState.programWrappers[programName].cache.attributes[label] = location;

  if (location === -1) {
    console.log(location);
    console.warn(
      `Attribute ${label} doesn't have a location, so it's probably not going to work...`
    );
  } else {
    // console.log(`Successfully cached ${label} for program ${programName}...`);
  }
}

export function setIndicesBuffer(
  gl: WebGLRenderingContext,
  buffer: WebGLBuffer,
  indices: number[]
) {
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffer);
  gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(indices), gl.STATIC_DRAW);
}

export function setIndicesBufferDirect(
  gl: WebGLRenderingContext,
  buffer: WebGLBuffer,
  indices: Uint16Array
) {
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffer);
  gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, indices, gl.STATIC_DRAW);
}

export function setNormalsAttrib(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  vertices: number[]
) {
  configureBuffer(gl, programName, state, buffer, new Float32Array(vertices), 3, "aVertexNormal");
}

export function setNormalsAttribDirect(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  vertices: Float32Array
) {
  configureBuffer(gl, programName, state, buffer, vertices, 3, "aVertexNormal");
}

export function setTangentsAttrib(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  vertices: number[],
  instanced = false
) {
  configureBuffer(
    gl,
    programName,
    state,
    buffer,
    new Float32Array(vertices),
    3,
    "aVertexTangent",
    instanced
  );
}

export function setTangentsAttribDirect(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  vertices: Float32Array,
  instanced = false
) {
  configureBuffer(gl, programName, state, buffer, vertices, 3, "aVertexTangent", instanced);
}

export function setBitangentsAttrib(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  vertices: number[]
) {
  configureBuffer(
    gl,
    programName,
    state,
    buffer,
    new Float32Array(vertices),
    3,
    "aVertexBitangent"
  );
}

export function setBitangentsAttribDirect(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  vertices: Float32Array
) {
  configureBuffer(gl, programName, state, buffer, vertices, 3, "aVertexBitangent");
}

export function setSelectedsAttribDirect(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  selected: Float32Array
) {
  configureBuffer(gl, programName, state, buffer, selected, 1, "aSelected");
}

export function setCentersAttribDirect(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  center: Float32Array
) {
  configureBuffer(gl, programName, state, buffer, center, 3, "aCenter");
}

export function setOpacitiesAttrib(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  opacities: number[]
) {
  configureBuffer(gl, programName, state, buffer, new Float32Array(opacities), 1, "aOpacity");
}

export function setOffsetsAttribDirect(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  offsets: Float32Array
) {
  configureBuffer(gl, programName, state, buffer, offsets, 1, "aOffset");
}

export function setOpacitiesAttribDirect(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  opacities: Float32Array
) {
  configureBuffer(gl, programName, state, buffer, opacities, 1, "aOpacity");
}

export function setPositionsAttrib(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  vertices: number[]
) {
  configureBuffer(gl, programName, state, buffer, new Float32Array(vertices), 3, "aVertexPosition");
}

export function setPositionsAttribDirect(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  vertices: Float32Array
) {
  configureBuffer(gl, programName, state, buffer, vertices, 3, "aVertexPosition");
}

export function setModelMatrixAttrib(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  modelMatrices: number[]
) {
  const combinedMatrices = new Float32Array(modelMatrices);
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  gl.bufferData(gl.ARRAY_BUFFER, combinedMatrices, gl.STATIC_DRAW);

  const attributeName = "aModelMatrix";
  const attributeLocation = state.programWrappers[programName].cache.attributes[attributeName];
  if (attributeLocation === undefined) console.warn(`No attribute location for ${attributeName}!!`);

  // https://webglfundamentals.org/webgl/lessons/webgl-instanced-drawing.html
  const bytesPerMatrix = 4 * 16;
  for (let i = 0; i < 4; ++i) {
    const loc = attributeLocation + i;
    gl.enableVertexAttribArray(loc);
    // note the stride and offset
    const offset = i * 16; // 4 floats per row, 4 bytes per float
    gl.vertexAttribPointer(
      loc, // location
      4, // size (num values to pull from buffer per iteration)
      gl.FLOAT, // type of data in buffer
      false, // normalize
      bytesPerMatrix, // stride, num bytes to advance to get to next set of values
      offset // offset in buffer
    );
    enableInstancingForLocation(gl, loc);
  }
}

export function enableBlending(gl: WebGLRenderingContext) {
  gl.enable(gl.BLEND);
  gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
}

function enableInstancingForLocation(gl: WebGLRenderingContext, location: number) {
  // this line says this attribute only changes for each 1 instance
  const ext = gl.getExtension("ANGLE_instanced_arrays");
  ext.vertexAttribDivisorANGLE(location, 1);
}

// https://github.com/patriciogonzalezvivo/glslCanvas/issues/8
// https://github.com/KhronosGroup/WebGLDeveloperTools/blob/master/src/debug/webgl-debug.js
// Doing this because Safari for iOS/desktop has issues when switching between programs that
// have instancing to programs that do not...?
export function resetContext(gl: WebGLRenderingContext) {
  let tmpBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, tmpBuffer);
  const numAttribs = gl.getParameter(gl.MAX_VERTEX_ATTRIBS);
  for (let i = 0; i < numAttribs; ++i) {
    gl.disableVertexAttribArray(i);
    gl.vertexAttribPointer(i, 4, gl.FLOAT, false, 0, 0);
    gl.vertexAttrib1f(i, 0);
    disableInstancingForLocation(gl, i);
  }
  gl.deleteBuffer(tmpBuffer);

  const numTextureUnits = gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS);
  for (var ii = 0; ii < numTextureUnits; ++ii) {
    gl.activeTexture(gl.TEXTURE0 + ii);
    gl.bindTexture(gl.TEXTURE_CUBE_MAP, null);
    gl.bindTexture(gl.TEXTURE_2D, null);
  }

  gl.activeTexture(gl.TEXTURE0);
  gl.useProgram(null);
  gl.bindBuffer(gl.ARRAY_BUFFER, null);
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
  gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  gl.bindRenderbuffer(gl.RENDERBUFFER, null);
  gl.disable(gl.BLEND);
  gl.disable(gl.CULL_FACE);
  gl.disable(gl.DEPTH_TEST);
  gl.disable(gl.DITHER);
  gl.disable(gl.SCISSOR_TEST);
  gl.blendColor(0, 0, 0, 0);
  gl.blendEquation(gl.FUNC_ADD);
  gl.blendFunc(gl.ONE, gl.ZERO);
  gl.clearColor(0, 0, 0, 0);
  gl.clearDepth(1);
  gl.clearStencil(-1);
  gl.colorMask(true, true, true, true);
  gl.cullFace(gl.BACK);
  gl.depthFunc(gl.LESS);
  gl.depthMask(true);
  gl.depthRange(0, 1);
  gl.frontFace(gl.CCW);
  gl.hint(gl.GENERATE_MIPMAP_HINT, gl.DONT_CARE);
  gl.lineWidth(1);
  gl.pixelStorei(gl.PACK_ALIGNMENT, 4);
  gl.pixelStorei(gl.UNPACK_ALIGNMENT, 4);
  gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, false);
  gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, false);
}

export function disableInstancingForLocation(gl: WebGLRenderingContext, location: number) {
  // this line says this attribute only changes for each 1 instance
  const ext = gl.getExtension("ANGLE_instanced_arrays");
  ext.vertexAttribDivisorANGLE(location, 0);
}

export function setTextureCoordsAttrib(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  textureCoords: number[],
  instanced = false
) {
  configureBuffer(
    gl,
    programName,
    state,
    buffer,
    new Float32Array(textureCoords),
    2,
    "aTextureCoord",
    instanced
  );
}

export function setTextureCoordsAttribDirect(
  gl: WebGLRenderingContext,
  programName: string,
  state: GameState,
  buffer: WebGLBuffer,
  textureCoords: Float32Array,
  instanced = false
) {
  configureBuffer(gl, programName, state, buffer, textureCoords, 2, "aTextureCoord", instanced);
}

export function clamp(value: number, min: number, max: number) {
  return Math.max(Math.min(value, max), min);
}

export function setHalfPixelTextureCoords(
  textureCoords: number[] | Float32Array,
  inputCoords: number[],
  vertexIndex: number,
  tileIndex: number,
  tileSize: vec2,
  tileSetDimensions: vec2
) {
  const tW = tileSize[0]; // tile width
  const tH = tileSize[1]; // tile height
  const tsW = tileSetDimensions[0]; // width of the tile set
  const tsH = tileSetDimensions[1]; // height of the tile set
  for (let j = 0; j < inputCoords.length; j = j + 2) {
    const x = tileIndex % (tsW / tW); // tile offset in x (0 <= x < # of tiles wide)
    const y = Math.floor((tileIndex * tW) / tsW); // tile offset in y (0 <= y < # of tiles tall)
    const curr = vertexIndex * inputCoords.length + j; // current vertex

    // https://gamedev.stackexchange.com/questions/46963/how-to-avoid-texture-bleeding-in-a-texture-atlas
    // https://docs.microsoft.com/en-us/windows/win32/direct3d9/directly-mapping-texels-to-pixels?redirectedfrom=MSDN
    // https://gamedev.stackexchange.com/questions/74420/seamless-tilemap-rendering-borderless-adjacent-images

    const s = (inputCoords[j + 0] * tW + x * tW + 0.5) / tsW; // u (0 < u < 1)
    const t = (inputCoords[j + 1] * tH + y * tH + 0.5) / tsH; // v (0 < v < 1)

    textureCoords[curr + 0] = s;
    textureCoords[curr + 1] = t;
  }
}

// https://learnopengl.com/Advanced-Lighting/Normal-Mapping
export function createMapTbn(triangle: number[] | Float32Array) {
  const pos1 = triangle.slice(0, 3) as vec3;
  const pos2 = triangle.slice(3, 6) as vec3;
  const pos3 = triangle.slice(6, 9) as vec3;

  const edge1 = vec3.create();
  const edge2 = vec3.create();

  vec3.subtract(edge1, pos2, pos1);
  vec3.subtract(edge2, pos3, pos1);

  const uv1 = TILE_TEXTURE_COORDS.slice(0, 2) as vec2;
  const uv2 = TILE_TEXTURE_COORDS.slice(2, 4) as vec2;
  const uv3 = TILE_TEXTURE_COORDS.slice(4, 6) as vec2;

  const deltaUv1 = vec2.create();
  const deltaUv2 = vec2.create();

  vec2.subtract(deltaUv1, uv2, uv1);
  vec2.subtract(deltaUv2, uv3, uv1);

  const f = 1 / (deltaUv1[0] * deltaUv2[1] - deltaUv2[0] * deltaUv1[1]);

  const tangent = vec3.create();
  vec3.set(
    tangent,
    deltaUv2[1] * edge1[0] - deltaUv1[1] * edge2[0],
    deltaUv2[1] * edge1[1] - deltaUv1[1] * edge2[1],
    deltaUv2[1] * edge1[2] - deltaUv1[1] * edge2[2]
  );

  vec3.scale(tangent, tangent, f);
  vec3.normalize(tangent, tangent);

  const bitangent = vec3.create();

  vec3.set(
    bitangent,
    -deltaUv2[0] * edge1[0] + deltaUv1[0] * edge2[0],
    -deltaUv2[0] * edge1[1] + deltaUv1[0] * edge2[1],
    -deltaUv2[0] * edge1[2] + deltaUv1[0] * edge2[2]
  );

  vec3.scale(bitangent, bitangent, f);
  vec3.normalize(bitangent, bitangent);

  const normal = vec3.create();
  vec3.set(normal, 0, 0, 1);

  return { normal, tangent, bitangent };
}

// https://learnopengl.com/Advanced-Lighting/Normal-Mapping
export function generateTbn() {
  const pos1 = QUAD_POSITIONS.slice(0, 3) as vec3;
  const pos2 = QUAD_POSITIONS.slice(3, 6) as vec3;
  const pos3 = QUAD_POSITIONS.slice(6, 9) as vec3;

  const edge1 = vec3.create();
  const edge2 = vec3.create();

  vec3.subtract(edge1, pos2, pos1);
  vec3.subtract(edge2, pos3, pos1);

  const uv1 = QUAD_TEXTURE_COORDS.slice(0, 2) as vec2;
  const uv2 = QUAD_TEXTURE_COORDS.slice(2, 4) as vec2;
  const uv3 = QUAD_TEXTURE_COORDS.slice(4, 6) as vec2;

  const deltaUv1 = vec2.create();
  const deltaUv2 = vec2.create();

  vec2.subtract(deltaUv1, uv2, uv1);
  vec2.subtract(deltaUv2, uv3, uv1);

  const f = 1 / (deltaUv1[0] * deltaUv2[1] - deltaUv2[0] * deltaUv1[1]);

  const tangent = vec3.create();
  vec3.set(
    tangent,
    deltaUv2[1] * edge1[0] - deltaUv1[1] * edge2[0],
    deltaUv2[1] * edge1[1] - deltaUv1[1] * edge2[1],
    deltaUv2[1] * edge1[2] - deltaUv1[1] * edge2[2]
  );

  vec3.scale(tangent, tangent, f);
  vec3.normalize(tangent, tangent);

  const bitangent = vec3.create();

  vec3.set(
    bitangent,
    -deltaUv2[0] * edge1[0] + deltaUv1[0] * edge2[0],
    -deltaUv2[0] * edge1[1] + deltaUv1[0] * edge2[1],
    -deltaUv2[0] * edge1[2] + deltaUv1[0] * edge2[2]
  );

  vec3.scale(bitangent, bitangent, f);
  vec3.normalize(bitangent, bitangent);

  const normal = vec3.create();
  vec3.set(normal, 0, 0, 1);

  return { normal, tangent, bitangent };
}

export function setQuadPositions(
  positions: number[] | Float32Array,
  x: number,
  y: number,
  index = 0,
  rotation = 0,
  messVec?: vec3
) {
  const i = index;
  for (let j = 0; j < QUAD_POSITIONS.length; j = j + 3) {
    const v = messVec ? messVec : vec3.create();

    vec3.set(
      v,
      QUAD_POSITIONS[j + 0] + x + QUAD_WIDTH / 2,
      QUAD_POSITIONS[j + 1] - y - QUAD_WIDTH / 2,
      QUAD_POSITIONS[j + 2]
    );

    // vec3.rotateY(v, v, [x + QUAD_WIDTH / 2, y, QUAD_POSITIONS[j + 2]], rotation);

    const posIndex = i * QUAD_POSITIONS.length + j;

    positions[posIndex + 0] = v[0];
    positions[posIndex + 1] = v[1];
    positions[posIndex + 2] = v[2];
  }
}

// https://github.com/mdn/webgl-examples/blob/gh-pages/tutorial/sample6/webgl-demo.js
export async function loadTexture(gl: WebGLRenderingContext, url: string) {
  const texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, texture);

  // Because images have to be download over the internet
  // they might take a moment until they are ready.
  // Until then put a single pixel in the texture so we can
  // use it immediately. When the image has finished downloading
  // we'll update the texture with the contents of the image.
  const level = 0;
  const internalFormat = gl.RGBA;
  const width = 1;
  const height = 1;
  const border = 0;
  const srcFormat = gl.RGBA;
  const srcType = gl.UNSIGNED_BYTE;
  const pixel = new Uint8Array([0, 0, 255, 255]); // opaque blue
  gl.texImage2D(
    gl.TEXTURE_2D,
    level,
    internalFormat,
    width,
    height,
    border,
    srcFormat,
    srcType,
    pixel
  );

  const image = new Image();

  return new Promise((resolve, reject) => {
    image.onload = function() {
      gl.bindTexture(gl.TEXTURE_2D, texture);

      gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, image);

      // https://developer.mozilla.org/en-US/docs/Web/API/WebGLRenderingContext/texParameter
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
      gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
      resolve(texture);
    };

    if (state.itchCdnPath) url = `${state.itchCdnPath}${url}`;

    image.src = url;
  });
}

export function resizeFrameBuffer(
  fb: WebGLFramebuffer,
  texture: WebGLTexture,
  gl: WebGLRenderingContext,
  width: number,
  height: number
) {
  setDrawToTexture(gl, fb);
  const attachmentPoint = gl.COLOR_ATTACHMENT0;
  gl.bindTexture(gl.TEXTURE_2D, texture);
  // define size and format of level 0
  const level = 0;
  const internalFormat = gl.RGBA;
  const border = 0;
  const format = gl.RGBA;
  const type = gl.UNSIGNED_BYTE;
  const data = null;
  gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, width, height, border, format, type, data);

  // set the filtering so we don't need mips
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

  gl.framebufferTexture2D(gl.FRAMEBUFFER, attachmentPoint, gl.TEXTURE_2D, texture, level);

  setDrawToScreen(gl);
}

// https://nickdesaulniers.github.io/RawWebGL/#/40
function compileShader(gl: WebGLRenderingContext, type: number, shaderSrc: string) {
  const shader = gl.createShader(type);
  if (!shader) throw Error(`Couldn't create shader...`);
  gl.shaderSource(shader, shaderSrc);
  gl.compileShader(shader);

  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    throw new Error(gl.getShaderInfoLog(shader) || "Couldn't get program info...");
  }

  return shader;
}

// https://nickdesaulniers.github.io/RawWebGL/#/41
function linkShader(
  gl: WebGLRenderingContext,
  vertexShader: WebGLShader,
  fragmentShader: WebGLShader
) {
  const newProgram = gl.createProgram();
  if (!newProgram) throw Error(`Couldn't create new program...`);
  gl.attachShader(newProgram, vertexShader);
  gl.attachShader(newProgram, fragmentShader);
  gl.linkProgram(newProgram);

  if (!gl.getProgramParameter(newProgram, gl.LINK_STATUS)) {
    throw new Error(gl.getProgramInfoLog(newProgram) || "Couldn't get program info...");
  }

  return newProgram;
}
