export const setHidden = setClass("hidden");
export const unsetHidden = unsetClass("hidden");

function setStyle(propName: string, formattingFunction = (v?) => v) {
  return function(el) {
    const args = Array.from(arguments).slice(1, arguments.length);
    args.push(el);
    const formattedValue = formattingFunction(...args);

    if (el.style[propName] !== formattedValue) {
      el.style[propName] = formattedValue;
    }
  };
}

function unsetStyle(propName: string) {
  return function(el) {
    if (!!el.style[propName]) {
      el.style[propName] = null;
    }
  };
}

function setClass(className: string) {
  return function(el) {
    if (!hasClass(el, className)) {
      el.classList.add(className);
    }
  };
}

function unsetClass(className: string) {
  return function(el) {
    if (hasClass(el, className)) {
      el.classList.remove(className);
    }
  };
}

export function hasClass(el: HTMLElement, className: string) {
  return el.classList.contains(className);
}
