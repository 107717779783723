import { BehaviorSubject } from "rxjs";

import { vec2, mat4 } from "gl-matrix";

import Sprite from "../sprite";
import { loadTexture } from "../helpers/webgl-helpers";

import spriteSheetImageUrl from "../../assets/sprites/military2.png";
import spriteSheetNormalImageUrl from "../../assets/sprites/military2-n.png";

import { Directions } from "../interfaces";

export default class Henchperson extends Sprite {
  tileIndex: number;
  tileSize: vec2;
  tileSetDimensions: vec2;
  texture: WebGLTexture | null;
  textureUrl: string;
  normalTexture: WebGLTexture | null;
  animations: { [key: string]: number[] };
  animationName$: BehaviorSubject<string>;
  modelMatrix: number[];
  name: string;
  animationSpeed = 550;

  constructor(gl: WebGLRenderingContext) {
    super(gl);
    this.modelMatrix = <number[]>mat4.create();
    this.tileSize = [26, 36];
    this.tileSetDimensions = [312, 288];
    this.tileIndex = 0;
    this.textureUrl = spriteSheetImageUrl;
    this.name = "henchperson";
    this.animations = {
      left: [15, 16, 17],
      up: [39, 40, 41],
      down: [3, 4, 5],
      right: [27, 28, 29],
      downIdle: [4],
      upIdle: [40],
      leftIdle: [16],
      rightIdle: [28]
    };
    this.setScale(this.tileSize[0] / this.tileSize[1], 1);
    this.setDirection(Directions.DownIdle);
  }

  async load() {
    const { gl } = this;
    this.texture = await loadTexture(gl, spriteSheetImageUrl);
    this.normalTexture = await loadTexture(gl, spriteSheetNormalImageUrl);
    return true;
  }
}
