import { fromEvent, merge } from "rxjs";
import { filter, takeWhile, throttleTime } from "rxjs/operators";
import stringHash from "string-hash";

import { state } from "./index";

import html, { styles } from "./templates/dialogue-box-html";

export default class DialogueBox {
  public div: HTMLElement;

  private words: string;
  private dialogueBox: HTMLElement;
  private dialogueId: string;
  private lastLetterSpan: HTMLSpanElement;

  constructor(words: string, bonusId?: string) {
    this.words = words;
    this.dialogueId = `dialogue-${stringHash(words + bonusId)}`;
  }

  get onPage() {
    return !!document.querySelector(`[data-dialogue-id=${this.dialogueId}]`);
  }

  createBox() {
    this.div = document.createElement("div");
    this.div.dataset.dialogueId = this.dialogueId;
    this.div.insertAdjacentHTML("beforeend", html);
    this.dialogueBox = this.div.querySelector(`.${styles["dialogue-box"]}`);

    const lastIndex = this.words.length - 1;
    this.words.split("").forEach((letter, i) => {
      const span = document.createElement("span");
      span.classList.add(styles["letter"]);
      span.innerText = letter;
      span.style.animationDelay = `${i / 60}s`;
      this.dialogueBox.append(span);
      if (i === lastIndex) this.lastLetterSpan = span;
    });
  }

  append(element: HTMLElement) {
    this.createBox();
    element.append(this.div);
  }

  listenForClicks(callback) {
    const box = this.dialogueBox;
    const params = new URLSearchParams(window.location.search);
    const debug = params.has("debug");
    const el = debug ? box : document.body;
    const notPaused = filter(() => !state.paused);
    const onPage = () => this.onPage;
    const keys = [" ", "Enter"];
    const spaceAndEnter = filter((e: KeyboardEvent) => keys.includes(e.key));
    const mouseUp$ = fromEvent(el, "mouseup");
    const touchEnd$ = fromEvent(el, "touchend");
    const keyUp$ = fromEvent(document, "keyup").pipe(spaceAndEnter);

    // touchEnd causes that weird text problem on ios...
    // const click$ = merge(mouseUp$, touchEnd$, keyUp$).pipe(throttleTime(250), notPaused);
    const click$ = merge(mouseUp$, keyUp$).pipe(throttleTime(250), notPaused);
    click$.pipe(takeWhile(onPage)).subscribe(() => callback());
  }

  remove() {
    // not sure why I need this.div.parentNode...
    if (this.onPage && this.div.parentNode) this.div.parentNode.removeChild(this.div);
  }

  handleClick() {
    const { lastLetterSpan } = this;
    const opacity = parseFloat(window.getComputedStyle(lastLetterSpan).getPropertyValue("opacity"));

    if (opacity < 1.0) {
      const spans = this.dialogueBox.querySelectorAll("span");
      spans.forEach(span => (span.style.animationDelay = ""));
    } else {
      this.remove();
    }
  }
}
