import { BattleCharacter } from "./interfaces";
import { Observable } from "rxjs";
import { filter, mapTo, map, scan, switchMap, takeUntil, withLatestFrom } from "rxjs/operators";

import { round } from "./helpers/math-helpers";

export function characterTimer$(character: BattleCharacter, clock$: Observable<number>) {
  return clock$.pipe(
    mapTo(0.85),
    map(increase => (character.hp > 0 ? increase : 0)),
    // map(increase => (!animationQueue.isQueued(character) ? increase : 0)),
    map(increase => round(Math.min(character.wait + increase, 100)))
  );
}

export function getFilterWithLatestFromOperator(stream$, conditionFunction) {
  return function(input$) {
    return input$.pipe(
      withLatestFrom(stream$),
      filter(([_, value]) => conditionFunction(value)),
      map(([s]) => s)
    );
  };
}

// TODO: maybe delete
export function getIncrementTowardsValueOperator(object, key, maxKey) {
  return function(input$) {
    return input$.pipe(
      scan(acc => {
        const diff = object[key] - acc;
        let inc = object[maxKey] / 240; // Amount to increment by
        if (diff < 0) inc = -inc;
        if (diff === 0) inc = 0;
        return round(Math.min(Math.max(acc + inc, 0), object[maxKey]), 0);
      }, object[key])
    );
  };
}
