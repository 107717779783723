import { vec2 } from "gl-matrix";

import { TileMap, TileSet, TileLayer } from "./interfaces";
import { QUAD_WIDTH } from "./models";
import { walkableNeighborsForTileAtCoordinates as neighbors } from "./helpers/map-helpers";
import { round } from "./helpers/math-helpers";

const HALF_QUAD = QUAD_WIDTH / 2;

// https://www.redblobgames.com/pathfinding/a-star/introduction.html
export default function pathFinder(
  startCoords: vec2,
  destCoords: vec2,
  tileLayer: TileLayer,
  tileMap: TileMap
) {
  const roundedStart: vec2 = [Math.floor(startCoords[0]), Math.ceil(startCoords[1])];
  const roundedDest: vec2 = [round(destCoords[0], 2), round(destCoords[1], 2)];
  const tileDest: vec2 = [Math.floor(destCoords[0]), Math.ceil(destCoords[1])];

  if (roundedStart[0] === tileDest[0] && roundedStart[1] === tileDest[1]) {
    return [roundedDest];
  }

  const frontier = new Queue();
  const cameFrom = {};

  frontier.enqueue(roundedStart);
  cameFrom[vec2index(roundedStart)] = null;

  while (!frontier.isEmpty) {
    const current = frontier.dequeue();
    if (current[0] === tileDest[0] && current[1] === tileDest[1]) break;

    neighbors(current[0], current[1], tileLayer, tileMap).forEach(neighbor => {
      if (!cameFrom[vec2index(neighbor)]) {
        frontier.enqueue(neighbor);
        cameFrom[vec2index(neighbor)] = current;
      }
    });
  }

  if (!cameFrom[vec2index(tileDest)]) {
    console.warn(`can't get to ${tileDest} from ${roundedStart}`);
    return [];
  }

  let current = tileDest;

  let path: vec2[] = [];
  while (current != roundedStart) {
    path.push([current[0] + HALF_QUAD, current[1] - HALF_QUAD]);
    current = cameFrom[vec2index(current)];
  }
  path.reverse();
  path.pop();
  path.push(roundedDest);

  return path;
}

class Queue {
  queue: vec2[];

  constructor() {
    this.queue = [];
  }

  get isEmpty() {
    return this.queue.length === 0;
  }

  get length() {
    return this.queue.length;
  }

  enqueue(point: vec2) {
    this.queue.push(point);
  }

  dequeue() {
    return this.queue.shift();
  }
}

function vec2index(vector: vec2) {
  return `${vector[0]},${vector[1]}`;
}
