import { vec2 } from "gl-matrix";
import Sprite from "../sprite";
import { Directions, TileSet, Tile, TileMap, TileLayer } from "../interfaces";
import { makeMoveCommand } from "./input-helpers";

import { state } from "../index";

export function walkableNeighborsForTileAtCoordinates(
  x: number,
  y: number,
  tileLayer: TileLayer,
  tileMap: TileMap
): vec2[] {
  const width = tileLayer.width;
  const height = tileLayer.height;
  const tileX = xCoordinateForTileFromXWorldCoordinate(x);
  const tileY = yCoordinateForTileFromYWorldCoordinate(y);
  const neighbors = [];

  if (tileX > 0) {
    const leftX = x - 1;
    const leftY = y;
    const left = coordinatesCanBeReachedFromPosition(x, y, leftX, leftY, tileLayer, tileMap);
    if (left) neighbors.push([leftX, leftY]);
  }

  if (tileX < width - 1) {
    const rightX = x + 1;
    const rightY = y;
    const right = coordinatesCanBeReachedFromPosition(x, y, rightX, rightY, tileLayer, tileMap);
    if (right) neighbors.push([rightX, rightY]);
  }

  if (tileY > 0) {
    const upX = x;
    const upY = y + 1;
    const up = coordinatesCanBeReachedFromPosition(x, y, upX, upY, tileLayer, tileMap);
    if (up) neighbors.push([upX, upY]);
  }

  if (tileY < height - 1) {
    const downX = x;
    const downY = y - 1;
    const down = coordinatesCanBeReachedFromPosition(x, y, downX, downY, tileLayer, tileMap);
    if (down) neighbors.push([downX, downY]);
  }

  return neighbors;
}

// export function walkableNeighborsForTileAtCoordinatesGivenDirection(
//   x: number,
//   y: number,
//   tileLayer: TileLayer,
//   tileSet: TileSet,
//   direction?: Directions
// ): vec2[] {
//   const width = tileLayer.width;
//   const height = tileLayer.height;
//   const tileX = xCoordinateForTileFromXWorldCoordinate(x);
//   const tileY = yCoordinateForTileFromYWorldCoordinate(y);
//   const neighbors = [];

//   let leftNeighbor: vec2 = null;
//   let rightNeighbor: vec2 = null;
//   let upNeighbor: vec2 = null;
//   let downNeighbor: vec2 = null;

//   if (tileX > 0) {
//     const leftX = x - 1;
//     const leftY = y;
//     const left = coordinatesCanBeReachedFromPosition(x, y, leftX, leftY, tileLayer, tileSet);
//     if (left) leftNeighbor = [leftX, leftY];
//   }

//   if (tileX < width - 1) {
//     const rightX = x + 1;
//     const rightY = y;
//     const right = coordinatesCanBeReachedFromPosition(x, y, rightX, rightY, tileLayer, tileSet);
//     if (right) rightNeighbor = [rightX, rightY];
//   }

//   if (tileY > 0) {
//     const upX = x;
//     const upY = y + 1;
//     const up = coordinatesCanBeReachedFromPosition(x, y, upX, upY, tileLayer, tileSet);
//     if (up) upNeighbor = [upX, upY];
//   }

//   if (tileY < height - 1) {
//     const downX = x;
//     const downY = y - 1;
//     const down = coordinatesCanBeReachedFromPosition(x, y, downX, downY, tileLayer, tileSet);
//     if (down) downNeighbor = [downX, downY];
//   }

//   if (direction) {
//     switch (direction) {
//       case Directions.Left:
//         pushCurrentDirectionToTheFront(leftNeighbor);
//         leftNeighbor = null;
//         break;
//       case Directions.Right:
//         pushCurrentDirectionToTheFront(rightNeighbor);
//         rightNeighbor = null;
//         break;
//       case Directions.Down:
//         pushCurrentDirectionToTheFront(downNeighbor);
//         downNeighbor = null;
//         break;
//       case Directions.Up:
//         pushCurrentDirectionToTheFront(upNeighbor);
//         upNeighbor = null;
//         break;
//       default:
//         console.warn("Unknown direction in map-helpers walkable neighbors...");
//     }
//   }

//   if (leftNeighbor) neighbors.push(leftNeighbor);
//   if (rightNeighbor) neighbors.push(rightNeighbor);
//   if (downNeighbor) neighbors.push(downNeighbor);
//   if (upNeighbor) neighbors.push(upNeighbor);

//   function pushCurrentDirectionToTheFront(neighborToPush) {
//     if (!neighborToPush) return;
//     let copy: vec2 = vec2.create();
//     vec2.copy(copy, neighborToPush);
//     neighbors.push(copy);
//   }

//   return neighbors;
// }

export function coordinatesCanBeReachedFromPosition(
  toX: number,
  toY: number,
  fromX: number,
  fromY: number,
  tileLayer: TileLayer,
  tileMap: TileMap
) {
  const fromTile = tileFromCoordinates(fromX, fromY, tileLayer, tileMap);
  const toTile = tileFromCoordinates(toX, toY, tileLayer, tileMap);
  if (!toTile) return false;

  const bothWalkable = isWalkable(fromTile) && isWalkable(toTile);
  const sameLevel = isSameLevelOffGround(fromTile, toTile);

  // Can be reached if both tiles are walkable and at the same height
  // e.g. don't have to go up stairs to get to the tile
  if (bothWalkable && sameLevel) return true;

  // One (or both) of the tiles isn't walkable by one of the tiles is climbable
  // e.g. stairs
  const destinationIsClimbableOrWalkable = isClimbable(toTile) || isWalkable(toTile);
  if (!bothWalkable && destinationIsClimbableOrWalkable) return true;
  return false;
}

export function xCoordinateForTileFromXWorldCoordinate(x: number) {
  if (x < 0) console.warn(`x is less than 0, so clicking out of bounds...`);
  return Math.floor(x); // Fudge is to move the point to the feet
}

export function yCoordinateForTileFromYWorldCoordinate(y: number) {
  if (y > 0) console.warn(`y is greater than 0, so clicking out of bounds...`);
  return Math.floor(Math.abs(y)); // Fudge is to move the point to the feet
}

export function tileIndexFromCoordinates(x: number, y: number, tileLayer: TileLayer) {
  const iFromWidth = xCoordinateForTileFromXWorldCoordinate(x);
  const iFromHeight = yCoordinateForTileFromYWorldCoordinate(y) * tileLayer.width;
  return iFromHeight + iFromWidth;
}

export function tileFromCoordinates(x, y, tileLayer: TileLayer, tileMap: TileMap) {
  // subtracting by the firstgid is weird, but... I guess I have to?
  // https://doc.mapeditor.org/en/stable/reference/json-map-format/
  const index = tileIndexFromCoordinates(x, y, tileLayer);
  return tileFromTileIndex(index, tileLayer, tileMap);
}

function tileFromTileIndex(index: number, tileLayer: TileLayer, tileMap: TileMap) {
  const tileIndex = tileLayer.data[index];
  if (!tileIndex) return null;
  const tileSet = tileMap.tilesets.find(ts => tileIndex >= ts.firstgid);
  return tileSet.tiles[tileIndex - tileSet.firstgid];
}

export function isSameLevelOffGround(tile1: Tile, tile2: Tile) {
  return levelOffGround(tile1) === levelOffGround(tile2);
}

function levelOffGround(tile: Tile) {
  return propValueFromName(tile, "levelOffGround");
}

export function isReachable(tile: Tile) {
  return isWalkable(tile) || isClimbable(tile);
}

export function isWalkable(tile: Tile) {
  return propValueFromName(tile, "walkable");
}

export function isClimbable(tile: Tile) {
  return propValueFromName(tile, "climbable");
}

function propValueFromName(tile: Tile, propName: string) {
  if (!tile) {
    console.warn(`No tile when checking prop value ${propName}...`);
    return;
  }

  const prop = tile.properties.find(prop => prop.name === propName);
  if (!prop) console.warn(`No prop on tile ${tile}...`);
  return prop ? prop.value : null;
}

export function outOfBounds(x: number, y: number, tileMap: TileMap) {
  return x < 0 || x > tileMap.width || y > 0 || Math.abs(y) > tileMap.height;
}

export function moveCharacterAlongPath(character: Sprite, path, callback?: () => void) {
  if (path.length < 1) return;
  const next = path[0];
  const pX = character.pos[0];

  // an attempt to work with positionIsUnReachable in Sprite to keep player's butts out of the floor
  // const pY = character.pos[1] - character.size[1] / 2;
  const pY = character.pos[1];
  const nX = next[0];
  const nY = next[1];

  const closeEnoughX = closeEnough(pX, nX);
  const closeEnoughY = closeEnough(pY, nY);

  if (closeEnoughX && closeEnoughY) {
    path.shift();
    character.updatePos(nX, nY);
    if (path.length === 0) {
      character.switchToIdleAnimation();
      if (callback) callback();
    }
    return;
  }

  if (pX > next[0] && !closeEnoughX) {
    makeMoveCommand(character, Directions.Left, state)();
  } else if (pX < next[0] && !closeEnoughX) {
    makeMoveCommand(character, Directions.Right, state)();
  } else if (pY > next[1] && !closeEnoughY) {
    makeMoveCommand(character, Directions.Down, state)();
  } else if (pY < next[1] && !closeEnoughY) {
    makeMoveCommand(character, Directions.Up, state)();
  }
}

function closeEnough(num1, num2) {
  const epsilon = Sprite.MOVE_AMOUNT * state.playbackSpeed;
  return Math.abs(num1 - num2) <= epsilon;
}
