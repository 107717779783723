import { state } from "./index";
import Sprite from "./sprite";

import { NUM_VERTS_PER_QUAD } from "./models";

import {
  compiledProgram,
  cacheAttributeLocations,
  cacheUniformLocations,
  enableBlending,
  setPositionsAttrib,
  setTextureCoordsAttrib,
  setBitangentsAttribDirect,
  setTangentsAttribDirect,
  setNormalsAttribDirect,
  setOpacitiesAttribDirect,
  setSelectedsAttribDirect,
  setCentersAttribDirect
} from "./helpers/webgl-helpers";

const UNIFORM_NAMES = [
  "uModelMatrix",
  "uViewMatrix",
  "uProjectionMatrix",
  "uSpriteSampler",
  "uNormalSampler",
  "uTime",
  "uOpacity",
  "uCheckOutOfBounds",
  "uDayLevel",
  "uLightPositions",
  "uLightDropoffs",
  "uLightColors",
  "uBillboard"
];

const ATTRIBUTE_NAMES = [
  "aVertexPosition",
  "aVertexNormal",
  "aVertexTangent",
  "aVertexBitangent",
  "aTextureCoord",
  "aOpacity",
  "aSelected",
  "aCenter"
];

import { QUAD_POSITIONS, FUDGED_QUAD_TEXTURE_COORDS } from "./models";

import TILE_VERT_SHADER from "./shaders/tile-vertex.glsl";
import SPRITE_FRAG_SHADER from "./shaders/sprite-fragment.glsl";

const DEFAULT_OPTIONS: SpriteRendererOptions = {
  billboard: false
};

export default class SpriteRenderer {
  static VERTEX_POSITIONS_LENGTH = QUAD_POSITIONS.length;
  static TEXTURE_COORDS_LENGTH = FUDGED_QUAD_TEXTURE_COORDS.length;
  static OPACITIES_LENGTH = NUM_VERTS_PER_QUAD;
  static SELECTEDS_LENGTH = NUM_VERTS_PER_QUAD;
  static CENTERS_LENGTH = NUM_VERTS_PER_QUAD * 3;
  static MODEL_MATRIX_LENGTH = 16;
  static NORMAL_LENGTH = 36;
  static TANGENT_LENGTH = 36;
  static BITANGENT_LENGTH = 36;

  public sprites: Sprite[];

  public vertexPositions: number[] = [];
  public outlineVertexPositions: number[] = [];
  public modelMatrices: number[] = [];
  public textureCoords: number[] = [];
  public vertexNormals: Float32Array = new Float32Array();
  public vertexTangents: Float32Array = new Float32Array();
  public vertexBitangents: Float32Array = new Float32Array();
  public opacities: Float32Array = new Float32Array();
  public selecteds: Float32Array = new Float32Array();
  public centers: Float32Array = new Float32Array();
  public lightPositions: Float32Array;
  public lightColors: Float32Array;
  public lightDropoffs: Float32Array;
  public dayLevel: number;
  public checkOutOfBounds: boolean;
  public opacity: number;

  private billboard: boolean;
  private gl: WebGLRenderingContext;
  private programName: string;
  private buffers: {
    positions: WebGLBuffer | null;
    normals: WebGLBuffer | null;
    tangents: WebGLBuffer | null;
    bitangents: WebGLBuffer | null;
    textureCoords: WebGLBuffer | null;
    modelMatrices: WebGLBuffer | null;
    opacities: WebGLBuffer | null;
    selecteds: WebGLBuffer | null;
    centers: WebGLBuffer | null;
  };

  constructor(
    gl: WebGLRenderingContext,
    programName: string,
    lightBag: LightBag,
    options: SpriteRendererOptions = {}
  ) {
    this.gl = gl;
    this.programName = programName;
    this.sprites = [];
    this.buffers = {
      positions: gl.createBuffer(),
      textureCoords: gl.createBuffer(),
      normals: gl.createBuffer(),
      tangents: gl.createBuffer(),
      bitangents: gl.createBuffer(),
      modelMatrices: gl.createBuffer(),
      opacities: gl.createBuffer(),
      selecteds: gl.createBuffer(),
      centers: gl.createBuffer()
    };

    this.lightPositions = lightBag.lightPositions;
    this.lightColors = lightBag.lightColors;
    this.lightDropoffs = lightBag.lightDropoffs;
    this.dayLevel = lightBag.dayLevel;
    this.checkOutOfBounds = lightBag.checkOutOfBounds;
    this.billboard = "billboard" in options ? options.billboard : DEFAULT_OPTIONS.billboard;
    this.opacity = 1.0;
    this.configureProgram();
  }

  public removeAll() {
    clearArray(this.sprites);
    clearSpriteRenderer(this.sprites);
  }

  // Sort by the array texture so we can hopefully batch all the
  // draw calls with the same texture in a row
  public add(sprite: Sprite) {
    sprite.billboard = this.billboard;
    this.sprites.push(sprite);
    sprite.spriteRenderer = this;
  }

  public sort() {
    // The textureURL comparison is essentially random
    // the currentPlayer check is to keep the currentPlayer on top of everything else
    this.sprites.sort((a, b) => {
      const aCurrent = a === state.currentPlayer ? "Z" : "A";
      const bCurrent = b === state.currentPlayer ? "Z" : "A";
      const aKey = aCurrent + a.spriteRenderRenderOrderHint + a.textureUrl;
      const bKey = bCurrent + b.spriteRenderRenderOrderHint + b.textureUrl;

      if (aKey == bKey) return 0;

      if (aKey > bKey) {
        return 1;
      } else {
        return -1;
      }
    });

    this.vertexBitangents = new Float32Array(this.sprites.length * SpriteRenderer.BITANGENT_LENGTH);
    this.vertexTangents = new Float32Array(this.sprites.length * SpriteRenderer.TANGENT_LENGTH);
    this.vertexNormals = new Float32Array(this.sprites.length * SpriteRenderer.NORMAL_LENGTH);
    this.selecteds = new Float32Array(this.sprites.length * SpriteRenderer.SELECTEDS_LENGTH);
    this.opacities = new Float32Array(this.sprites.length * SpriteRenderer.OPACITIES_LENGTH);
    this.centers = new Float32Array(this.sprites.length * SpriteRenderer.CENTERS_LENGTH);

    this.sprites.forEach(sprite => {
      const index = this.sprites.indexOf(sprite);
      sprite.spriteRendererIndex = index;
      sprite.setTbnOnSpriteRenderer();
      sprite.setTextureCoordinatesOnSpriteRenderer();
      sprite.setVertexPositionsOnSpriteRenderer();
      sprite.setOpacitiesOnSpriteRenderer();
      sprite.setSelectedOnSpriteRenderer();
      sprite.setCenterOnSpriteRenderer();
    });
  }

  public renderNoGarbage(timestamp: number) {
    if (this.sprites.length === 0) return;

    const { gl, buffers, programName } = this;
    const { programWrappers } = state;

    const programWrapper = programWrappers[programName];
    const spriteProgram = programWrapper.program;
    const { cache } = programWrapper;

    if (!buffers["positions"]) throw Error(`No positions buffer...`);
    if (!buffers["textureCoords"]) throw Error(`No texture coordinates buffer...`);

    let activeTextureUrl: string = this.sprites[0].textureUrl;
    let activeTexture: WebGLTexture = this.sprites[0].texture;
    let activeNormalTexture: WebGLTexture = this.sprites[0].normalTexture;
    let startIndex = 0;
    let endIndex = 0;

    function bindActiveTexture() {
      gl.useProgram(spriteProgram);
      gl.activeTexture(gl.TEXTURE0);
      gl.bindTexture(gl.TEXTURE_2D, activeTexture);
      gl.activeTexture(gl.TEXTURE1);
      gl.bindTexture(gl.TEXTURE_2D, activeNormalTexture);
      gl.uniform1i(cache.uniforms.uSpriteSampler, 0);
      gl.uniform1i(cache.uniforms.uNormalSampler, 1);
    }

    for (let i = 0; i < this.sprites.length; i++) {
      const sprite = this.sprites[i];

      if (activeTextureUrl !== sprite.textureUrl) {
        bindActiveTexture();
        endIndex = i - 1;
        this.drawNoGarbage(startIndex, endIndex);

        startIndex = i;
      }

      if (sprite.texture) {
        activeTextureUrl = sprite.textureUrl;
        activeTexture = sprite.texture;
        activeNormalTexture = sprite.normalTexture;
      } else {
        console.warn(`No texture for ${sprite.name}`);
      }
    }

    bindActiveTexture();
    this.drawNoGarbage(startIndex, this.sprites.length - 1);
  }

  configureProgram() {
    const { gl } = this;
    const spriteProgram = compiledProgram(gl, TILE_VERT_SHADER, SPRITE_FRAG_SHADER);

    state.programWrappers[this.programName] = {
      program: spriteProgram,
      cache: { program: spriteProgram, attributes: {}, enabled: {}, uniforms: {} },
      buffers: {
        positions: gl.createBuffer(),
        normals: gl.createBuffer(),
        textureCoords: gl.createBuffer(),
        tangents: gl.createBuffer(),
        bitangents: gl.createBuffer()
      }
    };

    cacheAttributeLocations(gl, this.programName, spriteProgram, state, ATTRIBUTE_NAMES);
    cacheUniformLocations(gl, this.programName, spriteProgram, state, UNIFORM_NAMES);
  }

  prepareProgram(programName: string) {
    const { gl, lightColors, lightDropoffs, lightPositions } = this;
    const { programWrappers } = state;

    const { cache } = programWrappers[programName];
    const { viz } = state;
    const { projectionMatrix, modelMatrix, viewMatrix } = viz;
    enableBlending(gl);
    gl.uniform1f(cache.uniforms.uOpacity, this.opacity);
    gl.uniform1f(cache.uniforms.uBillboard, this.billboard ? 1.0 : 0.0);
    gl.uniform1f(cache.uniforms.uDayLevel, this.dayLevel);
    gl.uniform1i(cache.uniforms.uCheckOutOfBounds, this.checkOutOfBounds ? 1 : 0);
    gl.uniform4fv(cache.uniforms.uLightColors, lightColors);
    gl.uniform3fv(cache.uniforms.uLightPositions, lightPositions);

    gl.uniform1fv(cache.uniforms.uLightDropoffs, lightDropoffs);
    gl.uniformMatrix4fv(cache.uniforms.uProjectionMatrix, false, projectionMatrix);
    gl.uniformMatrix4fv(cache.uniforms.uModelMatrix, false, modelMatrix);
    gl.uniformMatrix4fv(cache.uniforms.uViewMatrix, false, viewMatrix);
    gl.uniform1f(cache.uniforms.uTime, state.currentTime / 200);
  }

  drawNoGarbage(startIndex: number, endIndex: number) {
    const { gl, buffers } = this;
    const { programWrappers } = state;

    let programName = this.programName;

    const {
      vertexPositions,
      textureCoords,
      vertexNormals,
      vertexTangents,
      vertexBitangents,
      opacities,
      selecteds,
      centers
    } = this;

    const programWrapper = programWrappers[programName];
    const { program } = programWrapper;
    gl.useProgram(program);
    this.prepareProgram(programName);

    const lastSliceIndex = endIndex + 1;

    const vertexPositionSlices = vertexPositions.slice(
      startIndex * SpriteRenderer.VERTEX_POSITIONS_LENGTH,
      lastSliceIndex * SpriteRenderer.VERTEX_POSITIONS_LENGTH
    );

    const textureCoordsSlices = textureCoords.slice(
      startIndex * SpriteRenderer.TEXTURE_COORDS_LENGTH,
      lastSliceIndex * SpriteRenderer.TEXTURE_COORDS_LENGTH
    );

    const opacitySlices = opacities.subarray(
      startIndex * SpriteRenderer.OPACITIES_LENGTH,
      lastSliceIndex * SpriteRenderer.OPACITIES_LENGTH
    );

    const selectedSlices = selecteds.subarray(
      startIndex * SpriteRenderer.SELECTEDS_LENGTH,
      lastSliceIndex * SpriteRenderer.SELECTEDS_LENGTH
    );

    const centerSlices = centers.subarray(
      startIndex * SpriteRenderer.CENTERS_LENGTH,
      lastSliceIndex * SpriteRenderer.CENTERS_LENGTH
    );

    const normalSlices = vertexNormals.subarray(
      startIndex * SpriteRenderer.NORMAL_LENGTH,
      lastSliceIndex * SpriteRenderer.NORMAL_LENGTH
    );

    const tangentSlices = vertexTangents.subarray(
      startIndex * SpriteRenderer.TANGENT_LENGTH,
      lastSliceIndex * SpriteRenderer.TANGENT_LENGTH
    );

    const bitangentSlices = vertexBitangents.subarray(
      startIndex * SpriteRenderer.BITANGENT_LENGTH,
      lastSliceIndex * SpriteRenderer.BITANGENT_LENGTH
    );

    setPositionsAttrib(gl, programName, state, buffers.positions, vertexPositionSlices);
    setTextureCoordsAttrib(gl, programName, state, buffers.textureCoords, textureCoordsSlices);
    setOpacitiesAttribDirect(gl, programName, state, buffers.opacities, opacitySlices);
    setSelectedsAttribDirect(gl, programName, state, buffers.selecteds, selectedSlices);
    setCentersAttribDirect(gl, programName, state, buffers.centers, centerSlices);
    setNormalsAttribDirect(gl, programName, state, buffers.normals, normalSlices);
    setTangentsAttribDirect(gl, programName, state, buffers.tangents, tangentSlices);
    setBitangentsAttribDirect(gl, programName, state, buffers.bitangents, bitangentSlices);
    gl.drawArrays(gl.TRIANGLES, 0, vertexPositionSlices.length / 3);
  }
}

function clearArray(array) {
  array.splice(0, array.length);
}

function clearSpriteRenderer(array: Sprite[]) {
  array.forEach(sprite => {
    sprite.spriteRenderer = null;
    sprite.spriteRendererIndex = null;
  });
}

interface LightBag {
  lightPositions: Float32Array;
  lightColors: Float32Array;
  lightDropoffs: Float32Array;
  dayLevel: number;
  checkOutOfBounds: boolean;
}

interface SpriteRendererOptions {
  billboard?: boolean;
}
