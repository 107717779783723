import { vec2 } from "gl-matrix";
import { fromEvent, merge, Observable, Subscription } from "rxjs";
import { filter, throttleTime } from "rxjs/operators";
import Hammer from "hammerjs";

import pathFinder from "../path-finder";
import { tileFromCoordinates, outOfBounds, isReachable } from "../helpers/map-helpers";
import { pointInRect } from "../helpers/math-helpers";
import { unprojectPoint } from "../helpers/input-helpers";
import { GameState } from "../interfaces";

export default class PointerInputer {
  state: GameState;

  private subscriptions: Subscription[];

  constructor(state: GameState) {
    this.state = state;

    const hammertime = new Hammer(document.body);
    hammertime.get("pinch").set({ enable: true });

    const notPaused = filter(() => !state.paused);
    const moveable = filter(() => state.moveable);
    const mouseUp$ = fromEvent(document.body, "mouseup");
    const touchEnd$ = fromEvent(document.body, "touchend");
    const wheel$ = fromEvent(document.body, "wheel").pipe(moveable);
    const click$ = merge(mouseUp$, touchEnd$).pipe(notPaused, moveable, throttleTime(250));
    this.handleTap = this.handleTap.bind(this);
    this.zoom = this.zoom.bind(this);
    this.wheel = this.wheel.bind(this);

    const pinch$ = new Observable(subscriber => {
      hammertime.on("pinch", (e: any) => {
        const mult = e.additionalEvent === "pinchin" ? 1 : -1;
        subscriber.next(mult * e.distance);
      });
    }).pipe(notPaused, moveable);

    this.subscriptions = [];
    this.subscriptions.push(click$.subscribe(this.handleTap));
    this.subscriptions.push(pinch$.subscribe(this.zoom));
    this.subscriptions.push(wheel$.subscribe(this.wheel));
  }

  tearDown() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  handleTap(event: any) {
    let touch = event;
    let target = null;
    const { currentTown, tileLayer, tileMap, currentPlayer, viz, playerPath } = this.state;

    const { inverseViewProjectionMatrix, camera } = viz;

    if (event.touches) touch = event.changedTouches[0];
    const point: vec2 = [touch.clientX, touch.clientY];
    const worldCoords = unprojectPoint(
      document.body,
      point,
      0,
      inverseViewProjectionMatrix,
      camera.vector
    );

    if (currentTown) {
      currentTown.playerTarget = null;
      const worldX = worldCoords[0];
      const worldY = worldCoords[1];
      target = currentTown.townPlay.characters
        .filter(c => c.sprite)
        .filter(c => c.sprite.interactable)
        .find(character => {
          const pos = character.sprite.pos;
          const size = character.sprite.size;
          const x1 = pos[0] - size[0] / 2;
          const x2 = pos[0] + size[0] / 2;
          const y1 = pos[1] + size[1] / 2;
          const y2 = pos[1] - size[1] / 2;
          return pointInRect(worldX, worldY, x1, y1, x2, y2);
        });

      if (target) currentTown.playerTarget = target.sprite;
    }

    console.log("World coords: ", worldCoords);

    if (!currentPlayer) {
      console.warn("No current player...");
      return;
    }

    const tile = tileFromCoordinates(worldCoords[0], worldCoords[1], tileLayer, tileMap);

    const dest = vec2.create();
    vec2.set(dest, worldCoords[0], worldCoords[1]);

    if (target && target.sprite) {
      vec2.set(dest, target.sprite.pos[0], target.sprite.pos[1]);
    }

    if (outOfBounds(worldCoords[0], worldCoords[1], tileMap)) {
      console.log("Clicked outside the bounds...", worldCoords);
      return;
    }

    if (!isReachable(tile)) {
      console.warn("Tile isn't reachable...");
      return;
    }

    // const startPos: vec2 = [currentPlayer.pos[0], currentPlayer.pos[1] - currentPlayer.size[1] / 2];
    const startPos: vec2 = [currentPlayer.pos[0], currentPlayer.pos[1]];

    const path = pathFinder(startPos, dest, tileLayer, tileMap);

    // if there's a target, then remove the the stop right before the last tile
    // if (target) path.pop();

    playerPath.splice(0, playerPath.length);
    playerPath.push(...path);
  }

  zoom(distance: number) {
    const { viz } = this.state;
    const { camera } = viz;
    camera.zoom(distance / 200);
  }

  wheel(event: WheelEvent) {
    const { deltaY } = event;

    const { viz } = this.state;
    const { camera } = viz;
    camera.zoom(deltaY / 200);
  }
}
