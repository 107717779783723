import Town from "../town";
import TownPlay from "../town-play";
import { GamePage } from "../interfaces";
import { state } from "../index";

import { lockCameraPosition$ } from "../town-observables";
import Ttam from "../sprites/ttam";

import sharesburgTileMap from "../../assets/maps/test-map.json";

export default class Sharesburg extends Town implements GamePage {
  name = "sharesburg";
  tileMap = sharesburgTileMap;

  async load(params: URLSearchParams) {
    await super.load(params);

    this.resize();

    const { gameGl } = this;

    const ttam: Ttam = new Ttam(gameGl);
    state.currentPlayer = ttam;
    await state.currentPlayer.load();
    this.townPlay = new TownPlay();
    const cameraLoop$ = lockCameraPosition$(state.viz.camera, state.currentPlayer);

    this.townPlay.addCharacter("camera", state.viz.camera, cameraLoop$);
    this.spriteRenderer.add(ttam);
    state.currentPlayer.updatePos(5, -2);

    this.dayLevel = 0.9;
    this.lightPositions[0] = 5;
    this.lightPositions[1] = -5;
    this.lightPositions[2] = 1.0;
    this.lightColors[0] = 1.0;
    this.lightColors[1] = 0.5;
    this.lightColors[2] = 0.5;
    this.lightDropoffs[0] = 1.5;
    this.spriteRenderer.sort();

    this.loaded = true;
  }
}
