import Sprite from "../sprite";
import { Directions, GameState, Positionable } from "../interfaces";
import { vec2, vec3, mat4 } from "gl-matrix";
import TownPlay from "../town-play";

export function makeMoveCommand(actor: Sprite, direction: Directions, state: GameState) {
  return () => {
    const { tileLayer, tileSet, tileMap } = state;
    actor.move(direction, tileLayer, tileSet, tileMap);
  };
}

export function makeInteractionCommand(playerTarget: Positionable, townPlay: TownPlay) {
  return () => {
    if (!playerTarget) return;
    if (!playerTarget.interactable) return;
    const target = townPlay.characters
      .filter(c => c.sprite)
      .filter(c => c.sprite.interactable)
      .find(c => playerTarget === c.sprite);

    if (target.interaction) target.interaction();
  };
}

// https://stackoverflow.com/questions/13055214/mouse-canvas-x-y-to-three-js-world-x-y-z
export function unprojectPoint(
  element: HTMLElement,
  point: vec2,
  z: number,
  inverseViewProjMat: mat4,
  camera: vec3
) {
  const screenX = point[0];
  const screenY = point[1];
  const x = (screenX / element.clientWidth) * 2 - 1;
  const y = -(screenY / element.clientHeight) * 2 + 1;

  const coords: vec3 = [x, y, 0];
  const worldCoords = vec3.create();

  vec3.transformMat4(worldCoords, coords, inverseViewProjMat);
  vec3.subtract(worldCoords, worldCoords, camera);
  vec3.normalize(worldCoords, worldCoords);
  const distance = (z - camera[2]) / worldCoords[2];
  vec3.scale(worldCoords, worldCoords, distance);
  vec3.add(worldCoords, worldCoords, camera);
  // console.log("camera: ", camera);
  // console.log("screen: ", point);
  // console.log("clip: ", [x, y]);
  // console.log("world: ", worldCoords);
  return worldCoords;
}
