import { GamePage } from "../interfaces";
import TitleMenu from "./title-menu";

import html from "./loading-html";

let prevTime = 0;

export default class Loading implements GamePage {
  public menu: HTMLElement;
  public loaded: boolean;
  public tornDown: boolean;
  public routerId: string;
  public name: string = "loading";

  private dots: HTMLSpanElement;
  private titleMenu: TitleMenu;

  constructor() {
    this.menu = document.createElement("div");
    this.menu.classList.add("loading-wrapper");
    this.menu.insertAdjacentHTML("beforeend", html);
    this.dots = this.menu.querySelector("span");

    this.routerId = "";
    this.loaded = false;
    this.tornDown = false;
    this.titleMenu = new TitleMenu();
  }

  configurePrograms() {
    this.titleMenu.configurePrograms();
  }

  tearDown() {
    console.log("Tearing down loading menu...");
    this.tornDown = true;
    this.titleMenu.tearDown();
  }

  tick(timestamp: number) {
    this.titleMenu.tick(timestamp);

    const diff = timestamp - prevTime;

    if (diff < 250) return;
    prevTime = timestamp;

    let length = this.dots.innerText.length + 1;
    if (length > 3) length = 0;
    const loadingDots = new Array(length).fill(".").join("");
    this.dots.innerText = loadingDots;
  }

  start() {
    console.log("Loading page starting...");
  }

  async load(params: URLSearchParams) {
    await this.titleMenu.load(params);
    this.loaded = true;
  }
}
