import { vec2, vec3 } from "gl-matrix";
import { Observable, of } from "rxjs";
import { takeWhile, repeat, tap, switchMap, delay, takeUntil } from "rxjs/operators";
import Twarn from "./twarn";
import stringHash from "string-hash";
import { v4 as uuidv4 } from "uuid";

import GameSaver from "./game-saver";
import { Positionable, TileLayer, TileMap } from "./interfaces";
import Sprite from "./sprite";
import Camera from "./camera";
import DialogueBox from "./dialogue-box";
import ThatsIt from "./thats-it";
import { moveCharacterAlongPath } from "./helpers/map-helpers";
import pathFinder from "./path-finder";
import AudioPlayback from "./audio-playback";
import TownPlay from "./town-play";

const TRANSLATE_SPEED = 1000;

function setEventHappened(context: EventContext, key: string, value = true) {
  context[key] = value;
}

export function sendAnalyticsEvent$(eventContext: EventContext, eventName: string) {
  if (eventContext[eventName]) return of(true);
  return of(true).pipe(tap(() => {
    if (window.plausible) window.plausible(eventName);
    setEventHappened(eventContext, eventName)
  }));
}

export function saveToLocalStorage$(gameSaver: GameSaver): Observable<any> {
  return new Observable(observer => {
    gameSaver.saveCurrentGameToFirstSaveLocation();
    observer.next(true);
    observer.complete();
  });
}

export function saveToLocalStorageEvent$(
  eventContext: EventContext,
  key: string,
  gameSaver: GameSaver
): Observable<any> {
  if (eventContext[key]) return of(true);
  return saveToLocalStorage$(gameSaver).pipe(tap(() => setEventHappened(eventContext, key)));
}

export function dialogueInteractionEventFn(
  eventContext: EventContext,
  eventName: string,
  words: string,
  element: HTMLElement
): () => void {
  return () =>
    dialogueEvent$(
      eventContext,
      words,
      element,
      `interaction-event-${uuidv4()}`,
      eventName
    ).subscribe();
}

export function dialogueInteractionFn(
  words: string,
  element: HTMLElement,
  bonusId: string = ""
): () => void {
  return () => dialogueBox$(words, element, bonusId).subscribe();
}

export function delayEvent$(
  eventContext: EventContext,
  key: string,
  delayAmount: number
): Observable<any> {
  if (eventContext[key]) return of(true);
  return of(true)
    .pipe(delay(delayAmount))
    .pipe(tap(() => setEventHappened(eventContext, key)));
}

export function dialogueEventAfter$(
  eventContext: EventContext,
  words: string,
  element: HTMLElement,
  afterEventKey: string,
  time$: Observable<number>,
  bonusId?: string,
  eventName?: string
): Observable<any> {
  return waitFor$(eventContext, afterEventKey, true, time$).pipe(
    switchMap(() => dialogueEvent$(eventContext, words, element, bonusId, eventName))
  );
}

export function dialogueEvent$(
  eventContext: EventContext,
  words: string,
  element: HTMLElement,
  bonusId?: string,
  eventName?: string
): Observable<any> {
  let eventKey = `dialogue-${stringHash(words)}`;

  if (eventName) eventKey = eventName;

  // If this key already exists, don't show the box
  if (eventContext[eventKey]) return of(true);
  // Put into event context that the dialogue was already said
  return dialogueBox$(words, element, bonusId).pipe(
    tap(() => setEventHappened(eventContext, eventKey))
  );
}

export function thatsItEvent$(eventContext: EventContext, element: HTMLElement): Observable<any> {
  const key = "thats-it";
  if (eventContext[key]) return of(true);
  return thatsIt$(element).pipe(tap(() => setEventHappened(eventContext, key)));
}

export function thatsIt$(element: HTMLElement): Observable<any> {
  return new Observable(observer => {
    const box = new ThatsIt();
    if (!box.onPage) box.append(element);

    box.listenForClicks(() => {
      box.handleClick();
      if (!box.onPage) {
        observer.next(true);
        observer.complete();
      }
    });
  });
}

export function dialogueBox$(
  words: string,
  element: HTMLElement,
  bonusId?: string
): Observable<any> {
  return new Observable(function(observer) {
    const box = new DialogueBox(words, bonusId);
    if (box.onPage) {
      observer.next(true);
      observer.complete();
    } else {
      box.append(element);

      box.listenForClicks(() => {
        box.handleClick();
        if (!box.onPage) {
          observer.next(true);
          observer.complete();
        }
      });
    }
  });
}

export function lockCameraPosition$(
  camera: Camera,
  characterToFollow: Positionable
): Observable<any> {
  return new Observable(function(observer) {
    camera.target = characterToFollow;
    observer.next(true);
    observer.complete();
  });
}

export function unlockCameraPosition$(camera: Camera): Observable<any> {
  return new Observable(function(observer) {
    camera.target = null;
    observer.next(true);
    observer.complete();
  });
}

export function waitTimeEvent$(
  eventContext: EventContext,
  eventKey: string,
  startTime: numberOrNumFunction,
  duration: number,
  time$: Observable<number>
): Observable<any> {
  if (eventContext[eventKey]) return of(true);
  return new Observable(function(observer) {
    const dontStop = () => !eventContext[eventKey];
    const start = valueFromNumberOrNumFunction(startTime);
    const end = start + duration;
    time$.pipe(takeWhile(dontStop)).subscribe({
      next(time: number) {
        if (time >= end) setEventHappened(eventContext, eventKey);
      },
      complete() {
        observer.next(true);
        observer.complete();
      }
    });
  });
}

export function waitFor$(
  context: EventContext,
  key: string,
  value: any,
  time$: Observable<number>
): Observable<any> {
  if (context[key] === value) return of(true);
  return new Observable(function(observer) {
    const dontStop = () => context[key] !== value;

    time$.pipe(takeWhile(dontStop)).subscribe({
      complete() {
        observer.next(true);
        observer.complete();
      }
    });
  });
}

export function setEvent$(eventContext: EventContext, eventKey: string, value: any) {
  if (eventContext[eventKey] === value) return of(true);
  return setProperty$(eventContext, eventKey, value);
}

export function setPropertyEvent$(
  eventContext: EventContext,
  eventKey: string,
  regularContext: any,
  regularKey: string,
  value: any
) {
  if (eventContext[eventKey]) return of(true).pipe(tap((regularContext[regularKey] = value)));
  return setProperty$(regularContext, regularKey, value).pipe(
    tap(() => setEventHappened(eventContext, eventKey))
  );
}

export function setProperty$(context: any, key: string, value: any): Observable<any> {
  return new Observable(function(observer) {
    context[key] = value;
    observer.next(true);
    observer.complete();
  });
}

export function callFunctionEvent$(eventContext: EventContext, eventKey: string, func: () => any) {
  if (eventContext[eventKey]) return of(true);
  return callFunction$(func).pipe(tap(() => setEventHappened(eventContext, eventKey)));
}

export function callFunction$(func: () => any): Observable<any> {
  return new Observable(function(observer) {
    func();
    observer.next(true);
    observer.complete();
  });
}

export function playSoundEvent$(
  eventContext: EventContext,
  eventKey: string,
  soundName: string
): Observable<any> {
  return new Observable(function(observer) {
    if (!eventContext[eventKey]) {
      const audio = AudioPlayback.getInstance();
      audio.play(soundName);
    }

    setEventHappened(eventContext, eventKey);
    observer.next(true);
    observer.complete();
  });
}

export function loopCharacterAlongPath$(
  character: Sprite,
  path: vec2[],
  time$: Observable<number>,
  stopCondition: () => boolean = () => false
): Observable<any> {
  return new Observable(function(observer) {
    const dontStop = () => !stopCondition();

    const moveSub = moveCharacterAlongPath$(character, path, time$, stopCondition)
      .pipe(repeat())
      .subscribe();

    time$.pipe(takeWhile(dontStop)).subscribe({
      complete() {
        moveSub.unsubscribe();
        observer.next(true);
        observer.complete();
      }
    });
  });
}

export function loopCharacterAlongPathXTimesEvent$(
  eventContext: EventContext,
  eventKey: string,
  character: Sprite,
  path: vec2[],
  time$: Observable<number>,
  times: number
): Observable<any> {
  // If this key already exists, return empty since character ends up where they begin
  if (eventContext[eventKey]) {
    const lastPos = path[path.length - 1];
    return updatePosition$(character, lastPos[0], lastPos[1]);
  }

  return loopCharacterAlongPathXTimes$(character, path, time$, times).pipe(
    tap(() => setEventHappened(eventContext, eventKey))
  );
}

export function moveCharacterFromCurrentPosToPointEvent$(
  eventContext: EventContext,
  eventKey: string,
  character: Sprite,
  x: number,
  y: number,
  tileLayer: TileLayer,
  tileMap: TileMap,
  time$: Observable<number>
): Observable<any> {
  if (eventContext[eventKey]) return of(true).pipe(tap(() => character.updatePos(x, y)));

  return of(true).pipe(
    switchMap(() => {
      const dest: vec2 = [x, y];
      const path = pathFinder(character.pos, dest, tileLayer, tileMap);
      return moveCharacterAlongPath$(character, path, time$).pipe(
        tap(() => (setEventHappened(eventContext,eventKey)))
      );
    })
  );
}

export function loopCharacterAlongPathXTimes$(
  character: Sprite,
  path: vec2[],
  time$: Observable<number>,
  times: number
): Observable<any> {
  return new Observable(function(observer) {
    moveCharacterAlongPath$(character, path, time$)
      .pipe(repeat(times))
      .subscribe({
        complete() {
          observer.next(true);
          observer.complete();
        }
      });
  });
}

export function updateCameraPosition$(
  camera: Camera,
  x: number,
  y: number,
  z: number
): Observable<any> {
  return of(true).pipe(tap(() => camera.updatePos3(x, y, z)));
}

export function updatePositionEvent$(
  eventContext: EventContext,
  eventName: string,
  thing: Positionable,
  x: number,
  y: number
) {
  if (eventContext[eventName]) return of(true).pipe(tap(() => thing.updatePos(x, y)));
  return updatePosition$(thing, x, y).pipe(tap(() => (setEventHappened(eventContext,eventName))));
}

export function updatePosition$(
  thing: Positionable,
  x: numberOrNumFunction,
  y: numberOrNumFunction
): Observable<any> {
  return new Observable(function(observer) {
    thing.updatePos(valueFromNumberOrNumFunction(x), valueFromNumberOrNumFunction(y));
    observer.next(true);
    observer.complete();
  });
}

export function moveCharacterAlongPath$(
  character: Sprite,
  path: vec2[],
  time$: Observable<number>,
  stopCondition: () => boolean = () => false
): Observable<any> {
  return new Observable(function(observer) {
    let done = false;
    const dontStop = () => !stopCondition();
    const notDone = () => !done && dontStop();
    const moveCallback = () => {
      done = true;
      observer.next(true);
      observer.complete();
    };

    const pathCopy = path.slice();
    const subscriber = () => moveCharacterAlongPath(character, pathCopy, moveCallback);
    time$.pipe(takeWhile(notDone)).subscribe(subscriber);
  });
}

type numberOrNumFunction = number | (() => number);

function valueFromNumberOrNumFunction(n: numberOrNumFunction): number {
  let n2: number = null;

  if (typeof n === "function") {
    n2 = n();
  } else {
    n2 = n;
  }

  return n2;
}

export function cameraZoomTweenEvent$(
  eventContext: EventContext,
  eventKey: string,
  camera: Camera,
  z1: numberOrNumFunction,
  z2: numberOrNumFunction,
  startTime: numberOrNumFunction,
  time$: Observable<number>,
  speed = TRANSLATE_SPEED
): Observable<any> {
  // If this key already exists, don't show the box
  if (eventContext[eventKey]) {
    const zoomTo = valueFromNumberOrNumFunction(z2);
    return of(true).pipe(tap(() => camera.updateZ(zoomTo)));
  }

  return cameraZoomTween$(camera, z1, z2, startTime, time$, speed).pipe(
    tap(() => (setEventHappened(eventContext,eventKey)))
  );
}

export function cameraZoomTween$(
  camera: Camera,
  z1: numberOrNumFunction,
  z2: numberOrNumFunction,
  startTime: numberOrNumFunction,
  time$: Observable<number>,
  speed = TRANSLATE_SPEED
): Observable<any> {
  return of(true).pipe(
    switchMap(() =>
      getTwarnEndWithTime$(
        new Twarn(
          [valueFromNumberOrNumFunction(z1), 0, 0],
          [valueFromNumberOrNumFunction(z2), 0, 0],
          valueFromNumberOrNumFunction(startTime),
          { duration: speed }
        ).onUpdate(vector => {
          const value = vector[0];
          camera.updateZ(value);
        }),
        time$
      )
    )
  );
}

export function translateTweenFromCameraEvent$(
  eventContext: EventContext,
  eventKey: string,
  camera: Camera,
  destX: numberOrNumFunction,
  destY: numberOrNumFunction,
  startTime: numberOrNumFunction,
  time$: Observable<number>,
  speed?: number
): Observable<any> {
  if (eventContext[eventKey]) {
    return of(true).pipe(
      tap(() => {
        camera.updatePos3(
          valueFromNumberOrNumFunction(destX),
          valueFromNumberOrNumFunction(destY),
          camera.vector[2]
        );
      })
    );
  }

  return of(true).pipe(
    switchMap(() => {
      const pos = camera.pos;
      const x = pos[0];
      const y = pos[1];
      return translateTweenEvent$(
        eventContext,
        eventKey,
        camera,
        x,
        y,
        destX,
        destY,
        startTime,
        time$,
        speed
      );
    })
  );
}

export function updateInteraction$(townPlay: TownPlay, name: string, interactionFn: () => void) {
  return new Observable(observer => {
    const characterInfo = townPlay.characters.find(characterInfo => characterInfo.name === name);
    characterInfo.interaction = interactionFn;
    observer.next(true);
    observer.complete();
  });
}

export function translateTweenEvent$(
  eventContext: EventContext,
  eventKey: string,
  thing: Positionable,
  x1: numberOrNumFunction,
  y1: numberOrNumFunction,
  x2: numberOrNumFunction,
  y2: numberOrNumFunction,
  startTime: numberOrNumFunction,
  time$: Observable<number>,
  speed = TRANSLATE_SPEED
): Observable<any> {
  if (eventContext[eventKey]) return updatePosition$(thing, x2, y2);

  return translateTween$(thing, x1, y1, x2, y2, startTime, time$, speed).pipe(
    tap(() => (setEventHappened(eventContext, eventKey)))
  );
}

export function translateTween$(
  thing: Positionable,
  x1: numberOrNumFunction,
  y1: numberOrNumFunction,
  x2: numberOrNumFunction,
  y2: numberOrNumFunction,
  startTime: numberOrNumFunction,
  time$: Observable<number>,
  speed = TRANSLATE_SPEED
): Observable<any> {
  return of(true).pipe(
    switchMap(() =>
      getTwarnEndWithTime$(
        new Twarn(
          [valueFromNumberOrNumFunction(x1), valueFromNumberOrNumFunction(y1), 0],
          [valueFromNumberOrNumFunction(x2), valueFromNumberOrNumFunction(y2), 0],
          valueFromNumberOrNumFunction(startTime),
          { duration: speed }
        ).onUpdate((updatedVector: vec3) => thing.updatePos(updatedVector[0], updatedVector[1])),
        time$
      )
    )
  );
}

function getTwarnEndWithTime$(twarn: Twarn, time$: Observable<number>): Observable<any> {
  const twarn$ = new Observable(subscriber => {
    twarn.onComplete(() => {
      subscriber.next(true);
      subscriber.complete();
    });
  });

  time$.pipe(takeUntil(twarn$)).subscribe(time => twarn.update(time));
  return twarn$;
}

interface EventContext {
  [key: string]: boolean;
}
