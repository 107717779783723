import { BehaviorSubject } from "rxjs";

import { vec2, mat4 } from "gl-matrix";

import Sprite from "../sprite";
import { loadTexture } from "../helpers/webgl-helpers";

import spriteSheetImageUrl from "../../assets/sprites/arcade-pack.png";
import spriteSheetNormalImageUrl from "../../assets/sprites/arcade-pack-n.png";
import { Directions } from "../interfaces";

export default class Metaorb extends Sprite {
  tileIndex: number;
  tileSize: vec2;
  tileSetDimensions: vec2;
  texture: WebGLTexture | null;
  textureUrl: string;
  normalTexture: WebGLTexture | null;
  animations: { [key: string]: number[] };
  animationName$: BehaviorSubject<string>;
  modelMatrix: number[];
  name: string;
  interactable = false;

  constructor(gl: WebGLRenderingContext) {
    super(gl);
    this.modelMatrix = <number[]>mat4.create();
    this.tileSize = [32, 32];
    this.tileSetDimensions = [640, 1280];
    this.tileIndex = 0;
    this.textureUrl = spriteSheetImageUrl;
    this.name = "torch";
    this.spriteRenderRenderOrderHint = "Z";

    this.animations = {
      left: [0],
      up: [613, 614],
      down: [0],
      right: [0],
      leftIdle: [0],
      upIdle: [0],
      downIdle: [0],
      rightIdle: [0]
    };
    this.opacity = 0.5;

    this.setScale(0.4, 0.4);
    this.setDirection(Directions.Up);
  }

  async load() {
    const { gl } = this;
    this.texture = await loadTexture(gl, spriteSheetImageUrl);
    this.normalTexture = await loadTexture(gl, spriteSheetNormalImageUrl);
    return true;
  }
}
