import { fromEvent, merge } from "rxjs";
import { filter, takeWhile, throttleTime } from "rxjs/operators";

import { state } from "./index";

import { hasClass } from "./helpers/style-helpers";

import html from "./templates/thats-it-html";

export default class ThatsIt {
  public div: HTMLElement;

  private dialogueId: string;

  constructor() {
    this.dialogueId = `thats-it`;
  }

  get onPage() {
    return !!document.querySelector(`[data-dialogue-id=${this.dialogueId}]`);
  }

  createBox() {
    this.div = document.createElement("div");
    this.div.dataset.dialogueId = this.dialogueId;
    this.div.classList.add("dialogue-wrapper");
    this.div.insertAdjacentHTML("beforeend", html);
  }

  append(element: HTMLElement) {
    this.createBox();
    element.append(this.div);
  }

  listenForClicks(callback) {
    const notPaused = filter(() => !state.paused);
    const keys = [" ", "Enter"];
    const spaceAndEnter = filter((e: KeyboardEvent) => keys.includes(e.key));

    const notLink = filter((e: Event) => !hasClass(e.target as HTMLElement, "js-encourage-link"));
    const mouseUp$ = fromEvent(document, "mouseup");
    const touchEnd$ = fromEvent(document, "touchend");
    const keyUp$ = fromEvent(document, "keyup").pipe(spaceAndEnter);
    const onPage = () => this.onPage;
    const click$ = merge(mouseUp$, touchEnd$, keyUp$).pipe(throttleTime(250), notPaused, notLink);
    click$.pipe(takeWhile(onPage)).subscribe(() => callback());
  }

  remove() {
    if (this.onPage && this.div.parentNode) this.div.parentNode.removeChild(this.div);
  }

  handleClick() {
    this.remove();
  }
}
