import { Observable, Subscription } from "rxjs";

import { Positionable, Updateable } from "./interfaces";

export default class TownPlay {
  public characters: CharacterInfo[];
  private subscriptions: Array<Subscription>;

  constructor() {
    this.characters = [];
    this.subscriptions = [];
  }

  addCharacter(
    name: string,
    sprite: Positionable & Updateable,
    loop: Observable<any>,
    interaction?: () => void
  ) {
    const info = { name, sprite, loop, interaction };
    this.characters.push(info);
  }

  start() {
    this.characters.forEach((character, i) => {
      this.subscriptions[i] = character.loop.subscribe();
    });
  }

  stop() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}

interface CharacterInfo {
  name: string;
  loop: Observable<any>;
  sprite?: Positionable & Updateable;
  interaction?: () => void;
}
