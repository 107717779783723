import { vec2, vec3 } from "gl-matrix";

import Twarn from "./twarn";

import { setHalfPixelTextureCoords, createMapTbn } from "./helpers/webgl-helpers";
import { state } from "./index";
import { FUDGED_QUAD_TEXTURE_COORDS, QUAD_POSITIONS, QUAD_WIDTH } from "./models";

import {
  Directions,
  GameState,
  Positionable,
  TileMap,
  TileSet,
  TileLayer,
  Updateable
} from "./interfaces";

import SpriteRenderer from "./sprite-renderer";

import { coordinatesCanBeReachedFromPosition, outOfBounds } from "./helpers/map-helpers";

const zeroPos = vec3.create();

export default abstract class Sprite implements Positionable, Updateable {
  static readonly DEFAULT_ANIMATION_SPEED = 350;
  static readonly MOVE_AMOUNT = 0.075;
  static readonly MOVE_FUDGE = QUAD_WIDTH / 4;

  abstract name: string;
  abstract textureUrl: string; // We use the textureUrl as the key for the sprite-renderer
  abstract tileIndex: number;
  abstract tileSize: vec2;
  abstract tileSetDimensions: vec2;

  public vertexPositions: number[];
  public outlineVertexPositions: number[];
  public textureCoords: number[];
  public vertexTangents: Float32Array;
  public vertexBitangents: Float32Array;
  public vertexNormals: Float32Array;
  public modelMatrix: number[];
  public texture: WebGLTexture | null;
  public normalTexture: WebGLTexture | null;
  public interactable: boolean = true;
  public spriteRenderer?: SpriteRenderer = null;
  public spriteRendererIndex?: number = null;
  public spriteRenderRenderOrderHint: string = "A";
  public spriteAnimationTween: Twarn;
  public selected: number = 0.0;
  public billboard: boolean = false;
  public scale: vec2;
  public origin: vec2;
  public rotation: number;

  protected gl: WebGLRenderingContext;
  protected gameState: GameState;
  protected _pos: vec3;
  protected animationSpeed: number = Sprite.DEFAULT_ANIMATION_SPEED;
  protected animations: { [key: string]: number[] };
  protected currentDirection: Directions;

  private currentIdleAnimation: Directions;
  private _opacity: number;

  constructor(gl: WebGLRenderingContext) {
    this.gl = gl;
    this.textureCoords = [...FUDGED_QUAD_TEXTURE_COORDS];
    this.vertexPositions = [...QUAD_POSITIONS];
    this.outlineVertexPositions = [...QUAD_POSITIONS];
    this.vertexTangents = new Float32Array(36);
    this.vertexBitangents = new Float32Array(36);
    this.vertexNormals = new Float32Array(36);
    this.animations = {
      up: [0],
      down: [0],
      left: [0],
      right: [0],
      upIdle: [0],
      downIdle: [0],
      rightIdle: [0],
      leftIdle: [0]
    };

    this.scale = [1, 1];
    this._pos = vec3.create();
    this._opacity = 1.0;
    this.origin = vec2.create();
    this.rotation = 0;
    vec2.set(this.origin, 0, QUAD_WIDTH / 2);
    this.updatePos(2, -3);
    this.updateTbn();
  }

  get pos(): vec2 {
    return [this._pos[0], this._pos[1]];
  }

  get pos3(): vec3 {
    return [this._pos[0], this._pos[1], this._pos[2]];
  }

  get size(): vec2 {
    return [this.scale[0], this.scale[1]];
  }

  get opacity(): number {
    return this._opacity;
  }

  set opacity(value) {
    this._opacity = value;
    this.setOpacitiesOnSpriteRenderer();
  }

  abstract async load(): Promise<boolean>;

  public move(
    direction: Directions,
    tileLayer: TileLayer,
    tileSet: TileSet,
    tileMap: TileMap,
    amount = Sprite.MOVE_AMOUNT * state.playbackSpeed
  ) {
    this.setDirection(direction);

    let x = this.pos[0];
    let y = this.pos[1];
    const fromX = x;
    const fromY = y;

    switch (direction) {
      case Directions.Up:
        y = this.pos[1] + amount;
        break;
      case Directions.Down:
        y = this.pos[1] - amount;
        break;
      case Directions.Left:
        x = this.pos[0] - amount;
        break;
      case Directions.Right:
        x = this.pos[0] + amount;
        break;
    }

    if (x === fromX && y === fromY) return;
    if (outOfBounds(x, y, tileMap)) return;

    const positionIsUnreachable = !coordinatesCanBeReachedFromPosition(
      x,
      // y - this.size[1] / 2, // so character doesn't end up with butt half way in the floor
      y,
      fromX,
      fromY,
      tileLayer,
      tileMap
    );

    if (positionIsUnreachable) return;

    this.updatePos(x, y);
  }

  public update(timestamp: number) {
    const { scale } = this;

    const pos = this.billboard ? zeroPos : this._pos;

    for (let i = 0; i < this.vertexPositions.length; i = i + 3) {
      const x = scale[0] * QUAD_POSITIONS[i + 0];
      const y = scale[1] * QUAD_POSITIONS[i + 1];
      const x1 = x * Math.cos(this.rotation) - y * Math.sin(this.rotation);
      const y1 = x * Math.sin(this.rotation) + y * Math.cos(this.rotation);
      this.vertexPositions[i + 0] = x1 + pos[0] + this.origin[0];
      this.vertexPositions[i + 1] = y1 + pos[1] + this.origin[1]; // .5 moves to feet
      this.vertexPositions[i + 2] = pos[2] + QUAD_POSITIONS[i + 2];
    }

    this.setSelectedOnSpriteRenderer();
    this.setVertexPositionsOnSpriteRenderer();
    this.setCenterOnSpriteRenderer();
    this.spriteAnimationTween.update(timestamp);
  }

  public switchToIdleAnimation() {
    this.setDirection(this.currentIdleAnimation);
  }

  public changeAnimationSpeed(speed: number) {
    this.animationSpeed = speed;
    this.setDirection(this.currentDirection, true);
  }

  public setDirection(direction: Directions, force: boolean = false) {
    if (direction === this.currentDirection && force === false) return;
    this.currentDirection = direction;
    this.updateAnimation(direction);

    let nextIdleAnimation = Directions.DownIdle;
    switch (direction) {
      case Directions.Up:
        nextIdleAnimation = Directions.UpIdle;
        break;
      case Directions.Down:
        nextIdleAnimation = Directions.DownIdle;
        break;
      case Directions.Left:
        nextIdleAnimation = Directions.LeftIdle;
        break;
      case Directions.Right:
        nextIdleAnimation = Directions.RightIdle;
        break;
    }

    this.currentIdleAnimation = nextIdleAnimation;
  }

  public updatePos(x: number, y: number) {
    vec3.set(this._pos, x, y, this._pos[2]);
  }

  public updatePos3(x: number, y: number, z: number) {
    vec3.set(this._pos, x, y, z);
  }

  public setScale(width: number, height: number) {
    this.scale = [this.scale[0] * width, this.scale[1] * height];
    // TODO: need to do this?
    this.updateTbn();
  }

  public setVertexPositionsOnSpriteRenderer() {
    if (!this.spriteRenderer) return;
    const index = this.spriteRendererIndex;
    this.spriteRenderer.vertexPositions.splice(
      index * SpriteRenderer.VERTEX_POSITIONS_LENGTH,
      SpriteRenderer.VERTEX_POSITIONS_LENGTH,
      ...this.vertexPositions
    );
    this.spriteRenderer.outlineVertexPositions.splice(
      index * SpriteRenderer.VERTEX_POSITIONS_LENGTH,
      SpriteRenderer.VERTEX_POSITIONS_LENGTH,
      ...this.outlineVertexPositions
    );
  }

  public setTbnOnSpriteRenderer() {
    if (!this.spriteRenderer) return;
    const index = this.spriteRendererIndex;
    this.spriteRenderer.vertexTangents.set(
      this.vertexTangents,
      index * SpriteRenderer.TANGENT_LENGTH
    );

    this.spriteRenderer.vertexBitangents.set(
      this.vertexBitangents,
      index * SpriteRenderer.BITANGENT_LENGTH
    );

    this.spriteRenderer.vertexNormals.set(this.vertexNormals, index * SpriteRenderer.NORMAL_LENGTH);
  }

  public setCenterOnSpriteRenderer() {
    if (!this.spriteRenderer) return;
    const index = this.spriteRendererIndex;
    const centerArray = new Float32Array(SpriteRenderer.CENTERS_LENGTH);
    for (let i = 0; i < centerArray.length; i = i + 3) {
      centerArray.set(new Float32Array(this._pos), i);
    }

    this.spriteRenderer.centers.set(centerArray, index * SpriteRenderer.CENTERS_LENGTH);
  }

  public setSelectedOnSpriteRenderer() {
    if (!this.spriteRenderer) return;
    const index = this.spriteRendererIndex;

    this.spriteRenderer.selecteds.set(
      new Float32Array(SpriteRenderer.SELECTEDS_LENGTH).fill(this.selected),
      index * SpriteRenderer.SELECTEDS_LENGTH
    );
  }

  public setTextureCoordinatesOnSpriteRenderer() {
    if (!this.spriteRenderer) return;
    const index = this.spriteRendererIndex;
    this.spriteRenderer.textureCoords.splice(
      index * SpriteRenderer.TEXTURE_COORDS_LENGTH,
      SpriteRenderer.TEXTURE_COORDS_LENGTH,
      ...this.textureCoords
    );
  }

  public setOpacitiesOnSpriteRenderer() {
    if (!this.spriteRenderer) return;
    const index = this.spriteRendererIndex;

    this.spriteRenderer.opacities.set(
      new Float32Array(SpriteRenderer.OPACITIES_LENGTH).fill(this.opacity),
      index * SpriteRenderer.OPACITIES_LENGTH
    );
  }

  protected updateTbn() {
    let offset = 0;
    for (let i = 0; i < this.vertexPositions.length; i = i + 9) {
      const vertexPosition = this.vertexPositions.slice(i, i + 9);
      const tbn = createMapTbn(vertexPosition);

      // Every vertex in a triangle can have the same tangent/bitangent
      // TODO: why 6 and not 3??
      for (let j = 0; j < 6; j++) {
        this.vertexTangents.set(tbn.tangent, offset);
        this.vertexBitangents.set(tbn.bitangent, offset);
        this.vertexNormals.set(tbn.normal, offset);
        offset += 3;
      }
    }

    this.setTbnOnSpriteRenderer();
  }

  protected updateAnimation(direction: Directions) {
    let animation = this.animations[direction] || [0];
    const firstAnimationIndex = animation[0];
    const lastAnimationIndex = animation[animation.length - 1];
    this.tileIndex = firstAnimationIndex;
    this.setTextureCoordinates();
    this.spriteAnimationTween = new Twarn(
      [firstAnimationIndex, 0, 0],
      [lastAnimationIndex, 0, 0],
      state.currentTime,
      { yoyo: true, repeat: Infinity, duration: this.animationSpeed }
    ).onUpdate(updatedVector => {
      const i = updatedVector[0];
      const oldIndex = this.tileIndex;
      this.tileIndex = Math.round(i);
      if (this.tileIndex !== oldIndex) this.setTextureCoordinates();
    });
  }

  public setTextureCoordinates() {
    const { textureCoords, tileIndex, tileSize, tileSetDimensions } = this;
    setHalfPixelTextureCoords(
      textureCoords,
      FUDGED_QUAD_TEXTURE_COORDS,
      0,
      tileIndex,
      tileSize,
      tileSetDimensions
    );

    this.setTextureCoordinatesOnSpriteRenderer();
  }
}

// Formula for animation frame:

// count from 0
// index_of_row * num_of_sprites_in_a_row + index_of_column
// e.g:
/*

0 0 0 0 0 0
0 0 0 0 0 0
0 0 X 0 0 0
0 0 0 0 0 0
0 0 0 0 0 0

the X is:

2 * 6 + 2 = 14

*/
