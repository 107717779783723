import EncourageMatt from "./routes/encourage-matt-menu";
import Loading from "./routes/loading";
import TitleMenu from "./routes/title-menu";
import SettingsMenu from "./routes/settings-menu";
import AudioPlayback from "./audio-playback";

import { GamePage } from "./interfaces";

const TITLE = "Matt Fantasy VI";
const DEFAULT_PATH = "/";

const overlay = document.getElementById("overlay");
const gaId = process.env.GA_ID;
const selectSoundUrl = require("../assets/audio/sfx/ui-select-02.wav").default;

export default class Router {
  public currentRoute: GamePage;
  public loadingPage?: Loading;

  private routes: Routes;
  private audioPlayBack: AudioPlayback;

  public push(routeName: string) {
    const urlParts = window.location.pathname.split("/");
    urlParts.push(routeName);
    const route = urlParts.join("/");
    const query = window.location.search;
    const pathName = route + "?" + query;
    this.loadRoute(pathName);
  }

  public pop() {
    const urlParts = window.location.pathname.split("/");
    urlParts.pop();
    const route = urlParts.join("/");
    const query = new URLSearchParams(window.location.search);
    query.set("pop", "true");
    const pathName = route + "?" + query.toString();
    this.loadRoute(pathName);
  }

  constructor() {
    this.routes = {
      "": TitleMenu,
      "encourage-matt": EncourageMatt,
      sharesburg: import("./routes/sharesburg").then(r => r.default),
      minetown: import("./routes/minetown").then(r => r.default),
      battle: import("./battle").then(r => r.default),
      loading: Loading,
      settings: SettingsMenu
    };

    this.currentRoute = null;
    this.loadingPage = null;
    this.audioPlayBack = AudioPlayback.getInstance();
    this.audioPlayBack.add("select", selectSoundUrl);
  }

  async loadPage(name: string) {
    const { routes } = this;

    let params = new URLSearchParams("");
    const urlParts = name.split("?");
    if (urlParts.length > 1) params = new URLSearchParams(urlParts[1]);

    let normalizedName = DEFAULT_PATH;
    if (!name) {
      console.warn(`No route passed so sending to root...`);
    } else {
      // remove leading/trailing slash, then add slash to the front
      const pathStack = urlParts[0].split("/");
      normalizedName = pathStack[pathStack.length - 1];
      normalizedName = normalizedName.replace(/^\/|\/$/g, "");
      // itch.io adds an index.html and puts things in a /dist dir so pull everything out of there...
      normalizedName = normalizedName.replace("index.html", "").replace(/^\/.*dist/, "");
    }

    // Create next GamePage
    let Page = null;

    try {
      Page = await routes[normalizedName];
      if (!Page) throw `No import found for route ${name} normalized to ${normalizedName}...`;
    } catch (e) {
      console.error(e);
      window.history.pushState({}, TITLE, DEFAULT_PATH);
      Page = await routes[DEFAULT_PATH];
    }

    const bornPage: GamePage = new Page();
    bornPage.routerId = name;
    // Tear down old GamePage
    if (this.currentRoute) this.currentRoute.tearDown();
    this.currentRoute = bornPage;

    // Add loading page
    if (this.loadingPage) this.loadingPage.tearDown();
    this.loadingPage = new Loading();
    await this.loadingPage.load(params);
    this.loadingPage.configurePrograms();

    this.emptyOverlay(); // Clear out previous menu, if any
    overlay.appendChild(this.loadingPage.menu); // add loading...

    if (bornPage.load) await bornPage.load(params);
    console.log(`Finished loading page for route ${name} normalized to ${normalizedName}...`);

    if (this.currentRoute !== bornPage) {
      const current = this.currentRoute.routerId;
      const born = bornPage.routerId;
      console.log(`Current route ${current} isn't ${born}, so bailing...`);
      return;
    }

    bornPage.configurePrograms();
    this.emptyOverlay(); // remove loading text...

    if (bornPage.menu) overlay.appendChild(bornPage.menu);

    this.loadingPage.tearDown();
    this.loadingPage = null;
    bornPage.start();

    if (window.gtag) {
      const props = {
        page_title: bornPage.name,
        page_path: name
      };
      window.gtag("config", gaId, props);
    }
  }

  async startRouter() {
    await this.loadPage(pathAndSearch());
    window.onpopstate = async () => {
      await this.loadPage(pathAndSearch());
    };

    // We only have a few elements on the screen so not so bad
    document.addEventListener("click", event => {
      const target = <HTMLElement>event.target;
      if (target.tagName !== "A") return; // don't commandeer if it's not a link
      const path = (target as HTMLAnchorElement).getAttribute("href");
      if (!path.startsWith("/")) return; // don't commandeer if it's not linked to something based of a root url

      event.preventDefault();

      this.navigate(path);
    });
  }

  async loadRoute(pathName: string) {
    window.history.pushState({}, TITLE, pathName);
    await this.loadPage(pathName);
  }

  // Used for commandeering links...
  async navigate(pathName: string) {
    this.audioPlayBack.play("select");
    this.loadRoute(pathName);
  }

  emptyOverlay() {
    while (overlay.firstChild) overlay.removeChild(overlay.lastChild);
  }
}

function pathAndSearch() {
  return window.location.pathname + window.location.search;
}

interface Routes {
  [key: string]: any;
}

declare global {
  interface Window {
    gtag: any;
  }
}

// Use this to test loading screen
const delay = t => new Promise(resolve => setTimeout(resolve, t));

// References:
// https://medium.com/altcampus/implementing-simple-spa-routing-using-vanilla-javascript-53abe399bf3c
// https://parceljs.org/code_splitting.html
// https://developers.google.com/web/tools/workbox/guides/using-bundlers
