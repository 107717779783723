import s from "./atb.css";

export const styles = s;

export default `
<div id="js-atb" class="${s["bottom"]} ${s["menu"]}">
  <div class="${s["left"]} ${s["box"]}">
    <div class="${s["row"]} ${s["header"]}">NAME</div>
    <div id="js-hero-name-0" class="${s["row"]} ${s["name"]} js-name" data-index="0"></div>
    <div id="js-hero-name-1" class="${s["row"]} ${s["name"]} js-name" data-index="1"></div>
    <div id="js-hero-name-2" class="${s["row"]} ${s["name"]} js-name" data-index="2"></div>
  </div>

  <div class="${s["right"]} ${s["box"]}">
    <div class="${s["row"]}">
      <div class="${s["header"]}">HP</div>
      <div class="${s["header"]}">MP</div>
      <div class="${s["header"]}">TIME</div>
    </div>

    <div id="js-hero-0-stats" class="${s["row"]}">
      <div class="js-hp"></div>
      <div class="js-mp"></div>
      <div class="js-progress-bar ${s["progress-bar"]}">
        <div class="js-progress-bar-filling ${s["progress-bar-filling"]}"></div>
      </div>
    </div>

    <div id="js-hero-1-stats" class="${s["row"]}">
      <div class="js-hp"></div>
      <div class="js-mp"></div>
      <div class="js-progress-bar ${s["progress-bar"]}">
        <div class="js-progress-bar-filling ${s["progress-bar-filling"]}"></div>
      </div>
    </div>

    <div id="js-hero-2-stats" class="${s["row"]}">
      <div class="js-hp"></div>
      <div class="js-mp"></div>
      <div class="js-progress-bar ${s["progress-bar"]}">
        <div class="js-progress-bar-filling ${s["progress-bar-filling"]}"></div>
      </div>
    </div>
  </div>

<div id="js-hero-menu" class="${s["right"]} ${s["box"]} ${s["hide"]} ${s["hero-menu"]} ${s["sub-menu"]} ${s["menu"]} js-sub-menu">
  <div class="${s["row"]} ${s["back-row-wrapper"]}">
    <div></div>
    <div></div>
    <div class="js-back ${s["hero-back"]} ${s["selectable"]}">Back</div>
  </div>
  <div class="${s["row"]} ${s["selectable"]} ${s["action"]} js-secondary-menu-option" data-action="attack" data-secondary="attack">Attack</div>
  <div class="${s["row"]} ${s["selectable"]} js-menu-link" data-menu-name="magic" data-secondary="magic">
    Magic
  </div>
  <div class="${s["row"]} ${s["selectable"]} js-menu-link" data-menu-name="metaorb" data-secondary="metaorb">
    Metaorb
  </div>
  <div class="${s["row"]} ${s["selectable"]} js-menu-link" data-menu-name="item" data-secondary="item">Item</div>
</div>

<div id="js-magic-menu" class="${s["right"]} ${s["box"]} ${s["hide"]} ${s["sub-menu"]} ${s["menu"]} js-sub-menu">
  <div class="${s["row"]} ${s["back-row-wrapper"]}">
    <div></div>
    <div></div>
    <div class="${s["sub-menu-back"]} ${s["selectable"]} js-secondary-back" data-menu-name="magic">
      Back
    </div>
  </div>
</div>

<div id="js-metaorb-menu" class="${s["right"]} ${s["box"]} ${s["hide"]} ${s["sub-menu"]} ${s["menu"]} js-sub-menu">
  <div class="${s["row"]} ${s["back-row-wrapper"]}">
    <div></div>
    <div></div>
    <div class="${s["sub-menu-back"]} ${s["selectable"]} js-secondary-back" data-menu-name="metaorb">
      Back
    </div>
  </div>
</div>

<div id="js-item-menu" class="${s["right"]} ${s["box"]} ${s["hide"]} ${s["sub-menu"]} ${s["menu"]} js-action-menu js-sub-menu">
  <div class="${s["row"]} ${s["back-row-wrapper"]}">
    <div></div>
    <div></div>
    <div class="${s["sub-menu-back"]} ${s["selectable"]} js-secondary-back" data-menu-name="item">
      Back
    </div>
  </div>
</div>

<div id="js-sink-menu" class="${s["right"]} ${s["box"]} ${s["hide"]} ${s["sub-menu"]} ${s["menu"]} js-sub-menu">
  <div class="${s["row"]} ${s["back-row-wrapper"]}">
    <div></div>
    <div></div>
    <div class="${s["sink-back"]} ${s["sub-menu-back"]} ${s["selectable"]} js-sink-back js-sub-menu" data-menu-name="sink">
    Back
    </div>
  </div>
</div>

<div id="js-confirm-menu" class="${s["right"]} ${s["box"]} ${s["hide"]} ${s["confirm-menu"]} ${s["menu"]} js-sub-menu">
  <div class="${s["row"]} ${s["back-row-wrapper"]}">
    <div></div><div></div>
    <div class="${s["sink-back"]} ${s["sub-menu-back"]} ${s["selectable"]} js-confirm-back" data-menu-name="confirm">
      Back
    </div>
  </div>
  <div id="js-confirm-action" class="${s["confirm"]}">Confirm you want to do that?</div>
  <div class="${s["yes-no"]}"><div class="${s["selectable"]} js-confirm-yes">Yes</div><div>/</div><div class="${s["selectable"]} js-confirm-back">No</div></div>
</div>
`;
