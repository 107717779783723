import { HappenedEvents } from "./interfaces";

import { state } from "./index";

export default class GameSaver {
  newGame() {
    this.saveCurrentGameToFirstSaveLocation();
  }

  listSaves(): GameSave[] {
    let saves = [];
    try {
      saves = JSON.parse(localStorage.getItem("saves") || "[]");
    } catch (e) {
      console.error(`Error parsing saves: ${e}`);
      saves = [];
    }

    return saves;
  }

  loadFirstSavedGame(): boolean {
    const saves = this.listSaves();
    let firstSave = saves[0];
    if (firstSave) {
      console.log("Loading first save...");
      state.happenedEvents = firstSave.events;
      return true;
    } else {
      console.warn("No first save found...");
      return false;
    }
  }

  openSave(save: GameSave) {
    state.happenedEvents = save.events;
  }

  saveCurrentGameToFirstSaveLocation() {
    const saves = this.listSaves();
    let firstSave = saves[0];
    if (!firstSave) {
      firstSave = { events: {} };
      saves.push(firstSave);
    }
    firstSave.events = state.happenedEvents;
    localStorage.setItem("saves", JSON.stringify(saves));
  }
}

interface GameSave {
  events: HappenedEvents;
}
