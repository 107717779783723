import s from "./encourage-matt-menu.css";
export const styles = s;

export default `<div class="${s["form-wrapper"]}">
  <div class="${s["form-title"]}"><h1>Encourage Matt to Keep Making This Game</h1></div>
  <div class="${s["encouragement-form-wrapper"]}"}>
    <form action="https://formspree.io/xgeldozj" method="POST" class="${s["encouragement-form"]}">
      <div>
        <label>
          Your email, totally optional
          <br />
          <input class=${s["encouragement-email"]} type="text" name="_replyto" />
        </label>
      </div>
      <div>
        <label
          >Your message, less optional but more fun
          <br />
          <textarea class="${s["encouragement-message"]}" name="message" required>
Please continue making Matt Fantasy VI because it seems like it maybe could be fun.</textarea
          >
        </label>
      </div>
      <div class="${s["button-wrapper"]}">
        <button class="${s["button"]}" type="submit">Send your full-throated encouragement!</button>
      </div>
    </form>
  </div>
  <div class="${s["title-menu-options"]}">
    <div class="${s["title-menu-option"]}" tabindex="0"><a href="/">BACK TO THE TITLE SCREEN</a></div>
  </div>
</div>`;
