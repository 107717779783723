import { mat4, vec2, vec3 } from "gl-matrix";
import { defer, of, merge, Subject, timer } from "rxjs";
import { takeWhile, tap } from "rxjs/operators";
import i18next, { i18n } from "i18next";
import Howl from "howler";

import * as ImGui_Impl from "./imgui-js/imgui-impl";
const { ImGui } = ImGui_Impl;

import { Bounds, TileLayer, TileMap, TileSet, GamePage, Positionable } from "./interfaces";
import { state } from "./index";
import { loadTexture, resetContext, resizeFrameBuffer } from "./helpers/webgl-helpers";
import { gamePageBounds, round, clamp } from "./helpers/math-helpers";
import { lastElementFromArray } from "./helpers/array-helpers";
import { makeMoveCommand, makeInteractionCommand } from "./helpers/input-helpers";
import { moveCharacterAlongPath } from "./helpers/map-helpers";
import SpriteRenderer from "./sprite-renderer";
import Sprite from "./sprite";
import GameSaver from "./game-saver";
import SettingsCog from "./settings-cog";

const { resize$ } = state.o$;
const { input } = state;

import {
  clearScreen,
  compiledProgram,
  cacheAttributeLocations,
  cacheUniformLocations,
  createMapTbn,
  enableBlending,
  setDisableDepthTesting,
  setHalfPixelTextureCoords,
  setQuadPositions,
  setNormalsAttribDirect,
  setPositionsAttribDirect,
  setBitangentsAttribDirect,
  setTangentsAttribDirect,
  setTextureCoordsAttribDirect,
  setOpacitiesAttribDirect,
  setSelectedsAttribDirect,
  setCentersAttribDirect,
  setDrawToScreen,
  setDrawToTexture
} from "./helpers/webgl-helpers";

import {
  TILE_TEXTURE_COORDS,
  QUAD_WIDTH,
  SCREEN_POSITIONS,
  SCREEN_TEXTURE_COORDS,
  QUAD_POSITIONS,
  NUM_VERTS_PER_QUAD
} from "./models";
import KeyboardInput from "./inputs/keyboard-input";
import PointerInput from "./inputs/pointer-input";
import SPRITE_FRAG_SHADER from "./shaders/sprite-fragment.glsl";
import TILE_VERT_SHADER from "./shaders/tile-vertex.glsl";
import TEXTURE_FRAG_SHADER from "./shaders/texture-fragment.glsl";
import TEXTURE_VERT_SHADER from "./shaders/texture-vertex.glsl";
import TownPlay from "./town-play";

const screenTextureCoords = new Float32Array(SCREEN_TEXTURE_COORDS);

const UNIFORM_NAMES = [
  "uWorldStart",
  "uWorldEnd",
  "uModelMatrix",
  "uViewMatrix",
  "uProjectionMatrix",
  "uSpriteSampler",
  "uNormalSampler",
  "uLightPositions",
  "uLightColors",
  "uLightDropoffs",
  "uDayLevel",
  "uOpacity",
  "uTime",
  "uCheckOutOfBounds",
  "uBillboard"
];

const ATTRIBUTE_NAMES = [
  "aOpacity",
  "aSelected",
  "aVertexPosition",
  "aVertexNormal",
  "aTextureCoord",
  "aVertexTangent",
  "aVertexBitangent",
  "aCenter"
];

const vecToMessWith: vec3 = [0, 0, 0];
const POST_PROCESS_EFFECT_DURATION = 3000;

export default class Town implements GamePage {
  public menu: HTMLElement;
  public loaded: boolean;
  public tornDown: boolean;
  public routerId: string;
  public playerTarget: Positionable;
  public townPlay: TownPlay | null;
  public name: string = "generic-town";
  public backgroundAudio: Howl[];

  protected gameCanvas: HTMLCanvasElement;
  protected gameGl: WebGLRenderingContext;
  protected uiCanvas: HTMLCanvasElement;
  protected uiGl: WebGLRenderingContext;
  protected params: URLSearchParams;
  protected textureImageUrls: string[];
  protected normalTextureImageUrls: string[];
  protected textures: WebGLTexture[];
  protected normalTextures: WebGLTexture[];
  protected layerTextures: WebGLTexture[];
  protected layerTextureCoords: Float32Array[];
  protected layerOpacities: number[];
  protected vertexPositions: Float32Array;
  protected layerProgramVertexPositions: Float32Array;
  protected vertexTangents: Float32Array;
  protected vertexBitangents: Float32Array;
  protected vertexNormals: Float32Array;
  protected vertexOpacities: Float32Array;
  protected vertexSelecteds: Float32Array;
  protected vertexCenters: Float32Array;
  protected tileMap: TileMap;
  protected tileLayer: TileLayer;
  protected vertexCount = 0;
  protected fbs: WebGLFramebuffer[];
  protected spriteRenderer: SpriteRenderer;
  protected townTime$: Subject<number>;
  protected overlay: HTMLElement;
  protected lightPositions: number[];
  protected lightColors: number[];
  protected lightDropoffs: number[];
  protected lockCameraToMapBounds = true;
  protected gameSaver: GameSaver;
  protected dayLevel: number = 1.0;
  protected i18next: i18n;

  private inputs;
  private bounds: Bounds;
  private tileAngle: vec3;
  private settingsCog: SettingsCog;
  private canvasDimensions: Float32Array;
  private postProcessStartTimes: number[];
  private postProcessingEffects: number[];

  constructor() {
    this.gameCanvas = <HTMLCanvasElement>document.getElementById("game");
    this.gameGl = this.gameCanvas.getContext("webgl", {
      antialias: false,
      depth: true,
      alpha: true
    });

    this.uiCanvas = <HTMLCanvasElement>document.getElementById("imgui");
    this.uiGl = this.uiCanvas.getContext("webgl", {
      antialias: false,
      depth: false,
      alpha: true
    });
    this.params = new URLSearchParams("");

    const { gameGl } = this;

    /*    this.texture = gameGl.createTexture();*/
    //this.normalTexture = gameGl.createTexture();

    this.textureImageUrls = [];
    this.normalTextureImageUrls = [];
    this.textures = [];
    this.normalTextures = [];
    this.layerTextureCoords = [];
    this.layerOpacities = [];
    this.vertexPositions = new Float32Array();
    this.layerProgramVertexPositions = new Float32Array(SCREEN_POSITIONS.slice());
    this.vertexTangents = new Float32Array();
    this.vertexBitangents = new Float32Array();
    this.vertexNormals = new Float32Array();
    this.vertexOpacities = new Float32Array();
    this.vertexSelecteds = new Float32Array();
    this.vertexCenters = new Float32Array();
    this.tileMap = null;
    this.tileLayer = null;
    this.vertexCount = 0;
    this.fbs = [gameGl.createFramebuffer(), gameGl.createFramebuffer()];
    this.layerTextures = [gameGl.createTexture(), gameGl.createTexture()];
    this.inputs = [];
    this.loaded = false;
    this.tornDown = false;
    this.bounds = { top: -1, left: -1, right: -1, bottom: -1 };
    this.tileAngle = [0, 0, 0];
    this.townPlay = null;
    this.routerId = "";
    this.overlay = document.getElementById("overlay");
    this.lightPositions = new Array(12 * 3).fill(-1);
    this.lightColors = new Array(12 * 4).fill(-1);
    this.lightDropoffs = new Array(12).fill(1);
    this.canvasDimensions = new Float32Array(2).fill(100);
    this.postProcessStartTimes = [0, 0];
    this.postProcessingEffects = [0.0, 0, 0];
    this.spriteRenderer = new SpriteRenderer(gameGl, "main", {
      lightColors: new Float32Array(this.lightColors),
      lightPositions: new Float32Array(this.lightPositions),
      lightDropoffs: new Float32Array(this.lightDropoffs),
      dayLevel: this.dayLevel,
      checkOutOfBounds: true
    });
    this.gameSaver = new GameSaver();
    this.menu = document.createElement("div");
    this.menu.classList.add("town-menu");

    this.backgroundAudio = [];

    this.configureInput();
    setDisableDepthTesting(gameGl);
    this.resize = this.resize.bind(this);
    resize$.pipe(takeWhile(() => !this.tornDown)).subscribe(this.resize);

    this.townTime$ = new Subject();
    this.i18next = i18next;

    ImGui.default().then(() => {
      ImGui.CreateContext();
      const io = ImGui.GetIO();
      ImGui.StyleColorsDark();
      io.Fonts.AddFontDefault();
      ImGui_Impl.Init(this.uiCanvas);
    });
  }

  tearDown() {
    const { gameGl } = this;

    this.inputs.forEach(input => {
      input.tearDown();
    });

    this.townPlay.stop();

    this.backgroundAudio.forEach(audio => audio.stop());
    resetContext(gameGl);
    this.tornDown = true;
  }

  transitionToBattle$(sprites: Sprite[] = [], battleKey: string = null, battleCallback?: Function) {
    return defer(() => {
      if (battleKey && state.happenedEvents[this.name][battleKey] === true) {
        console.log(`Skipping battle ${battleKey} because it's already been fought...`);
        return of(true);
      }

      const lowerOpacityOnSprites = () => {
        this.spriteRenderer.sprites.forEach(sprite => {
          if (sprites.includes(sprite)) {
            sprite.opacity = sprite.opacity;
            sprite.scale[0] = sprite.scale[0] + 0.03;
            sprite.scale[1] = sprite.scale[1] + 0.03;
          } else {
            sprite.opacity = Math.max(sprite.opacity - 0.075, 0);
          }
        });
      };

      const defaultCallback = battleKey
        ? () => (state.happenedEvents[this.name][battleKey] = true)
        : () => {};

      state.battle.callback = battleCallback ? battleCallback : defaultCallback;

      this.postProcessStartTimes = [state.currentTime, state.currentTime];
      this.postProcessingEffects = [1.0, 0.0];

      const lowerOpacityOnSprites$ = this.townTime$.pipe(
        takeWhile(() => this.tornDown === false),
        tap(lowerOpacityOnSprites)
      );

      const pushBattle$ = timer(POST_PROCESS_EFFECT_DURATION).pipe(
        tap(() => {
          this.postProcessingEffects = [0.0, 0.0];
          state.router.push("battle");
        })
      );

      const transition$ = merge(lowerOpacityOnSprites$, pushBattle$);

      return transition$;
    });
  }

  configurePrograms() {
    const { gameGl } = this;
    const townProgram = compiledProgram(gameGl, TILE_VERT_SHADER, SPRITE_FRAG_SHADER);
    const layerProgram = compiledProgram(gameGl, TEXTURE_VERT_SHADER, TEXTURE_FRAG_SHADER);

    state.programWrappers.main = {
      program: townProgram,
      cache: { program: townProgram, attributes: {}, enabled: {}, uniforms: {} },
      buffers: {
        positions: gameGl.createBuffer(),
        normals: gameGl.createBuffer(),
        textureCoords: gameGl.createBuffer(),
        tangents: gameGl.createBuffer(),
        bitangents: gameGl.createBuffer(),
        opacities: gameGl.createBuffer(),
        selecteds: gameGl.createBuffer(),
        centers: gameGl.createBuffer()
      }
    };

    state.programWrappers.layer = {
      program: layerProgram,
      cache: { program: layerProgram, attributes: {}, enabled: {}, uniforms: {} },
      buffers: {
        positions: gameGl.createBuffer(),
        textureCoords: gameGl.createBuffer()
      }
    };

    cacheAttributeLocations(gameGl, "main", townProgram, state, ATTRIBUTE_NAMES);
    cacheUniformLocations(gameGl, "main", townProgram, state, UNIFORM_NAMES);
    cacheAttributeLocations(gameGl, "layer", layerProgram, state, [
      "aVertexPosition",
      "aTextureCoord"
    ]);
    cacheUniformLocations(gameGl, "layer", layerProgram, state, [
      "uLayerSampler",
      "uResolution",
      "uPercentDone",
      "uPostProcessingEffect"
    ]);
  }

  configureInput() {
    const pointerInput = new PointerInput(state);
    const keyboarInput = new KeyboardInput(state);
    this.inputs.push(pointerInput);
    this.inputs.push(keyboarInput);
  }

  tick(time: number) {
    if (!this.loaded) return;

    if (!state.paused) {
      this.townTime$.next(time);
      this.update(time);
    }

    this.render(time);
  }

  update(time) {
    const { currentPlayer } = state;

    if (state.currentPlayer) {
      currentPlayer.update(time);
      const interact = makeInteractionCommand(this.playerTarget, this.townPlay);
      moveCharacterAlongPath(state.currentPlayer, state.playerPath, interact);

      // If using keyboard
      if (state.playerPath.length === 0 && input.last)
        makeMoveCommand(state.currentPlayer, input.last, state)();
    }

    this.townPlay.characters.forEach(c => {
      if (c.sprite) c.sprite.update(time);
    });

    this.updateCamera();
    this.updateTilePositions();
  }

  // TODO: turn this into load function
  updateTilePositions() {
    if (this.vertexCount > 0 && this.vertexCount === this.vertexPositions.length) return;

    this.vertexPositions = new Float32Array(state.numTiles * QUAD_POSITIONS.length);
    this.vertexTangents = new Float32Array(state.numTiles * SpriteRenderer.TANGENT_LENGTH);
    this.vertexBitangents = new Float32Array(state.numTiles * SpriteRenderer.BITANGENT_LENGTH);
    this.vertexNormals = new Float32Array(state.numTiles * SpriteRenderer.NORMAL_LENGTH);
    this.vertexOpacities = new Float32Array(state.numTiles * QUAD_POSITIONS.length);
    this.vertexSelecteds = new Float32Array(state.numTiles * NUM_VERTS_PER_QUAD).fill(0.0);
    this.vertexCenters = new Float32Array(state.numTiles * NUM_VERTS_PER_QUAD * 3).fill(0.0);

    this.vertexCount = this.vertexPositions.length;

    const width: number = this.tileMap.width;

    for (let i = 0; i < state.numTiles; i++) {
      const col: number = i % width; // column tile is in in the tileset
      const row: number = Math.floor(i / width); // row tile is in in the tileset

      const angle: number = this.tileAngle[1];

      setQuadPositions(this.vertexPositions, col, row, i, angle, vecToMessWith);
    }

    this.updateBitangents();
  }

  updateBitangents() {
    const { vertexPositions } = this;

    // Every vertexPosition is made up of the following...
    // Quads, made up of two triangles
    // Tiangles, made up of vertices
    // Vertices, made up of x, y, z coords

    // Go triangle by triangle
    let offset = 0;
    for (let i = 0; i < vertexPositions.length; i = i + 9) {
      const vertexPosition = vertexPositions.subarray(i, i + 9);
      const tbn = createMapTbn(vertexPosition);

      // Every vertex in a triangle can have the same tangent/bitangent
      // TODO Why does it need to be 6 and not 3?
      for (let j = 0; j < 6; j++) {
        this.vertexTangents.set(tbn.tangent, offset);
        this.vertexBitangents.set(tbn.bitangent, offset);
        this.vertexNormals.set(tbn.normal, offset);
        offset += 3;
      }
    }

    this.vertexOpacities.fill(1);
  }

  updateCamera() {
    const { camera, inverseViewProjectionMatrix } = state.viz;

    this.resetMatrices();

    this.bounds = gamePageBounds(inverseViewProjectionMatrix, camera.vector);

    if (!this.lockCameraToMapBounds) return;

    // stop camera at edge of map
    const leftEdgeIsShowing = this.bounds.left < 0;
    const rightEdgeIsShowing = this.bounds.right > this.tileMap.width;

    const topEdgeIsShowing = this.bounds.top > 0;
    const bottomEdgeIsShowing = this.bounds.bottom < -this.tileMap.height;
    const cameraTooWide = this.bounds.right - this.bounds.left > this.tileMap.width * QUAD_WIDTH;
    const cameraTooTall = this.bounds.top - this.bounds.bottom > this.tileMap.height * QUAD_WIDTH;
    const atAnEdge =
      leftEdgeIsShowing ||
      rightEdgeIsShowing ||
      topEdgeIsShowing ||
      bottomEdgeIsShowing ||
      cameraTooTall ||
      cameraTooWide;

    if (leftEdgeIsShowing) camera.moveX(-this.bounds.left);
    if (rightEdgeIsShowing) camera.moveX(-(this.bounds.right - this.tileMap.width * QUAD_WIDTH));
    if (topEdgeIsShowing) camera.moveY(-this.bounds.top);
    if (bottomEdgeIsShowing) camera.moveY(-(this.tileMap.height * QUAD_WIDTH + this.bounds.bottom));
    if (cameraTooWide) camera.updateX((this.tileMap.width * QUAD_WIDTH) / 2);
    if (cameraTooTall) camera.updateY((-this.tileMap.height * QUAD_WIDTH) / 2);

    if (atAnEdge) {
      this.resetMatrices();
      this.bounds = gamePageBounds(inverseViewProjectionMatrix, camera.vector);
    }
  }

  render(timestamp: number) {
    this.configureTownProgram(timestamp);
    this.drawTilesToTexture(timestamp);
    this.drawLayerToScreen(timestamp, 0);
    this.configureTownProgram(timestamp);
    this.drawCharactersToTexture(timestamp);
    this.drawLayerToScreen(timestamp, 1);
    this.drawDebugMenu(timestamp);

    this.gameGl.flush();
  }

  drawDebugMenu(timestamp: number) {
    if (state.debug) {
      ImGui_Impl.NewFrame(timestamp);
      ImGui.NewFrame();
      ImGui.Begin("Debug");
      ImGui.Text(`Current time: ${round(state.currentTime, 2)}`);
      ImGui.PushItemWidth(ImGui.GetWindowWidth() * 0.5);
      ImGui.InputText("speed", (value = state.playbackSpeed.toString()) => {
        state.playbackSpeed = parseFloat(value);
        return value;
      });

      ImGui.PushStyleColor(ImGui.ImGuiCol.Button, ImGui.ImColor.HSV(0.7, 0.6, 0.6));
      if (ImGui.Button("Play")) state.paused = false;
      ImGui.SameLine();
      ImGui.PushStyleColor(ImGui.ImGuiCol.Button, ImGui.ImColor.HSV(0.3, 0.6, 0.6));
      if (ImGui.Button("Pause")) state.paused = true;
      ImGui.SameLine();
      ImGui.PushStyleColor(ImGui.ImGuiCol.Button, ImGui.ImColor.HSV(0.1, 0.6, 0.6));
      ImGui.Button("Rewind");
      ImGui.End();
      ImGui.ShowMetricsWindow();
      ImGui.EndFrame();
      ImGui.Render();
      ImGui_Impl.RenderDrawData(ImGui.GetDrawData());
    }
  }

  drawCharactersToTexture(timestamp) {
    const { gameGl } = this;
    setDrawToTexture(gameGl, this.fbs[1]);
    clearScreen(gameGl);
    this.spriteRenderer.renderNoGarbage(timestamp);
  }

  resize() {
    const { gameGl } = this;

    const width = state.canvasDimensions[0];
    const height = state.canvasDimensions[1];
    this.canvasDimensions.set([width, height]);

    this.fbs.forEach((fb, i) =>
      resizeFrameBuffer(fb, this.layerTextures[i], gameGl, width, height)
    );
  }

  resetMatrices() {
    const { viz } = state;
    const {
      projectionMatrix,
      fieldOfView,
      zNear,
      zFar,
      viewProjectionMatrix,
      viewMatrix,
      inverseViewProjectionMatrix
    } = viz;
    this.resetViewMatrix();
    mat4.perspective(projectionMatrix, fieldOfView, state.aspect, zNear, zFar);
    mat4.multiply(viewProjectionMatrix, projectionMatrix, viewMatrix);
    mat4.invert(inverseViewProjectionMatrix, viewProjectionMatrix);
  }

  resetViewMatrix() {
    const { viz } = state;
    const { viewMatrix, camera, up } = viz;
    const { vector: camVector } = camera;
    mat4.identity(viewMatrix);
    mat4.lookAt(viewMatrix, camVector, [camVector[0], camVector[1], 0], up);
  }

  drawTilesToTexture(timestamp: number) {
    const {
      gameGl,
      layerTextureCoords,
      textures,
      normalTextures,
      vertexNormals,
      vertexPositions,
      vertexTangents,
      vertexBitangents,
      vertexOpacities,
      vertexSelecteds,
      vertexCenters
    } = this;
    const { programWrappers } = state;
    const { main } = programWrappers;
    const { cache, buffers } = main;
    const { viz } = state;
    const modelMatrix = viz.modelMatrix as number[];
    const fb = this.fbs[0];

    // Clear composite tiles
    setDrawToTexture(gameGl, fb);
    clearScreen(gameGl);

    const texture = textures[0];
    const normalTexture = normalTextures[0];
    // Tile map
    gameGl.activeTexture(gameGl.TEXTURE0);
    gameGl.bindTexture(gameGl.TEXTURE_2D, texture);

    // Normal map
    gameGl.activeTexture(gameGl.TEXTURE1);
    gameGl.bindTexture(gameGl.TEXTURE_2D, normalTexture);

    gameGl.uniform1i(cache.uniforms.uSpriteSampler, 0);
    gameGl.uniform1i(cache.uniforms.uNormalSampler, 1);
    gameGl.uniform1f(cache.uniforms.uBillboard, 0.0);
    gameGl.uniformMatrix4fv(cache.uniforms.uModelMatrix, false, modelMatrix);

    setNormalsAttribDirect(gameGl, "main", state, buffers.normals, vertexNormals);
    setPositionsAttribDirect(gameGl, "main", state, buffers.positions, vertexPositions);
    setNormalsAttribDirect(gameGl, "main", state, buffers.normals, vertexNormals);
    setTangentsAttribDirect(gameGl, "main", state, buffers.tangents, vertexTangents);
    setBitangentsAttribDirect(gameGl, "main", state, buffers.bitangents, vertexBitangents);
    setOpacitiesAttribDirect(gameGl, "main", state, buffers.opacities, vertexOpacities);
    setSelectedsAttribDirect(gameGl, "main", state, buffers.selecteds, vertexSelecteds);
    setCentersAttribDirect(gameGl, "main", state, buffers.centers, vertexCenters);

    // Draw tiles to texture
    layerTextureCoords.forEach((textureCoords, i) => {
      this.drawTileTextures(textureCoords, i);
    });

    gameGl.uniform1f(cache.uniforms.uOpacity, 1.0);
  }

  configureTownProgram(timestamp: number) {
    const { gameGl, vertexTangents, vertexBitangents, tileMap } = this;
    const { programWrappers } = state;
    const { main } = programWrappers;
    const { program, cache, buffers } = main;
    const { viz } = state;
    const { projectionMatrix, modelMatrix, viewMatrix } = viz;
    gameGl.useProgram(program);
    enableBlending(gameGl);
    gameGl.uniformMatrix4fv(cache.uniforms.uProjectionMatrix, false, projectionMatrix);
    gameGl.uniformMatrix4fv(cache.uniforms.uModelMatrix, false, modelMatrix);
    gameGl.uniformMatrix4fv(cache.uniforms.uViewMatrix, false, viewMatrix);
    gameGl.uniform1f(cache.uniforms.uOpacity, 1.0);
    gameGl.uniform1f(cache.uniforms.uBillboard, 0.0);
    gameGl.uniform1f(cache.uniforms.uDayLevel, this.dayLevel);
    gameGl.uniform1f(cache.uniforms.uTime, state.currentTime / 200);
    gameGl.uniform2fv(cache.uniforms.uWorldStart, [0, 0]);
    gameGl.uniform2fv(cache.uniforms.uWorldEnd, [tileMap.width, tileMap.height]);
    gameGl.uniform3fv(cache.uniforms.uLightPos, [
      1.5 + 2 * Math.sin(state.currentTime / 300),
      -3.1,
      1.0
    ]);
    gameGl.uniform3fv(cache.uniforms.uLightPositions, this.lightPositions);
    gameGl.uniform4fv(cache.uniforms.uLightColors, this.lightColors);
    gameGl.uniform1fv(cache.uniforms.uLightDropoffs, this.lightDropoffs);
    gameGl.uniform1i(cache.uniforms.uCheckOutOfBounds, 1);

    setTangentsAttribDirect(gameGl, "main", state, buffers["tangents"], vertexTangents);
    setBitangentsAttribDirect(gameGl, "main", state, buffers["bitangents"], vertexBitangents);
  }

  drawTileTextures(textureCoords: Float32Array, index) {
    const { gameGl, layerOpacities, normalTextures, textures, vertexPositions } = this;
    const { programWrappers } = state;
    const { main } = programWrappers;
    const { buffers, cache } = main;

    //const texture = textures[0];
    //const normalTexture = normalTextures[0];

    // Tile map
    //gameGl.activeTexture(gameGl.TEXTURE0);
    //gameGl.bindTexture(gameGl.TEXTURE_2D, texture);

    // Normal map
    //gameGl.activeTexture(gameGl.TEXTURE1);
    //gameGl.bindTexture(gameGl.TEXTURE_2D, normalTexture);

    setTextureCoordsAttribDirect(gameGl, "main", state, buffers["textureCoords"], textureCoords);
    gameGl.uniform1f(cache.uniforms.uOpacity, layerOpacities[index]);
    gameGl.drawArrays(gameGl.TRIANGLES, 0, vertexPositions.length / 3);
  }

  drawLayerToScreen(timestamp: number, index: number) {
    const { gameGl, layerProgramVertexPositions } = this;
    const { programWrappers } = state;
    const { layer: layerWrapper } = programWrappers;
    const { program: layerProgram } = layerWrapper;
    const { buffers: layerBuffers } = layerWrapper;
    const { cache: layerCache } = layerWrapper;

    setDrawToScreen(gameGl);

    gameGl.useProgram(layerProgram);

    setPositionsAttribDirect(
      gameGl,
      "layer",
      state,
      layerBuffers.positions,
      layerProgramVertexPositions
    );

    setTextureCoordsAttribDirect(
      gameGl,
      "layer",
      state,
      layerBuffers.textureCoords,
      screenTextureCoords
    );

    const percentDone = clamp(
      (timestamp - this.postProcessStartTimes[index]) / POST_PROCESS_EFFECT_DURATION,
      0,
      1
    );

    // Previous layer tiles texture
    gameGl.activeTexture(gameGl.TEXTURE0);
    gameGl.bindTexture(gameGl.TEXTURE_2D, this.layerTextures[index]);
    gameGl.uniform2fv(layerCache.uniforms.uResolution, this.canvasDimensions);
    gameGl.uniform1i(layerCache.uniforms.uLayerSampler, 0);
    gameGl.uniform1f(layerCache.uniforms.uPostProcessingEffect, this.postProcessingEffects[index]);
    gameGl.uniform1f(layerCache.uniforms.uPercentDone, percentDone);

    // Draw composite tiles
    gameGl.drawArrays(gameGl.TRIANGLES, 0, layerProgramVertexPositions.length / 3);
  }

  configureTileInAnimation() {
    const { tileMap, layerTextureCoords, layerOpacities } = this;
    const tileSet: TileSet = tileMap.tilesets[0];

    const tileSize: vec2 = [tileMap.tilewidth, tileMap.tileheight];
    const tileSetDimensions: vec2 = [tileSet.imagewidth, tileSet.imageheight];

    for (let i = 0; i < tileMap.layers.length; i++) {
      const layer = tileMap.layers[i];
      const tiles = layer.data;
      const textureCoords = new Float32Array(TILE_TEXTURE_COORDS.length * tiles.length);
      layerTextureCoords.push(textureCoords);
      layerOpacities.push(layer.opacity);
      for (let j = 0; j < tiles.length; j++) {
        const tileIndex = tiles[j] - 1;
        setHalfPixelTextureCoords(
          textureCoords,
          TILE_TEXTURE_COORDS,
          j,
          tileIndex,
          tileSize,
          tileSetDimensions
        );
      }
    }
  }

  configureStateWithCurrentMap() {
    // TODO clean up gamestate so it doesn't need tileSet
    // e.g. use tileLayer and tileMap directly
    state.numTiles = this.tileLayer.data.length;
    state.tileLayer = this.tileLayer;
    state.tileMap = this.tileMap;
  }

  async load(params) {
    const { gameGl, name } = this;
    const dialogue = await import(`./routes/${name}.en.json`).then(i => i.default);
    const en = {};
    en[name] = dialogue;

    this.settingsCog = new SettingsCog(`/${name}`, "bottom");
    this.settingsCog.appendTo(this.menu);

    await this.i18next.init({
      ns: [name],
      lng: "en",
      resources: { en }
    });

    const tileSets: TileSet[] = this.tileMap.tilesets;
    this.textureImageUrls = await Promise.all(tileSets.map(ts => importMapImagePromise(imageNameFromUrl(ts.image))));
    this.normalTextureImageUrls = await Promise.all(tileSets.map(ts => importMapImagePromise(imageNameFromUrl(ts.image, true))));
    this.textures = await Promise.all(this.textureImageUrls.map(url => loadTexture(gameGl, url)));
    this.normalTextures = await Promise.all(this.normalTextureImageUrls.map(url => loadTexture(gameGl, url)));
    this.tileLayer = this.tileMap.layers.find(layer => layer.name === "main");
    this.configureStateWithCurrentMap();
    this.configureTileInAnimation();
  }

  start() {
    state.currentTown = this;
    this.townPlay.start();
    this.backgroundAudio.forEach((audio: Howl) => audio.play());
  }

  protected t(character: string, key: string) {
    return this.i18next.t(`${this.name}:${character}.${key}`);
  }
}

function imageNameFromUrl(url: string, normal = false): string {
  let fileName = lastElementFromArray(decodeURI(url).split("/"));
  const nameParts = fileName.split(".");
  const nameHalf = normal ? nameParts[0] + "-n" : nameParts[0];
  fileName = [nameHalf, nameParts[1]].join(".");
  return fileName;
}

function importMapImagePromise(imageName: string): Promise<string> {
  return import(`../assets/maps/images/${imageName}`).then(i => i.default);
}
