import { vec2, vec3, mat4 } from "gl-matrix";
import { Observable } from "rxjs";

import Router from "./router";
import Sprite from "./sprite";
import Camera from "./camera";
import Town from "./town";
import { StatsEl } from "./battle";

export enum Directions {
  Up = "up",
  UpIdle = "upIdle",
  Down = "down",
  DownIdle = "downIdle",
  Left = "left",
  LeftIdle = "leftIdle",
  Right = "right",
  RightIdle = "rightIdle"
}

export interface GameState {
  o$: {
    resize$: Observable<Event>;
  };
  happenedEvents: HappenedEvents;
  router?: Router;
  paused: boolean;
  debug: boolean;
  aspect: number;
  canvasDimensions: vec2;
  numTiles: number;
  moveable: boolean;
  inCinematic: boolean;
  programWrappers: { [key: string]: ProgramWrapper };
  tileMap?: TileMap;
  tileSet?: TileSet;
  tileLayer?: TileLayer;
  currentPlayer?: Sprite;
  playbackSpeed: number;
  battle: BattleState;
  currentTime: number;
  currentTown?: Town;
  input: {
    last?: Directions;
  };
  playerPath: vec2[];
  itchCdnPath?: string;
  viz: {
    camera: Camera;
    zNear: number;
    zFar: number;
    fieldOfView: number;
    up: vec3;
    inverseViewProjectionMatrix: mat4;
    modelMatrix: mat4;
    projectionMatrix: mat4;
    viewMatrix: mat4;
    viewProjectionMatrix: mat4;
  };
}

export interface GamePage {
  tearDown: () => void;
  load: (params: URLSearchParams) => void;
  start: () => void;
  configurePrograms: () => void;
  tick: (timestamp: number) => void;
  loaded: boolean;
  tornDown: boolean;
  routerId: string;
  menu?: HTMLElement;
  name: string;
}

export interface Settings {
  soundOn: boolean;
}

export interface ProgramCache {
  program: WebGLProgram;
  attributes: { [key: string]: number };
  enabled: { [key: number]: boolean };
  uniforms: { [key: string]: WebGLUniformLocation | null };
}

export interface HappenedEvents {
  [key: string]: {
    [key: string]: boolean;
  };
}

export interface ProgramWrapper {
  program: WebGLProgram;
  cache: ProgramCache;
  buffers: BufferWrapper;
}

export interface BufferWrapper {
  [key: string]: WebGLBuffer;
}

export interface Updateable {
  update(timestamp: number);
}

export interface Bounds {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

export interface Positionable {
  pos: vec2;
  pos3: vec3;
  size: vec2;
  updatePos(x: number, y: number);
  interactable: boolean;
}

export interface Drawable {
  draw(timestamp: number);
}

export interface TileMap {
  compressionLevel: number;
  editorsettings: any;
  height: number;
  infinite: boolean;
  layers: TileLayer[];
  nextlayerid: number;
  nextobjectid: 1;
  orientation: string;
  renderorder: string;
  tiledversion: string;
  tileheight: number;
  tilesets: TileSet[];
  tilewidth: number;
  type: string;
  version: number;
  width: number;
  default: any;
}

export interface TileLayer {
  data: number[];
  height: number;
  id: number;
  name: string;
  opacity: number;
  type: string;
  visibile: boolean;
  width: number;
  x: number;
  y: number;
}

export interface TileSet {
  columns: number;
  firstgid: number;
  image: string;
  imageheight: number;
  imagewidth: number;
  margin: number;
  name: string;
  spacing: number;
  tilecount: number;
  tileheight: number;
  tiles: Tile[];
  tilewidth: number;
}

export interface Tile {
  id: number;
  properties: Array<{ name: string; type: string; value: any }>;
}

export interface RainDrop {
  x: number;
  y: number;
  length: number;
  deltaY: number;
  cosAngle: number;
  sinAngle: number;
  fallSpeed: number;
}

export interface BattleCharacter {
  id: string;
  shortName: string;
  longName: string;
  sprite: Sprite;
  attack: number;
  maxHp: number;
  maxMp: number;
  hp: number;
  mp: number;
  wait: number;
  magic: BattleMagic[];
  items: BattleItem[];
  metaorb?: BattleMetaorb;
  nameEl?: HTMLElement;
  statsEl?: StatsEl;
  timer$?: Observable<number>;
  states$?: {
    dead$: Observable<boolean>;
    ready$: Observable<boolean>;
  };
  disabledMenus?: string[];
  side: "heroes" | "enemies";
}

export interface BattleState {
  state: "won" | "lost";
  enemies: BattleCharacter[];
  heroes: BattleCharacter[];
  callback: Function;
}

export interface BattleMagic {
  id: string;
  name: string;
  damage: number;
  mpDrain: number;
  effect: (v?: any) => void;
  type: ActionType.Magic;
}

export enum ActionType {
  Attack = "attack",
  Metaorb = "metaorb",
  Magic = "magic",
  Item = "item"
}

export interface BattleMetaorb {
  id: string;
  name: string;
  damage: number;
  effect: (v?: any) => void;
  type: ActionType.Metaorb;
}

export interface BattleItem {
  id: string;
  name: string;
  damage: number;
  effect: (v?: any) => void;
  type: ActionType.Item;
}

export type BattleAction = BattleMagic | BattleMetaorb | BattleItem;
