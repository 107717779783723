import { vec2, vec3 } from "gl-matrix";

import { Positionable, Updateable } from "./interfaces";
import { clamp } from "./helpers/math-helpers";
import { QUAD_WIDTH } from "./models";

export default class Camera implements Positionable, Updateable {
  public target?: Positionable;
  public interactable: boolean = false;
  private _pos: vec3;

  constructor(pos: vec3 = [0, 0, 10]) {
    this._pos = pos;
  }

  get pos() {
    return [this._pos[0], this._pos[1]] as vec2;
  }

  get pos3() {
    return this._pos;
  }

  get vector() {
    return this._pos;
  }

  get size() {
    return [0, 0] as vec2;
  }

  moveX(x: number) {
    vec3.set(this._pos, this._pos[0] + x, this._pos[1], this._pos[2]);
  }

  moveY(y: number) {
    vec3.set(this._pos, this._pos[0], this._pos[1] + y, this._pos[2]);
  }

  updatePos(x: number, y: number) {
    vec3.set(this._pos, x, y, this._pos[2]);
  }

  updatePos3(x: number, y: number, z: number) {
    vec3.set(this._pos, x, y, z);
  }

  updateX(x: number) {
    vec3.set(this._pos, x, this._pos[1], this._pos[2]);
  }

  updateY(y: number) {
    vec3.set(this._pos, this._pos[0], y, this._pos[2]);
  }

  updateZ(z: number) {
    vec3.set(this._pos, this._pos[0], this.pos[1], z);
  }

  zoom(amount) {
    this._pos[2] += amount;
    this._pos[2] = clamp(this._pos[2], 0.1, 100);
  }

  update(timestamp: number) {
    if (this.target) {
      const targetPos = this.target.pos;
      this.updatePos(targetPos[0], targetPos[1] + QUAD_WIDTH / 2);
    }
  }
}
