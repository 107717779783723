import { mat4, vec4 } from "gl-matrix";

import { state, MAX_DIMENSION } from "../index";

import {
  clamp,
  dimensionsMaintainingAspectWithMax,
  gamePageBounds,
  randomNumberBetween
} from "../helpers/math-helpers";
import { randomElement } from "../helpers/array-helpers";
import Cloud from "../sprites/cloud";
import Sprite from "../sprite";
import SpriteRenderer from "../sprite-renderer";
import { GamePage, Directions, Bounds, RainDrop } from "../interfaces";
import GameSaver from "../game-saver";
import SPRITE_FRAG_SHADER from "../shaders/sprite-fragment.glsl";
import TILE_VERT_SHADER from "../shaders/tile-vertex.glsl";
import AudioPlayback from "../audio-playback";
import Camera from "../camera";
import { resizeRain, updateRain } from "../weather";
import html, { styles } from "./title-menu-html";
import SettingsCog from "../settings-cog";

import {
  clearScreenWithColor,
  compiledProgram,
  cacheAttributeLocations,
  cacheUniformLocations,
  resetContext,
  setDisableDepthTesting,
  setDrawToScreen,
  enableBlending
} from "../helpers/webgl-helpers";

const rainThunderUrl = require("../../assets/audio/sfx/rain-thunder.wav").default;

const directions = [Directions.Left, Directions.Right, Directions.Down, Directions.Up];
const numClouds = 10;

const numRainDrops = 400;
const bigFont = "bold 50pt 'Times New Roman', Times, serif";
const minOpacity = 0.2;

const UNIFORM_NAMES = [
  "uWorldStart",
  "uWorldEnd",
  "uModelMatrix",
  "uViewMatrix",
  "uProjectionMatrix",
  "uSpriteSampler",
  "uNormalSampler",
  "uLightPositions",
  "uLightColors",
  "uLightDropoffs",
  "uDayLevel",
  "uOpacity",
  "uTime",
  "uBillboard"
];

const ATTRIBUTE_NAMES = [
  "aOpacity",
  "aSelected",
  "aVertexPosition",
  "aVertexNormal",
  "aTextureCoord",
  "aVertexTangent",
  "aVertexBitangent",
  "aCenter"
];

export default class TitleMenu implements GamePage {
  public menu: HTMLElement;
  public loaded: boolean;
  public routerId: string;
  public name: string = "title";

  fireCanvas: HTMLCanvasElement;
  shadowCanvas: HTMLCanvasElement;

  rainCanvas: HTMLCanvasElement;
  rainDropPos: Array<RainDrop>;
  firePixels: number[];
  opacity: number;
  squareSize: number;
  fireHeight: number;
  fireWidth: number;
  tornDown: boolean;
  skyRgba: vec4;

  private gameCanvas: HTMLCanvasElement;
  private gameGl: WebGLRenderingContext;
  private clouds: Sprite[];
  private cloudSpeeds: number[];
  private spriteRenderer: SpriteRenderer;
  private bounds: Bounds;
  private lightPositions: number[];
  private lightColors: number[];
  private lightDropoffs: number[];
  private gameSaver: GameSaver;
  private settingsCog: SettingsCog;

  constructor() {
    this.gameCanvas = <HTMLCanvasElement>document.getElementById("game");
    this.gameGl = this.gameCanvas.getContext("webgl");
    this.clouds = [];
    this.cloudSpeeds = [];
    this.bounds = { top: -1, left: -1, bottom: -1, right: -1 };
    this.gameSaver = new GameSaver();

    this.menu = document.createElement("div");
    this.menu.insertAdjacentHTML("beforeend", html);
    this.settingsCog = new SettingsCog("/", "top");
    this.loaded = false;
    const { menu } = this;

    this.routerId = "";
    this.fireCanvas = <HTMLCanvasElement>menu.querySelector("#js-fire-text");
    this.shadowCanvas = <HTMLCanvasElement>menu.querySelector("#js-shadow-text");
    this.rainCanvas = <HTMLCanvasElement>menu.querySelector("#js-rain");
    this.firePixels = [];
    this.opacity = 1;
    this.squareSize = 4;
    this.fireHeight = 10;
    this.fireWidth = 10;
    this.tornDown = false;
    this.skyRgba = [0.1, 0.1, 0.1, 1];
    this.rainDropPos = new Array(numRainDrops).fill({
      x: -1,
      y: -1,
      length: 10,
      deltaY: 0,
      sinAngle: 0,
      cosAngle: 0,
      fallSpeed: 0
    });

    for (let i = 0; i < numClouds; i++) {
      const cloud = new Cloud(this.gameGl);
      this.clouds.push(cloud);
      const direction = randomElement(directions);
      cloud.setDirection(direction);
      cloud.setScale(2, 2);
    }

    this.lightPositions = new Array(12 * 3).fill(-1);
    this.lightPositions[0] = -1;
    this.lightPositions[1] = 1;
    this.lightPositions[2] = 1;
    this.lightColors = new Array(12 * 4).fill(0.0);
    this.lightColors[0] = 1;
    this.lightColors[1] = 1;
    this.lightColors[2] = 1;
    this.lightColors[3] = 1;
    this.lightDropoffs = new Array(12).fill(1);

    this.spriteRenderer = new SpriteRenderer(this.gameGl, "title", {
      lightColors: new Float32Array(this.lightColors),
      lightDropoffs: new Float32Array(this.lightDropoffs),
      lightPositions: new Float32Array(this.lightPositions),
      dayLevel: 0.2,
      checkOutOfBounds: false
    });

    this.update = this.update.bind(this);
    this.resize = this.resize.bind(this);
    window.addEventListener("resize", this.resize);
    this.resize();
  }

  configureClouds() {
    for (let i = 0; i < numClouds; i++) {
      const cloud = this.clouds[i];
      const widthOffset = cloud.size[0] / 4;
      const heightOffset = cloud.size[1] / 4;

      this.cloudSpeeds[i] = randomNumberBetween(-0.0001, -0.002);
      const x = randomNumberBetween(
        this.bounds.left + widthOffset,
        this.bounds.right - widthOffset
      );
      const y = randomNumberBetween(
        this.bounds.top + heightOffset,
        this.bounds.bottom - heightOffset
      );

      cloud.updatePos(x, y);
    }

    this.spriteRenderer.removeAll();
    this.clouds.forEach(cloud => this.spriteRenderer.add(cloud));
    this.spriteRenderer.sort();
  }

  configurePrograms() {
    const { gameGl } = this;
    const titleProgram = compiledProgram(gameGl, TILE_VERT_SHADER, SPRITE_FRAG_SHADER);

    state.programWrappers.title = {
      program: titleProgram,
      cache: { program: titleProgram, attributes: {}, enabled: {}, uniforms: {} },
      buffers: {
        positions: gameGl.createBuffer(),
        normals: gameGl.createBuffer(),
        textureCoords: gameGl.createBuffer(),
        tangents: gameGl.createBuffer(),
        bitangents: gameGl.createBuffer(),
        modelMatrices: gameGl.createBuffer()
      }
    };

    setDrawToScreen(gameGl);
    setDisableDepthTesting(gameGl);
    enableBlending(gameGl);

    cacheAttributeLocations(gameGl, "title", titleProgram, state, ATTRIBUTE_NAMES);
    cacheUniformLocations(gameGl, "title", titleProgram, state, UNIFORM_NAMES);
  }

  tearDown() {
    window.removeEventListener("resize", this.resize);
    this.tornDown = true;
    console.log("Tearing down title menu...");

    const { gameGl } = this;
    clearScreenWithColor(gameGl, [0, 0, 0, 1]);
    AudioPlayback.soundBag["rain-thunder"].stop();
    resetContext(gameGl);
  }

  drawShadowText() {
    const { fireCanvas, shadowCanvas } = this;
    const shadowCtx = shadowCanvas.getContext("2d");
    let canvasWidth = fireCanvas.width;
    let canvasHeight = fireCanvas.height;
    const textX = canvasWidth / 2;
    const textY = canvasHeight / 2;

    shadowCtx.clearRect(0, 0, canvasWidth, canvasHeight);
    shadowCtx.fillStyle = "#000";
    shadowCtx.font = bigFont;
    shadowCtx.fillText("Matt Fantasy VI", Math.floor(textX + 2), Math.floor(textY + 2));
  }

  scheduleLightning() {
    if (this.tornDown) return; // don't schedule lighting if we're torn down

    this.opacity = 1.0;
    const time = randomNumberBetween(4000, 6000);
    setTimeout(() => {
      this.opacity = 1.0;
      this.scheduleLightning();
    }, time);
  }

  spreadFire(index) {
    const { fireWidth } = this;
    const rand = Math.round(Math.random() * 2.4);
    const horizontal = index + rand - 1;
    this.firePixels[horizontal - fireWidth] = this.firePixels[index] - rand;
  }

  updateFire() {
    const { fireCanvas, fireHeight, fireWidth } = this;
    const fireCtx = fireCanvas.getContext("2d");
    let canvasWidth = fireCanvas.width;

    fireCtx.clearRect(0, 0, canvasWidth, canvasWidth);
    for (let x = 0; x < fireWidth; x++) {
      for (let y = 1; y < fireHeight; y++) {
        this.spreadFire(y * fireWidth + x);
      }
    }
  }

  tick(timestamp: number) {
    if (!this.loaded) return;
    this.update(timestamp);
    this.render(timestamp);
  }

  update(timestamp: number) {
    this.updateFire();
    updateRain(this);
    this.updateLightning();

    this.clouds.forEach((cloud, i) => {
      const speed = this.cloudSpeeds[i];
      if (cloud.pos[0] + cloud.size[0] / 2 < this.bounds.left) {
        cloud.updatePos(this.bounds.right + cloud.size[0] / 2, cloud.pos[1]);
      } else {
        cloud.updatePos(cloud.pos[0] + speed, cloud.pos[1]);
      }
      cloud.update(timestamp);
    });

    const { fireCanvas, fireWidth, squareSize } = this;
    const fireCtx = fireCanvas.getContext("2d");

    let canvasWidth = fireCanvas.width;
    let canvasHeight = fireCanvas.height;
    const textX = canvasWidth / 2;
    const textY = canvasHeight / 2;

    for (let i = 0; i < this.firePixels.length; i++) {
      const colorIndex = this.firePixels[i];
      fireCtx.fillStyle = colors[colorIndex];
      const x = (i % fireWidth) * squareSize;
      const y = Math.floor(i / fireWidth) * squareSize;
      fireCtx.fillRect(x, y, squareSize, squareSize);
    }

    fireCtx.globalCompositeOperation = "destination-in";
    fireCtx.fillStyle = "rgba(1,0,0,1)";
    fireCtx.fillText("Matt Fantasy VI", Math.floor(textX), Math.floor(textY));
    fireCtx.globalCompositeOperation = "source-over";
  }

  updateLightning() {
    if (this.opacity < minOpacity) return;
    const lightning = randomNumberBetween(-0.08, 0.06);
    this.opacity = clamp(this.opacity + lightning, minOpacity, 1);
  }

  render(timestamp: number) {
    this.resetMatrices();

    const { projectionMatrix, viewMatrix, modelMatrix } = state.viz;

    const { gameGl, opacity } = this;
    const { programWrappers } = state;
    const { title } = programWrappers;
    const { program, cache } = title;

    clearScreenWithColor(gameGl, this.skyRgba);

    const lightDistance = clamp(opacity, minOpacity + 0.1, 1);

    gameGl.useProgram(program);
    gameGl.uniformMatrix4fv(cache.uniforms.uModelMatrix, false, modelMatrix);
    gameGl.uniformMatrix4fv(cache.uniforms.uProjectionMatrix, false, projectionMatrix);
    gameGl.uniformMatrix4fv(cache.uniforms.uViewMatrix, false, viewMatrix);
    gameGl.uniform2fv(cache.uniforms.uWorldStart, [this.bounds.left, this.bounds.bottom]);
    gameGl.uniform2fv(cache.uniforms.uWorldEnd, [this.bounds.right, this.bounds.top]);
    gameGl.uniform1f(cache.uniforms.uOpacity, 1.0);
    gameGl.uniform1f(cache.uniforms.uBillboard, 0.0);
    gameGl.uniform1f(cache.uniforms.uLightDropOff, lightDistance);
    gameGl.uniform1f(cache.uniforms.uDayLevel, 0);
    gameGl.uniform1f(cache.uniforms.uTime, timestamp / 200);
    gameGl.uniform3fv(cache.uniforms.uLightPositions, this.lightPositions);
    gameGl.uniform4fv(cache.uniforms.uLightColors, this.lightColors);
    gameGl.uniform1fv(cache.uniforms.uLightDropoffs, this.lightDropoffs);

    this.spriteRenderer.renderNoGarbage(timestamp);
  }

  resetMatrices() {
    const {
      projectionMatrix,
      viewProjectionMatrix,
      viewMatrix,
      inverseViewProjectionMatrix,
      fieldOfView,
      zNear,
      zFar
    } = state.viz;
    this.resetViewMatrix();
    mat4.perspective(projectionMatrix, fieldOfView, state.aspect, zNear, zFar);
    mat4.multiply(viewProjectionMatrix, projectionMatrix, viewMatrix);
    mat4.invert(inverseViewProjectionMatrix, viewProjectionMatrix);
  }

  resetViewMatrix() {
    const { viewMatrix, camera, up } = state.viz;
    mat4.identity(viewMatrix);
    mat4.lookAt(viewMatrix, camera.vector, [camera.vector[0], camera.vector[1], 0], up);
  }

  async load(params) {
    state.viz.camera = new Camera();
    state.viz.zNear = 0.1;
    state.viz.zFar = 100.0;
    state.viz.up = [0, 1, 0];

    const audioPlayback = AudioPlayback.getInstance();
    const { fireCanvas, shadowCanvas } = this;

    const fireCtx = fireCanvas.getContext("2d");
    const shadowCtx = shadowCanvas.getContext("2d");

    fireCtx.font = bigFont;
    fireCtx.textAlign = "center";
    shadowCtx.textAlign = "center";

    this.drawShadowText();
    this.scheduleLightning();

    this.resize();

    await Promise.all(this.clouds.map(cloud => cloud.load()));

    if (this.gameSaver.listSaves().length > 0) {
      const loadGame = this.menu.querySelector("#js-load-game");
      loadGame.classList.remove(styles["title-menu-option--disabled"]);
      loadGame.innerHTML = "<a href='/minetown?load-game'>LOAD GAME</a>";
    }

    audioPlayback.add("rain-thunder", rainThunderUrl, { loop: true });
    this.settingsCog.appendTo(this.menu);
    this.loaded = true;
  }

  resize() {
    const { fireCanvas, squareSize, fireHeight, fireWidth } = this;
    const dimensions = dimensionsMaintainingAspectWithMax(
      MAX_DIMENSION,
      state.aspect,
      window.innerWidth,
      window.innerHeight
    );
    const width = dimensions[0];
    const height = dimensions[1];
    let canvasWidth = fireCanvas.width;
    let canvasHeight = fireCanvas.height;

    this.fireWidth = Math.floor(canvasWidth / squareSize);
    this.fireHeight = Math.floor(canvasHeight / squareSize);

    this.firePixels = new Array(fireWidth * fireHeight).fill(0);

    for (let x = 0; x < fireWidth; x++) {
      this.firePixels[(fireHeight - 1) * fireWidth + x] = 36;
    }

    resizeRain(this, width, height);

    this.resetMatrices();
    const { inverseViewProjectionMatrix, camera } = state.viz;
    this.bounds = gamePageBounds(inverseViewProjectionMatrix, camera.vector);
    this.configureClouds();
  }

  start() {
    const audio = AudioPlayback.getInstance();
    audio.play("rain-thunder");
  }
}

const colors = [
  "rgb(7, 7, 7)",
  "rgb(31, 7, 7)",
  "rgb(47, 15, 7)",
  "rgb(71, 15, 7)",
  "rgb(87, 23, 7)",
  "rgb(103, 31, 7)",
  "rgb(119, 31, 7)",
  "rgb(143, 39, 7)",
  "rgb(159, 47, 7)",
  "rgb(175, 63, 7)",
  "rgb(191, 71, 7)",
  "rgb(199, 71, 7)",
  "rgb(223, 79, 7)",
  "rgb(223, 87, 7)",
  "rgb(223, 87, 7)",
  "rgb(215, 95, 7)",
  "rgb(215, 95, 7)",
  "rgb(215, 103, 15)",
  "rgb(207, 111, 15)",
  "rgb(207, 119, 15)",
  "rgb(207, 127, 15)",
  "rgb(207, 135, 23)",
  "rgb(199, 135, 23)",
  "rgb(199, 143, 23)",
  "rgb(199, 151, 31)",
  "rgb(191, 159, 31)",
  "rgb(191, 159, 31)",
  "rgb(191, 167, 39)",
  "rgb(191, 167, 39)",
  "rgb(191, 175, 47)",
  "rgb(183, 175, 47)",
  "rgb(183, 183, 47)",
  "rgb(183, 183, 55)",
  "rgb(207, 207, 111)",
  "rgb(223, 223, 159)",
  "rgb(239, 239, 199)",
  "rgb(255, 255, 255)"
];

// References:
// http://fabiensanglard.net/doom_fire_psx/
// https://github.com/filipedeschamps/doom-fire-algorithm
