import { fromEvent, merge } from "rxjs";
import { filter, takeWhile, throttleTime } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";

import { state } from "./index";

import html from "./templates/settings-cog-html";

export default class SettingsCog {
  public div: HTMLElement;
  private dialogueId?: string;
  private currentRoute: string;
  private topOrBottom: "top" | "bottom";

  constructor(currentRoute: string, topOrBottom: "top" | "bottom") {
    this.currentRoute = currentRoute;
    this.topOrBottom = topOrBottom;
  }

  get onPage() {
    return !!(this.dialogueId && document.querySelector(`[data-dialogue-id=${this.dialogueId}]`));
  }

  createBox() {
    this.dialogueId = `settings-cog-${uuidv4()}`;
    this.div = document.createElement("div");
    this.div.insertAdjacentHTML("beforeend", html);

    const link = <HTMLElement>this.div.querySelector("#js-settings-cog-link");
    link.style[this.topOrBottom] = "20px";
    link.setAttribute("href", `/settings?back-link=${this.currentRoute}`);
  }

  appendTo(element: HTMLElement) {
    this.createBox();
    element.append(this.div);
  }

  listenForClicks(callback) {
    const notPaused = filter(() => !state.paused);
    const mouseUp$ = fromEvent(document, "mouseup");
    const touchEnd$ = fromEvent(document, "touchend");
    const onPage = () => this.onPage;
    const click$ = merge(mouseUp$, touchEnd$).pipe(throttleTime(250), notPaused);
    click$.pipe(takeWhile(onPage)).subscribe(() => callback());
  }

  remove() {
    this.dialogueId = null;
    if (this.onPage && this.div.parentNode) this.div.parentNode.removeChild(this.div);
  }

  handleClick() {
    this.remove();
  }
}
