import { styles as s } from "../templates/atb-html";
import { hasClass } from "../helpers/style-helpers";

export const setReady = setClass(s["ready"]);
export const unsetReady = unsetClass(s["ready"]);

const unsetSinkable = unsetClass("sinkable");
const setSinkable = setClass("sinkable");

export const unsetSelectable = unsetClass(s["selectable"]);
export const setSelectable = setClass(s["selectable"]);

export const unsetHide = unsetClass(s["hide"]);
export const setHide = setClass(s["hide"]);

export const unsetDisplayNone = unsetClass(s["display-none"]);
export const setDisplayNone = setClass(s["display-none"]);

const unsetShrink = unsetClass("shrink");
const setShrink = setClass("shrink");

const setWon = setClass("won");
const unsetWon = unsetClass("won");

export const setSelected = setClass(s["selected"]);

export function unsetSelected(element: HTMLElement) {
  if (!element) return;
  element.classList.remove(s["selected"]);
  const children = Array.from(element.getElementsByClassName(s["selected"]));
  children.forEach(el => el.classList.remove(s["selected"]));
}

const setMagicBallBackground = setStyle(
  "background",
  v => `radial-gradient(${v} 0%, rgba(0, 0, 0, 0) 50%)`
);

const translateFormatter = combineTransformFunction(
  "translate",
  (left, top) => `translate(${left}px, ${top}px)`
);

const rotateFormatter = combineTransformFunction("rotate", degrees => `rotate(${degrees}deg)`);
const scaleFormatter = combineTransformFunction("scale", (x, y) => `scale(${x}, ${y})`);

const setTranslate = setStyle("transform", translateFormatter);
const setRotate = setStyle("transform", rotateFormatter);
const setScale = setStyle("transform", scaleFormatter);
const updateWaitWidth = setStyle("width", v => `${v}%`);
const moveTop = setStyle("top", v => `${v}px`);
const moveLeft = setStyle("left", v => `${v}px`);
const setHeight = setStyle("height", v => `${v}px`);
const setWidth = setStyle("width", v => `${v}px`);
const setBackgroundImage = setStyle("backgroundImage");
const setOpacity = setStyle("opacity");
const unsetOpacity = unsetStyle("opacity");

const setDead = el => setClass("dead");
const unsetDead = el => unsetClass("dead");

export function getAvailableActions(el = document) {
  return Array.from(el.getElementsByClassName("js-action"));
}

function combineTransformFunction(transformProp, formattingFunction) {
  return function() {
    const el = arguments[arguments.length - 1];

    const updatedProps = [];
    const props = transformProps(el);
    // remove prop for list if it already exists
    const existingProp = props.find(p => p.includes(transformProp));
    if (existingProp) props.splice(props.indexOf(existingProp), 1);

    // can remove prop from transform by omitting formatting function
    if (formattingFunction) props.push(formattingFunction(...Array.from(arguments)));

    const scale = props.find(p => p.includes("scale"));
    const rotate = props.find(p => p.includes("rotate"));
    const translate = props.find(p => p.includes("translate"));

    // Order transforms
    if (translate) updatedProps.push(translate);
    if (scale) updatedProps.push(scale);
    if (rotate) updatedProps.push(rotate);

    return updatedProps.join(" ");
  };
}

// function getRotation(el) {
//   const props = transformProps(el);
//   const rotation = props.find(prop => prop.includes("rotate"));
//   if (!rotation) return 0;
//   const match = rotation.match(/rotate\((\d+)deg\)/);
//   return match[1];
// }

function transformProps(el) {
  const transform = el.style.transform;
  // Extract scale, rotate, translate from transform style
  return transform.split(/([a-zA-Z]*\(.*?\))/).filter(p => p.length > 1);
}

function setStyle(propName, formattingFunction = (v?: any) => v) {
  return function(el: HTMLElement, ...argys: any[]) {
    const args = Array.from(arguments).slice(1, arguments.length);
    args.push(el);
    const formattedValue = formattingFunction(...args);

    if (el.style[propName] !== formattedValue) {
      el.style[propName] = formattedValue;
    }
  };
}

function unsetStyle(propName) {
  return function(el) {
    if (!!el.style[propName]) {
      el.style[propName] = null;
    }
  };
}

function setClass(className) {
  return function(el) {
    if (el.forEach === undefined) el = [el];
    el.forEach(e => {
      if (!hasClass(e, className)) {
        e.classList.add(className);
      }
    });
  };
}

function unsetClass(className) {
  return function(el) {
    if (el.forEach === undefined) el = [el];
    el.forEach(e => {
      if (hasClass(e, className)) {
        e.classList.remove(className);
      }
    });
  };
}

// function setAllCharactersAsSinkable() {
//   const els = [heroNameEls, heroSpriteEls, enemySpriteEls, enemyHitPointEls].flat();
//   els.forEach(setSelectable);
//   els.forEach(setSinkable);
// }

// function unsetAllCharactersAsSinkable() {
//   const els = [heroNameEls, heroSpriteEls, enemySpriteEls, enemyHitPointEls].flat();
//   els.forEach(unsetSelectable);
//   els.forEach(unsetSinkable);
// }

// function highlightHeroes() {
//   const els = [heroNameEls, heroSpriteEls].flat();
//   els.forEach(setSelectable);
//   els.forEach(setSinkable);
// }

// function unhighlightHeroes() {
//   const els = [heroNameEls, heroSpriteEls].flat();
//   els.forEach(unsetSelectable);
//   els.forEach(unsetSelected);
//   els.forEach(unsetSinkable);
// }

// function unhighlightHero(heroIndex) {
//   const els = [heroSpriteEls[heroIndex], heroNameEls[heroIndex]];
//   els.forEach(unsetSelectable);
//   els.forEach(unsetSelected);
//   els.forEach(unsetSinkable);
// }

// function unhighlightAllCharacters() {
//   unhighlightHeroes();
//   unhighlightEnemies();
// }

// function highlightHero(heroIndex) {
//   unhighlightHeroes();
//   const el = heroNameEls[heroIndex];
//   const spriteEl = heroSpriteEls[heroIndex];
//   setSelected(el);
//   setSelected(spriteEl);
// }

// function highlightEnemies() {
//   const els = [enemySpriteEls, enemyHitPointEls].flat();
//   els.forEach(setSelectable);
//   els.forEach(setSinkable);
// }

// function unhighlightEnemies() {
//   const els = [enemySpriteEls, enemyHitPointEls].flat();
//   els.forEach(unsetSelectable);
//   els.forEach(unsetSinkable);
// }

// function setHeroReady(heroIndex) {
//   const heroName = heroNameEls[heroIndex];
//   const heroSprite = heroSpriteEls[heroIndex];
//   [heroName, heroSprite].forEach(el => {
//     setSelectable(el);
//     setReady(el);
//   });
// }

// function unsetHeroReady(heroIndex) {
//   const heroName = heroNameEls[heroIndex];
//   const heroSprite = heroSpriteEls[heroIndex];
//   [heroName, heroSprite].forEach(el => {
//     unsetSelectable(el);
//     unsetReady(el);
//   });
// }

// function updateIfDifferent(element, value) {
//   if (element.textContent !== value) {
//     element.textContent = value;
//   }
// }

function createRow(text, classes, data = {}) {
  const row = document.createElement("div");
  row.classList.add(...classes);
  Object.keys(data).forEach(key => {
    row.dataset[key] = data[key];
  });

  row.textContent = text;

  return row;
}

function createActionRow(text: string, action: string, id: string, actionClass) {
  const classes = [s["row"], s["selectable"], s["action"], "js-clearable", actionClass];
  const data = { action, id };
  return createRow(text, classes, data);
}

function fillMenuFunction(type: string, actionClass: string = "js-action", nameKey = "name") {
  return function(element: HTMLElement, things: any[]) {
    clearRows(element);
    things.forEach(thing => {
      element.appendChild(createActionRow(thing[nameKey], type, thing.id, actionClass));
    });
  };
}

export const fillMagicMenu = fillMenuFunction("magic");
export const fillItemMenu = fillMenuFunction("item");
export const fillMetaOrbMenu = fillMenuFunction("metaorb");
export const fillSinkMenu = fillMenuFunction("sink", "js-sink", "longName");

function clearRows(element: HTMLElement) {
  const clearables = element.getElementsByClassName("js-clearable");
  Array.from(clearables).forEach(el => el.remove());
}
