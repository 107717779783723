import { BehaviorSubject } from "rxjs";

import { vec2, mat4 } from "gl-matrix";

import Sprite from "../sprite";
import { loadTexture } from "../helpers/webgl-helpers";

import spriteSheetImageUrl from "../../assets/sprites/cloud-sheet.png";
import spriteSheetNormalImageUrl from "../../assets/sprites/cloud-sheet-n.png";

export default class Cloud extends Sprite {
  tileIndex: number;
  tileSize: vec2;
  tileSetDimensions: vec2;
  texture: WebGLTexture | null;
  textureUrl: string;
  normalTexture: WebGLTexture | null;
  animations: { [key: string]: number[] };
  animationName$: BehaviorSubject<string>;
  modelMatrix: number[];
  name: string;

  constructor(gl: WebGLRenderingContext) {
    super(gl);
    this.modelMatrix = <number[]>mat4.create();
    this.tileSize = [95, 47];
    this.tileSetDimensions = [380, 47];
    this.tileIndex = 0;
    this.textureUrl = spriteSheetImageUrl;
    this.name = "cloud";

    this.animations = {
      left: [0],
      up: [1],
      down: [2],
      right: [3],
      leftIdle: [0],
      upIdle: [1],
      downIdle: [2],
      rightIdle: [3]
    };
    this.opacity = 0.7;

    this.setScale(this.tileSize[0] / this.tileSize[1], 1);
  }

  async load() {
    const { gl } = this;
    this.texture = await loadTexture(gl, spriteSheetImageUrl);
    this.normalTexture = await loadTexture(gl, spriteSheetNormalImageUrl);
    return true;
  }
}
