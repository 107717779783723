import { vec3 } from "gl-matrix";
import { clamp } from "./helpers/math-helpers";

const DEFAULT_OPTIONS: TwarnOptions = {
  duration: 1000,
  yoyo: false,
  repeat: 0
};

export default class Twarn {
  duration: number = DEFAULT_OPTIONS.duration;
  start: vec3 = vec3.create();
  current: vec3 = vec3.create();
  end: vec3 = vec3.create();
  startTime: number = 0;
  endTime: number = 0;
  complete: boolean = false;
  count: number = 0;
  yoyo: boolean;
  repeat: number;
  id: string;
  onUpdateCallback: CallbackFunction;
  onCompleteCallback: CallbackFunction;

  constructor(start: vec3, end: vec3, startTime: number, options: TwarnOptions = DEFAULT_OPTIONS) {
    vec3.copy(this.start, start);
    vec3.copy(this.end, end);
    this.duration = "duration" in options ? options.duration : DEFAULT_OPTIONS.duration;
    this.repeat = "repeat" in options ? options.repeat : DEFAULT_OPTIONS.repeat;
    this.yoyo = "yoyo" in options ? options.yoyo : DEFAULT_OPTIONS.yoyo;
    this.id = "id" in options ? options.id : DEFAULT_OPTIONS.id;
    this.startTime = startTime;
    this.endTime = startTime + this.duration;
  }

  restart(start: vec3, end: vec3, startTime: number) {
    vec3.copy(this.start, start);
    vec3.copy(this.end, end);
    this.startTime = startTime;
    this.endTime = startTime + this.duration;
    this.count = 0;
    this.complete = false;
  }

  update(timestamp: number) {
    if (this.complete) return;
    const { startTime, endTime } = this;
    const t = clamp(1 - (endTime - timestamp) / (endTime - startTime), 0, 1);
    vec3.lerp(this.current, this.start, this.end, t);

    if (vec3.equals(this.current, this.end)) {
      this.count += 1;
      this.startTime = this.endTime;
      this.endTime += this.duration;

      if (this.yoyo) {
        const swap = this.start;
        this.start = this.end;
        this.end = swap;
      }

      if (this.count >= this.repeat) {
        this.complete = true;
        this.onUpdateCallback(this.current);
        if (this.onCompleteCallback) this.onCompleteCallback(this.current);
        return;
      }
    }

    this.onUpdateCallback(this.current);
  }

  onUpdate(callback: CallbackFunction) {
    this.onUpdateCallback = callback;
    return this;
  }

  onComplete(callback: CallbackFunction) {
    this.onCompleteCallback = callback;
    return this;
  }
}

type CallbackFunction = (pos: vec3) => any;

interface TwarnOptions {
  repeat?: number;
  duration?: number;
  yoyo?: boolean;
  id?: string;
}
