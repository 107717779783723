import { concat, NEVER, merge, of } from "rxjs";

import { vec2, vec4 } from "gl-matrix";

import { state } from "../index";

import { QUAD_WIDTH } from "../models";
import Town from "../town";
import TownPlay from "../town-play";
import { GamePage, RainDrop, Directions } from "../interfaces";
import pathFinder from "../path-finder";
import { dimensionsMaintainingAspectWithMax, randomNumberBetween } from "../helpers/math-helpers";
import {
  callFunction$,
  cameraZoomTweenEvent$,
  delayEvent$,
  dialogueEvent$,
  dialogueEventAfter$,
  dialogueInteractionFn,
  dialogueInteractionEventFn,
  lockCameraPosition$,
  loopCharacterAlongPathXTimesEvent$,
  moveCharacterFromCurrentPosToPointEvent$,
  playSoundEvent$,
  saveToLocalStorageEvent$,
  sendAnalyticsEvent$,
  setPropertyEvent$,
  setEvent$,
    translateTweenEvent$,
  translateTweenFromCameraEvent$,
  unlockCameraPosition$,
  updateCameraPosition$,
  updatePositionEvent$,
  updateInteraction$,
  waitFor$,
  waitTimeEvent$,
  thatsItEvent$,
  updatePosition$
} from "../town-observables";

import ScaryPresto from "../sprites/scary-presto";
import Henchperson from "../sprites/henchperson";
import RoyalGetem from "../sprites/royal-getem";
import Director from "../sprites/director";
import LordEgg from "../sprites/lord-egg";
import KingPresident from "../sprites/king-president";
import Metaorb from "../sprites/metaorb";
import Torch from "../sprites/torch";
import { updateRain, resizeRain } from "../weather";
import { MAX_DIMENSION } from "../index";
import AudioPlayback from "../audio-playback";

const numRainDrops = 250;

const torchSpeed = 100;

import mineTownTileMap from "../../assets/maps/mine-town.json";

//const underwater1Url = require("../../assets/audio/music/underwater-1.wav").default;
const fanfareUrl = require("../../assets/audio/sfx/game-over.wav").default;
const rainUrl = require("../../assets/audio/sfx/rain.wav").default;

export default class Minetown extends Town implements GamePage {
  skyRgba: vec4;
  rainCanvas: HTMLCanvasElement;
  rainDropPos: Array<RainDrop>;
  opacity: number;

  name = "minetown";
  tileMap = mineTownTileMap;

  private torches: Torch[];
  private prevTorchTime: number = 0;

  constructor() {
    super();

    this.torches = [];

    // Rain
    this.menu.classList.add("town-menu-wrapper");
    this.rainCanvas = document.createElement("canvas");
    this.rainCanvas.style.height = "100%";
    this.rainCanvas.style.width = "100%";
    this.rainCanvas.style.position = "fixed";
    this.menu.append(this.rainCanvas);

    this.skyRgba = [0.1, 0.1, 0.1, 1];
    this.opacity = 1;
    this.rainDropPos = new Array(numRainDrops).fill({
      x: -1,
      y: -1,
      length: 10,
      deltaY: 0,
      sinAngle: 0,
      cosAngle: 0,
      fallSpeed: 0
    });

    this.lightDropoffs = new Array(12).fill(0.4);
    this.dayLevel = 0.5;
  }

  async load(params: URLSearchParams) {
    await super.load(params);
    this.params = params;
    let url = window.location.pathname;
    if (params.has("debug")) url += "?debug";
    if (params.has("playbackSpeed")) {
      const speed = params.get("playbackSpeed");
      url += `&playbackSpeed=${speed}`;
    }
    window.history.replaceState({}, document.title, url); // remove params except for debugs
    this.resize();
    const audioPlayback = AudioPlayback.getInstance();
    audioPlayback.add("fanfare", fanfareUrl);
    audioPlayback.add("rain", rainUrl, { loop: true });

    // == Events / Game Save

    let ec = state.happenedEvents["minetown"];

    if (this.params.has("new-game")) {
      console.log("In minetown, attempting to create a new game...");
      state.happenedEvents["minetown"] = {};

      if (window.plausible) window.plausible("new-game");
      this.gameSaver.newGame();
    } else if (!this.params.has("pop")) {
      console.log("In minetown, attempting to load a game...");
      const success = this.gameSaver.loadFirstSavedGame();
      if (window.plausible) window.plausible("load-game-in-minetown");
      if (!success) state.happenedEvents["minetown"] = {};
    } else {
      console.log("In minetown, loading from memory...");
    }

    ec = state.happenedEvents["minetown"];

    const { gameGl } = this;

    this.townPlay = new TownPlay();

    // ====== Town Play stuff ================================================

    const { overlay, townTime$, gameSaver, townPlay } = this;
    let { t } = this;
    t = t.bind(this);

    const now = () => state.currentTime;

    // == ROYAL GETEMs

    const offscreenX = 26;
    const offscreenY = 1;
    const getemStartX = 24.9;
    const getemEndX = 21.5;
    const getemStartHighY = -12.0;
    const getemStartLowY = -14.0;

    const getem1 = new RoyalGetem(gameGl);
    const getem2 = new RoyalGetem(gameGl);
    const getem3 = new RoyalGetem(gameGl);
    const getem4 = new RoyalGetem(gameGl);
    const getem5 = new RoyalGetem(gameGl);
    const getem6 = new RoyalGetem(gameGl);

    getem1.updatePos(offscreenX, getemStartHighY);
    getem2.updatePos(offscreenX, getemStartHighY);
    getem3.updatePos(offscreenX, getemStartHighY);
    getem4.updatePos(offscreenX, getemStartLowY);
    getem5.updatePos(offscreenX, getemStartLowY);
    getem6.updatePos(offscreenX, getemStartLowY);

    const getems = [getem1, getem2, getem3, getem4, getem5, getem6];

    const getEmLoops$ = getems.map((getem, i) => {
      const y = i > 2 ? getemStartLowY : getemStartHighY;
      let directionFn = () => getem.setDirection(Directions.DownIdle);
      let done: any = of(true);
      if (i > 2) directionFn = () => getem.setDirection(Directions.UpIdle);
      if (i === 5) done = setEvent$(ec, "getems-done", true);

      const path = pathFinder(
        [getemStartX, y],
        [getemEndX - (i % 3), y],
        this.tileLayer,
        this.tileMap
      );

      return concat(
        waitFor$(ec, "oh-no", true, this.townTime$),
        delayEvent$(ec, `get-em-${i}-delay`, 750 * i),
        updatePositionEvent$(ec, `get-em-start-${i}`, getem, getemStartX, y),
        loopCharacterAlongPathXTimesEvent$(ec, `royal-getem-walk-${i}`, getem, path, townTime$, 1),
        callFunction$(directionFn),
        done
      );
    });

    const getemsLoop$ = concat(
      waitFor$(ec, "oh-no", true, townTime$),
      dialogueEvent$(ec, t("royal-getems", "announcing"), overlay, null, "king-announced"),
      playSoundEvent$(ec, "play-fanfare", "fanfare")
    );

    // == KING PRESIDENT

    const kingStartY = -13.0;
    const kingStartX = 24.9;
    const kingPresident = new KingPresident(gameGl);
    const kingEndX = 21.5;
    const kingEndY = -13.0;
    const kingDirectionFn = () => kingPresident.setDirection(Directions.LeftIdle);

    kingPresident.updatePos(offscreenX, kingStartY);

    const kingPath = pathFinder(
      [kingStartX, kingStartY],
      [kingEndX, kingEndY],
      this.tileLayer,
      this.tileMap
    );

    const kingPresidentLoop$ = concat(
      waitFor$(ec, "scary-walks-to-meet-prez", true, this.townTime$),
      updatePositionEvent$(ec, `king-pops-on-screen`, kingPresident, kingStartX, kingStartY),
      loopCharacterAlongPathXTimesEvent$(
        ec,
        `king-walks-to-scary`,
        kingPresident,
        kingPath,
        townTime$,
        1
      ),
      callFunction$(kingDirectionFn)
    );

    // == LORD EGG

    const eggStartY = -13.0;
    const eggStartX = 24.9;
    const lordEgg = new LordEgg(gameGl);
    const eggEndX = 22.5;
    const eggEndY = -13.0;
    const eggDirectionFn = () => lordEgg.setDirection(Directions.LeftIdle);

    lordEgg.updatePos(offscreenX, eggStartY);

    const lordEggLoop$ = concat(
      waitFor$(ec, "scary-walks-to-meet-prez", true, this.townTime$),
      delayEvent$(ec, "lord-egg-follows-prez", 1000),
      updatePositionEvent$(ec, `lord-egg-pops-on-screen`, lordEgg, eggStartX, eggStartY),
      moveCharacterFromCurrentPosToPointEvent$(
        ec,
        "lord-egg-walks-to-scary",
        lordEgg,
        eggEndX,
        eggEndY,
        this.tileLayer,
        this.tileMap,
        townTime$
      ),
      callFunction$(eggDirectionFn)
    );

    // == HENCHPEOPLE

    const henchpeople: any[] = [];
    let luckyHenchperson = null;

    for (let i = 0; i < 12; i++) {
      if (i === 7) continue; // there's no mine in the 7th spot
      const henchperson1 = new Henchperson(gameGl);
      const henchperson2 = new Henchperson(gameGl);
      const henchperson3 = new Henchperson(gameGl);
      const henchperson4 = new Henchperson(gameGl);
      const henchperson5 = new Henchperson(gameGl);
      const henchperson6 = new Henchperson(gameGl);

      if (i === 0) luckyHenchperson = henchperson6;

      const im = i % 4;
      const leftX = im * 6 + 1.75;
      const rightX = im * 6 + 5.25;
      const y = -Math.floor(i / 4) * 8 - 3.5;
      henchperson1.updatePos(leftX, y - 0);
      henchperson2.updatePos(leftX, y - 1);
      henchperson3.updatePos(leftX, y - 2);
      henchperson1.setDirection(Directions.Right);
      henchperson2.setDirection(Directions.Right);
      henchperson3.setDirection(Directions.Right);

      henchperson4.updatePos(rightX, y - 0);
      henchperson5.updatePos(rightX, y - 1);
      henchperson6.updatePos(rightX, y - 2);

      henchperson4.setDirection(Directions.Left);
      henchperson5.setDirection(Directions.Left);
      henchperson6.setDirection(Directions.Left);

      henchpeople.push(
        henchperson1,
        henchperson2,
        henchperson3,
        henchperson4,
        henchperson5,
        henchperson6
      );
    }

    // == LUCKY HENCHPERSON

    const luckyStart = vec2.create();
    const luckyEnd: vec2 = [19.5, -13.0];

    vec2.copy(luckyStart, luckyHenchperson.pos);
    const luckyPath = pathFinder(luckyStart, luckyEnd, this.tileLayer, this.tileMap);
    const luckyHenchpersonLoop$ = concat(
      waitFor$(ec, "scary-says-we-havent", true, townTime$),
      waitFor$(ec, "path-to-lucky-henchperson", true, townTime$),
      delayEvent$(ec, "lucky-after-scary-stalls", 1500),
      callFunction$(() => luckyHenchperson.changeAnimationSpeed(500)),
      dialogueEvent$(ec, t("henchperson-4", "hang-on"), overlay, null, "hang-on"),
      waitFor$(ec, "metaorb-falls", true, townTime$),
      callFunction$(() => luckyHenchperson.setDirection(Directions.Down)),
      dialogueEvent$(ec, t("henchperson-4", "holy-moly"), overlay, null, "holy-moly"),
      loopCharacterAlongPathXTimesEvent$(
        ec,
        "lucky-runs",
        luckyHenchperson,
        luckyPath,
        townTime$,
        1
      ),
      callFunction$(() => luckyHenchperson.setDirection(Directions.RightIdle))
    );

    // == METAORB

    const metaorb = new Metaorb(gameGl);
    metaorb.updatePos(offscreenX, offscreenY);

    const metaorbLoop$ = concat(
      waitFor$(ec, "hang-on", true, townTime$),
      updatePositionEvent$(ec, "metaorb-appears", metaorb, luckyStart[0], luckyStart[1]),
      translateTweenEvent$(
        ec,
        "metaorb-falls",
        metaorb,
        luckyStart[0],
        luckyStart[1],
        luckyStart[0],
        luckyStart[1] - QUAD_WIDTH / 3,
        now,
        townTime$,
        250
      ),
      waitFor$(ec, "holy-moly", true, townTime$),
      updatePositionEvent$(ec, "metaorb-disappears", metaorb, offscreenX, offscreenY)
    );

    // == TORCHES

    const torches: Torch[] = [];
    // prettier-ignore
    const torchPositions = [
      [+3.5,-6.5] ,[+9.5,-6.5] ,[+15.5,-6.5] ,[+21.5,-6.5],
      [+3.5,-14.5],[+9.5,-14.5],[+15.5,-14.5],
      [+3.5,-22.5],[+9.5,-22.5],[+15.5,-22.5],[+21.5,-22.5]
    ];

    torchPositions.forEach(position => {
      const torch = new Torch(gameGl);
      torch.updatePos(position[0], position[1]);
      torches.push(torch);
    });

    this.torches = torches;
    this.torches.forEach((torch, i) => {
      const pos: vec2 = torch.pos;
      const posOffset = i * 3;
      const colOffset = i * 4;
      this.lightPositions[posOffset + 0] = pos[0];
      this.lightPositions[posOffset + 1] = pos[1];
      this.lightPositions[posOffset + 2] = 0.5;

      this.lightColors[colOffset + 0] = 0.9;
      this.lightColors[colOffset + 1] = 0.2;
      this.lightColors[colOffset + 2] = 0.0;
      this.lightColors[colOffset + 3] = 1.0;
    });

    const startLocation: vec2 = [14.5, -16.5];
    const endLocation: vec2 = [10, -16.5];

    // == HENCHPEOPLE DIALOG

    const henchpeopleLoop$ = concat(
      waitFor$(ec, "scary-asks-everybody", true, townTime$),
      dialogueEvent$(ec, t("henchpeople", "ellipsis"), overlay),
      setEvent$(ec, "henchpeople-dont-respond", true),
      waitFor$(ec, "scary-asks-again", true, townTime$),
      dialogueEvent$(ec, t("henchpeople", "possibile"), overlay),
      setEvent$(ec, "henchpeople-respond-meekly", true)
    );

    // == DIRECTOR
    const director = new Director(gameGl);
    director.updatePos(13.4, -15.15);

    // == SCARY PRESTO
    const scaryPresto: ScaryPresto = new ScaryPresto(gameGl);
    const scaryMeetPrezPos = [20.5, -13.0];

    await Promise.all(
      [
        scaryPresto,
        ...henchpeople,
        ...torches,
        director,
        lordEgg,
        kingPresident,
        ...getems,
        metaorb
      ].map(c => c.load())
    );

    const path = pathFinder(startLocation, endLocation, this.tileLayer, this.tileMap);
    const path2 = pathFinder(endLocation, startLocation, this.tileLayer, this.tileMap);
    const combinedPath = [...path, ...path2];

    const walkAndTalk$ = merge(
      dialogueEvent$(ec, t("scary", "not-good"), overlay),
      loopCharacterAlongPathXTimesEvent$(ec, "scary-paces", scaryPresto, combinedPath, townTime$, 1)
    );

    const scaryLoop$ = concat(
      updatePositionEvent$(ec, "scary-start", scaryPresto, startLocation[0], startLocation[1]),
      setPropertyEvent$(ec, "set-scary-as-player", state, "currentPlayer", scaryPresto),
      callFunction$(() => this.spriteRenderer.sort()),
      setPropertyEvent$(ec, "opening-cinematic-01", state, "inCinematic", true),
      dialogueEvent$(ec, t("scary", "today"), overlay),
      dialogueEvent$(ec, t("scary", "today-2"), overlay),
      setEvent$(ec, "scary-worries", true),
      walkAndTalk$,
      dialogueEvent$(ec, t("scary", "find-something"), overlay),
      dialogueEvent$(ec, t("scary", "still-time"), overlay),
      dialogueEvent$(ec, t("scary", "right-everybody"), overlay),
      setEvent$(ec, "scary-asks-everybody", true),
      waitFor$(ec, "henchpeople-dont-respond", true, townTime$),
      dialogueEvent$(ec, t("scary", "come-on"), overlay),
      setEvent$(ec, "scary-asks-again", true),
      waitFor$(ec, "henchpeople-respond-meekly", true, townTime$),
      dialogueEvent$(ec, t("scary", "ellipsis-1"), overlay),
      dialogueEvent$(ec, t("scary", "ellipsis-2"), overlay),
      dialogueEvent$(ec, t("scary", "ellipsis-3"), overlay),
      setEvent$(ec, "scary-realizes-he-is-doomed", true),
      dialogueEvent$(ec, t("scary", "screwed"), overlay),
      setEvent$(ec, "player-can-control-scary", true),
      dialogueEvent$(ec, t("scary", "dig-faster"), overlay),
      setEvent$(ec, "scary-asks-to-dig-faster", true),
      sendAnalyticsEvent$(ec, "end-of-first-cinematic"),
      setPropertyEvent$(ec, "opening-cinematic-02", state, "inCinematic", false),
      waitFor$(ec, "king-president-arrives", true, townTime$),
      dialogueEvent$(ec, t("scary", "oh-no"), overlay),
      setEvent$(ec, "oh-no", true),
      waitFor$(ec, "getems-done", true, townTime$),
      moveCharacterFromCurrentPosToPointEvent$(
        ec,
        "scary-walks-to-meet-prez",
        scaryPresto,
        scaryMeetPrezPos[0],
        scaryMeetPrezPos[1],
        this.tileLayer,
        this.tileMap,
        townTime$
      ),
      callFunction$(() => scaryPresto.setDirection(Directions.RightIdle))
    );

    // == SP-Lucky DIALOGUE
    const scaryLuckyConvoLoop$ = concat(
      waitFor$(ec, "stretch", true, townTime$),
      dialogueEvent$(ec, t("henchperson-4", "we-got-one"), overlay, null, "we-got-one"),
      callFunction$(() => scaryPresto.setDirection(Directions.LeftIdle)),
      dialogueEvent$(ec, t("scary", "not-now"), overlay, null, "not-now"),
      callFunction$(() => scaryPresto.setDirection(Directions.RightIdle)),
      dialogueEvent$(ec, t("henchperson-4", "but-we-got-one"), overlay, null, "but-we-got-one"),
      callFunction$(() => scaryPresto.setDirection(Directions.LeftIdle)),
      waitTimeEvent$(ec, "scary-looks-at-lucky-1", now, 1500, townTime$),
      callFunction$(() => scaryPresto.setDirection(Directions.RightIdle)),
      dialogueEvent$(ec, t("scary", "dotdotdot"), overlay, null, "dotdotdot"),
      callFunction$(() => scaryPresto.setDirection(Directions.LeftIdle)),
      waitTimeEvent$(ec, "scary-looks-at-lucky-2", now, 500, townTime$),
      callFunction$(() => scaryPresto.setDirection(Directions.RightIdle)),
      waitTimeEvent$(ec, "scary-looks-away-from-lucky", now, 500, townTime$),
      callFunction$(() => scaryPresto.setDirection(Directions.LeftIdle)),
      dialogueEvent$(ec, t("scary", "stunned"), overlay, null, "stunned"),
      dialogueEvent$(ec, t("henchperson-4", "check-it-out"), overlay, null, "check-it-out"),
      moveCharacterFromCurrentPosToPointEvent$(
        ec,
        "lucky-moves-back",
        luckyHenchperson,
        luckyEnd[0] - 0.5,
        luckyEnd[1],
        this.tileLayer,
        this.tileMap,
        townTime$
      ),
      callFunction$(() => luckyHenchperson.setDirection(Directions.RightIdle)),
      updatePosition$(metaorb, luckyEnd[0], luckyEnd[1] - 0.25),
      dialogueEvent$(ec, t("scary", "holy-moly-thats"), overlay, null, "holy-moly-thats"),
      moveCharacterFromCurrentPosToPointEvent$(
        ec,
        "sp-picks-up-orb-1",
        scaryPresto,
        luckyEnd[0],
        luckyEnd[1],
        this.tileLayer,
        this.tileMap,
        townTime$
      ),
      updatePositionEvent$(ec, "scary-picks-up-orb", metaorb, offscreenX, offscreenY),
      moveCharacterFromCurrentPosToPointEvent$(
        ec,
        "sp-picks-up-orb-2",
        scaryPresto,
        scaryMeetPrezPos[0] - 0.25,
        scaryMeetPrezPos[1],
        this.tileLayer,
        this.tileMap,
        townTime$
      )
    );

    // == SP-KP DIALOGUE
    const scaryKingConvoLoop$ = concat(
      waitFor$(ec, "king-announced", true, townTime$),
      dialogueEventAfter$(
        ec,
        t("king-president", "intro"),
        overlay,
        "king-walks-to-scary",
        townTime$
      ),
      dialogueEvent$(ec, t("scary", "confused"), overlay, null, "confused"),
      dialogueEvent$(ec, t("king-president", "i-say-lol"), overlay, null, "i-say-lol"),
      dialogueEvent$(ec, t("scary", "side-effects"), overlay),
      dialogueEvent$(ec, t("king-president", "uninterested"), overlay),
      dialogueEvent$(ec, t("scary", "scared"), overlay),
      dialogueEvent$(ec, t("king-president", "do-you-have-orbs"), overlay),
      dialogueEvent$(ec, t("scary", "so-close"), overlay),
      dialogueEvent$(ec, t("king-president", "now"), overlay),
      dialogueEvent$(ec, t("scary", "thing-is-1"), overlay),
      dialogueEvent$(ec, t("scary", "thing-is-2"), overlay, null, "thing-is-2"),
      dialogueEvent$(ec, t("scary", "thing-is-3"), overlay, null, "thing-is-3"),
      dialogueEvent$(ec, t("king-president", "stalling-1"), overlay, null, "stalling-1"),
      dialogueEvent$(ec, t("scary", "thing-is-4"), overlay),
      dialogueEvent$(ec, t("scary", "thing-is-5"), overlay),
      dialogueEvent$(ec, t("king-president", "stalling-2"), overlay, null, "stalling-2"),
      dialogueEvent$(ec, t("scary", "we-havent"), overlay, null, "scary-says-we-havent"),
      waitFor$(ec, "lucky-runs", true, townTime$),
      dialogueEvent$(ec, t("scary", "in-fact"), overlay, null, "scary-in-fact"),
      dialogueEvent$(ec, t("king-president", "stretch"), overlay, null, "stretch"),
      waitFor$(ec, "sp-picks-up-orb-2", true, townTime$),
      dialogueEvent$(ec, t("scary", "do-you-mean"), overlay, null, "do-you-mean"),
      updatePositionEvent$(
        ec,
        "scary-puts-down-orb",
        metaorb,
        scaryMeetPrezPos[0] + 0.25,
        scaryMeetPrezPos[1] - 0.25
      ),
      dialogueEvent$(ec, t("scary", "like-this"), overlay, null, "like-this"),
      dialogueEvent$(ec, t("king-president", "holy-moly-its"), overlay, null, "holy-moly-its"),
      dialogueEvent$(ec, t("scary", "had-it"), overlay, null, "had-it"),
      callFunction$(() => scaryPresto.setDirection(Directions.LeftIdle)),
      dialogueEvent$(ec, t("scary", "thanks-h4"), overlay, null, "thanks-h4"),
      callFunction$(() => scaryPresto.setDirection(Directions.RightIdle)),
      dialogueEvent$(ec, t("king-president", "well-well"), overlay, null, "well-well"),
      dialogueEvent$(ec, t("scary", "just-doing-my-job"), overlay, null, "just-doing-my-job"),
      moveCharacterFromCurrentPosToPointEvent$(
        ec,
        "kp-picks-up-orb-1",
        kingPresident,
        scaryMeetPrezPos[0] + 0.25,
        scaryMeetPrezPos[1],
        this.tileLayer,
        this.tileMap,
        townTime$
      ),
      updatePositionEvent$(ec, "scary-picks-up-orb", metaorb, offscreenX, offscreenY),
      moveCharacterFromCurrentPosToPointEvent$(
        ec,
        "kp-picks-up-orb-2",
        kingPresident,
        kingEndX,
        kingEndY,
        this.tileLayer,
        this.tileMap,
        townTime$
      ),
      callFunction$(() => kingPresident.setDirection(Directions.LeftIdle)),
      dialogueEvent$(
        ec,
        t("king-president", "ever-see-this-one"),
        overlay,
        null,
        "ever-see-this-one"
      ),
      dialogueEvent$(ec, t("scary", "cant-say"), overlay, null, "cant-say"),
      dialogueEvent$(ec, t("king-president", "move"), overlay, null, "move"),
      moveCharacterFromCurrentPosToPointEvent$(
        ec,
        "scary-moves-out-of-the-way",
        scaryPresto,
        scaryMeetPrezPos[0],
        scaryMeetPrezPos[1] + 2,
        this.tileLayer,
        this.tileMap,
        townTime$
      ),
      callFunction$(() => scaryPresto.setDirection(Directions.DownIdle)),
      dialogueEvent$(ec, t("henchperson-4", "umm"), overlay, null, "umm"),
      dialogueEvent$(ec, t("henchperson-4", "hey-scary"), overlay, null, "hey-scary"),
      dialogueEvent$(ec, t("scary", "youll-be-fine"), overlay, null, "youll-be-fine"),
      this.transitionToBattle$([kingPresident, luckyHenchperson], "h4-knocked-out")
    );

    // == GAMESAVER

    const gameSaverLoop$ = concat(
      waitFor$(ec, "scary-asks-to-dig-faster", true, this.townTime$),
      saveToLocalStorageEvent$(ec, "save-dig-faster", gameSaver),
      waitFor$(ec, "king-walks-to-scary", true, this.townTime$),
      saveToLocalStorageEvent$(ec, "save-king-walks-to-scary", gameSaver),
      waitFor$(ec, "hang-on", true, this.townTime$),
      saveToLocalStorageEvent$(ec, "save-hang-on", gameSaver),
      waitFor$(ec, "umm", true, this.townTime$),
      saveToLocalStorageEvent$(ec, "save-umm", gameSaver),
      waitFor$(ec, "h4-knocked-out", true, townTime$),
      saveToLocalStorageEvent$(ec, "save-h4-knocked-out", gameSaver)
    );

    // == CAMERA

    const camera = state.viz.camera;
    const cameraLoop$ = concat(
      setPropertyEvent$(
        ec,
        "minetown-unlock-cmaera-to-bounds",
        this,
        "lockCameraToMapBounds",
        false
      ),
      updateCameraPosition$(camera, -10000, -10000, 2),
      waitFor$(ec, "scary-worries", true, this.townTime$),
      setPropertyEvent$(ec, "minetown-lock-cmaera-to-bounds", this, "lockCameraToMapBounds", true),
      updateCameraPosition$(camera, 0, 0, 4),
      lockCameraPosition$(camera, scaryPresto),
      waitFor$(ec, "scary-asks-everybody", true, this.townTime$),
      cameraZoomTweenEvent$(
        ec,
        "zoom-out-on-henchmen",
        camera,
        () => camera.vector[2],
        15,
        now,
        townTime$,
        1500
      ),
      waitFor$(ec, "henchpeople-respond-meekly", true, this.townTime$),
      cameraZoomTweenEvent$(
        ec,
        "zoom-back-in-on-scary",
        camera,
        () => camera.vector[2],
        1.2,
        now,
        townTime$,
        1500
      ),
      waitFor$(ec, "scary-realizes-he-is-doomed", true, this.townTime$),
      updateCameraPosition$(camera, camera.vector[0], camera.vector[1], 0.8),
      waitFor$(ec, "player-can-control-scary", true, this.townTime$),
      updateCameraPosition$(camera, camera.vector[0], camera.vector[1], 10),
      waitFor$(ec, "king-announced", true, this.townTime$),
      setPropertyEvent$(ec, "opening-cinematic-03", state, "inCinematic", true),
      unlockCameraPosition$(camera),
      merge(
        cameraZoomTweenEvent$(
          ec,
          "zoom-out-to-getems",
          camera,
          () => camera.vector[2],
          18,
          now,
          townTime$,
          3000
        ),
        translateTweenFromCameraEvent$(
          ec,
          "path-to-getems",
          camera,
          20.5,
          -12.5,
          now,
          townTime$,
          1500
        )
      ),
      waitFor$(ec, "confused", true, this.townTime$),
      updateCameraPosition$(camera, scaryMeetPrezPos[0], scaryMeetPrezPos[1], 5),
      waitFor$(ec, "thing-is-2", true, townTime$),
      merge(
        concat(
          delayEvent$(ec, "delay-lucky-zoom", 4000),
          cameraZoomTweenEvent$(
            ec,
            "zoom-to-lucky-henchperson",
            camera,
            () => camera.vector[2],
            3,
            now,
            townTime$,
            4000
          )
        ),
        translateTweenFromCameraEvent$(
          ec,
          "path-to-lucky-henchperson",
          camera,
          luckyStart[0],
          luckyStart[1] + QUAD_WIDTH / 2,
          now,
          townTime$,
          8000
        )
      ),
      lockCameraPosition$(camera, luckyHenchperson),
      waitFor$(ec, "lucky-runs", true, townTime$),
      unlockCameraPosition$(camera),
      merge(
        cameraZoomTweenEvent$(
          ec,
          "zoom-from-lucky-to-group",
          camera,
          () => camera.vector[2],
          12,
          now,
          townTime$,
          3000
        ),
        translateTweenFromCameraEvent$(
          ec,
          "path-back-to-scary-after-lucky-runs",
          camera,
          () => scaryPresto.pos[0],
          () => scaryPresto.pos[1] + QUAD_WIDTH / 2,
          now,
          townTime$,
          3000
        )
      ),
      waitFor$(ec, "h4-knocked-out", true, townTime$),
      updateInteraction$(
        townPlay,
        "director-fran",
        dialogueInteractionFn(t("director-fran", "gods"), overlay)
      )
    );

    // == AFTER BATTLE HUDDLE

    const afterBattleHuddleLoop$ = concat(
      waitFor$(ec, "h4-knocked-out", true, townTime$),
      sendAnalyticsEvent$(ec, "returned-from-h4-battle"),
      saveToLocalStorageEvent$(ec, "save-h4-knocked-out", gameSaver),
      callFunction$(() => (luckyHenchperson.rotation = Math.PI / 2)),
      dialogueEvent$(ec, t("henchperson-4", "ugh"), overlay, null, "ugh"),
      dialogueEvent$(ec, t("scary", "you-all-right"), overlay, null, "you-all-right"),
      dialogueEvent$(ec, t("scary", "dot-dot-dot-h4"), overlay, null, "dot-dot-dot-h4"),
      dialogueEvent$(ec, t("scary", "fran-h4"), overlay, null, "fran-h4"),
      moveCharacterFromCurrentPosToPointEvent$(
        ec,
        "fran-checks-on-h4",
        director,
        luckyEnd[0] - 1,
        luckyEnd[1],
        this.tileLayer,
        this.tileMap,
        townTime$
      ),
      callFunction$(() => director.setDirection(Directions.RightIdle)),
      dialogueEvent$(ec, t("director-fran", "how-ya-doin"), overlay, null, "how-ya-doin"),
      dialogueEvent$(ec, t("henchperson-4", "barf"), overlay, null, "barf"),
      lockCameraPosition$(camera, scaryPresto),
      //thatsItEvent$(ec, overlay),
      dialogueEvent$(ec, t("king-president", "come-here-presto"), overlay, null, "come-here-presto"),
      moveCharacterFromCurrentPosToPointEvent$(
        ec,
        "scary-comes-back",
        scaryPresto,
        scaryMeetPrezPos[0],
        scaryMeetPrezPos[1],
        this.tileLayer,
        this.tileMap,
        townTime$
      ),
      callFunction$(() => scaryPresto.setDirection(Directions.RightIdle)),
      dialogueEvent$(ec, t("king-president", "take-it-to-the-castle"), overlay, null, "take-it-to-the-castle"),
      updatePositionEvent$(
        ec,
        "king-prez-gives-back-metaorb",
        metaorb,
        scaryMeetPrezPos[0] + 0.25,
        scaryMeetPrezPos[1] - 0.25
      ),
      dialogueEvent$(ec, t("scary", "one-question"), overlay, null, "one-question"),
      updatePositionEvent$(ec, "scary-picks-up-orb", metaorb, offscreenX, offscreenY),
      dialogueEvent$(ec, t("king-president", "what"), overlay, null, "what"),
      translateTweenFromCameraEvent$(
        ec,
        "translate-to-king-prez-for-lol",
        camera,
        kingEndX,
        kingEndY,
        now,
        townTime$,
        1000
      ),
      lockCameraPosition$(camera, kingPresident),
      dialogueEvent$(ec, t("scary", "why-do-you-want-metaorbs"), overlay, null, "why-do-you-want-metaorbs"),
      dialogueEvent$(ec, t("king-president", "lol-lol-lol"), overlay, null, "lol-lol-lol"),
      cameraZoomTweenEvent$(
        ec,
        "zoom-on-laughing-king",
        camera,
        () => camera.vector[2],
          3,
        now,
        townTime$,
        4000
      ),
      merge(
        cameraZoomTweenEvent$(
          ec,
          "zoom-on-laughing-king",
          camera,
          () => camera.vector[2],
            1.5,
          now,
          townTime$,
          3000
        ),
        dialogueEvent$(ec, t("king-president", "the-question-is"), overlay, null, "the-question-is"),
      ),
      updateCameraPosition$(camera, camera.vector[0], camera.vector[1], 0.8),
      dialogueEvent$(ec, t("king-president", "why-does-matt-want"), overlay, null, "why-does-matt-want"),
      updateCameraPosition$(camera, camera.vector[0], camera.vector[1], 0.5),
      dialogueEvent$(ec, t("king-president", "lol-lol-lol-2"), overlay, null, "lol-lol-lol"),
      updateCameraPosition$(camera, camera.vector[0], camera.vector[1], 5.0),
      lockCameraPosition$(camera, scaryPresto),
      dialogueEvent$(ec, t("king-president", "get-back-to-work"), overlay, null, "lol-lol-lol"),
      sendAnalyticsEvent$(ec, "minetown-complete"),
      thatsItEvent$(ec, overlay),
      setPropertyEvent$(ec, "opening-cinematic-04", state, "inCinematic", false)
    );

    // +++ Add to townplay

    const generateHenchpersonLoop$ = (henchperson: Henchperson) => {
      return concat(
        waitFor$(ec, "scary-asks-to-dig-faster", true, this.townTime$),
        callFunction$(() => henchperson.changeAnimationSpeed(200)),
        waitFor$(ec, "king-announced", true, this.townTime$),
        callFunction$(() => henchperson.changeAnimationSpeed(100))
      );
    };

    torches.forEach((torch, i) => this.townPlay.addCharacter(`torch-${i}`, torch, NEVER));
    henchpeople.forEach((henchperson, i) =>
      this.townPlay.addCharacter(
        `henchperson-${i}`,
        henchperson,
        generateHenchpersonLoop$(henchperson),
        dialogueInteractionFn(t(`henchperson-${i}`, "hello"), overlay, `henchperson-${i}`)
      )
    );

    this.townPlay.addCharacter(
      "director-fran",
      director,
      NEVER,
      dialogueInteractionEventFn(
        ec,
        "king-president-arrives",
        t("director-fran", "they-are-coming"),
        overlay
      )
    );
    this.townPlay.addCharacter("scary-presto", scaryPresto, scaryLoop$);
    this.townPlay.addCharacter("henchpeople", null, henchpeopleLoop$);
    this.townPlay.addCharacter("royal-getems", null, getemsLoop$);
    this.townPlay.addCharacter("king-president", kingPresident, kingPresidentLoop$);
    this.townPlay.addCharacter("lord-egg", lordEgg, lordEggLoop$);
    this.townPlay.addCharacter("lucky-scary-convo", null, scaryLuckyConvoLoop$);
    this.townPlay.addCharacter("king-scary-convo", null, scaryKingConvoLoop$);
    this.townPlay.addCharacter("lucky-henchperson", null, luckyHenchpersonLoop$);
    this.townPlay.addCharacter("game-saver", null, gameSaverLoop$);
    this.townPlay.addCharacter("metaorb", metaorb, metaorbLoop$);
    this.townPlay.addCharacter("after-battle-huddle", null, afterBattleHuddleLoop$);

    getems.forEach((getem, i) => {
      this.townPlay.addCharacter(`getem-${i}`, getem, getEmLoops$[i]);
    });
    this.townPlay.addCharacter("camera", state.viz.camera, cameraLoop$);

    // +++ Add to sprite renderer

    this.spriteRenderer.add(director);
    henchpeople.forEach(henchperson => this.spriteRenderer.add(henchperson));
    torches.forEach(torch => this.spriteRenderer.add(torch));
    this.spriteRenderer.add(scaryPresto);
    this.spriteRenderer.add(kingPresident);
    this.spriteRenderer.add(lordEgg);
    this.spriteRenderer.add(metaorb);
    this.spriteRenderer.sort();
    getems.forEach(getem => this.spriteRenderer.add(getem));

    this.spriteRenderer.lightPositions = new Float32Array(this.lightPositions);
    this.spriteRenderer.lightColors = new Float32Array(this.lightColors);
    this.spriteRenderer.lightDropoffs = new Float32Array(this.lightDropoffs);
    this.spriteRenderer.dayLevel = this.dayLevel;

    this.loaded = true;
  }

  update(time: number) {
    super.update(time);

    const diff = time - this.prevTorchTime;

    if (diff > torchSpeed) {
      this.torches.forEach((_, i) => (this.lightDropoffs[i] = randomNumberBetween(0.2, 0.8)));
      this.prevTorchTime = time;
    }

    updateRain(this);
  }

  start() {
    this.backgroundAudio = [AudioPlayback.soundBag["rain"]];
    super.start();
  }

  resize() {
    super.resize();
    const dimensions = dimensionsMaintainingAspectWithMax(
      MAX_DIMENSION,
      state.aspect,
      window.innerWidth,
      window.innerHeight
    );
    const width = dimensions[0];
    const height = dimensions[1];
    resizeRain(this, width, height);
  }
}
