import { init } from "@sentry/browser";

import "./index.css";

import { mat4 } from "gl-matrix";
import { fromEvent } from "rxjs";
import { filter, map } from "rxjs/operators";

import Router from "./router";
import { GameState } from "./interfaces";
import { deg2rad, dimensionsMaintainingAspectWithMax } from "./helpers/math-helpers";
import { enableVertexAttribArray0 } from "./helpers/webgl-helpers";
import Camera from "./camera";

export const MAX_DIMENSION = 640;
const MAX_TIME_BETWEEN_FRAMES = 1000;
let lastTimestamp = 0;

const gameCanvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById("game");
const gameGl = gameCanvas.getContext("webgl", { alpha: false });

const projectionMatrix = mat4.create();
const modelMatrix = mat4.create();
const viewMatrix = mat4.create();
const viewProjectionMatrix = mat4.create();
const inverseViewProjectionMatrix = mat4.create();
let aspect = getAspectRatio(gameGl.canvas);

// Deal with having to hardcode Itch's CDN
const url = window.location.href.replace("index.html", "");
const ITCH_CDN = "hwcdn.net";
const itchCdnPath = !!url.match(ITCH_CDN) ? url : null;

const resize$ = fromEvent(window, "resize");
const domLoaded$ = fromEvent(document, "DOMContentLoaded");

const queryParams = new URLSearchParams(location.search);
let playbackSpeed = parseFloat(queryParams.get("playbackSpeed")) || 1.0;

export const state: GameState = {
  o$: { resize$ },
  paused: false,
  debug: queryParams.has("debug"),
  canvasDimensions: [-1, -1],
  numTiles: -1,
  happenedEvents: {},
  aspect,
  router: null,
  currentPlayer: null,
  get moveable() {
    return !this.inCinematic && !document.querySelector(".js-dialogue");
  },
  inCinematic: false,
  itchCdnPath,
  playerPath: [],
  get playbackSpeed() {
    return playbackSpeed;
  },
  set playbackSpeed(value) {
    if (!isNaN(value)) playbackSpeed = value;
  },
  input: {
    last: null
  },
  currentTime: 0,
  battle: {
    state: "won",
    heroes: [],
    enemies: [],
    callback: () => {}
  },
  programWrappers: {},
  viz: {
    camera: new Camera(),
    zNear: 0.1,
    zFar: 100.0,
    fieldOfView: deg2rad(45),
    up: [0, 1, 0],
    inverseViewProjectionMatrix,
    modelMatrix,
    projectionMatrix,
    viewMatrix,
    viewProjectionMatrix
  }
};

// Configure router
const router = new Router();
state.router = router;
router.startRouter();

const otherKeys = ["p"];
const keyDown$ = fromEvent(document, "keydown");
resize();
resize$.subscribe(resize);

domLoaded$.subscribe(resize);

enableVertexAttribArray0(gameGl);

function resize() {
  aspect = getAspectRatio(gameCanvas);
  state.aspect = aspect;
  const dimensions = dimensionsMaintainingAspectWithMax(
    MAX_DIMENSION,
    aspect,
    window.innerWidth,
    window.innerHeight
  );

  const width = dimensions[0];
  const height = dimensions[1];

  state.canvasDimensions[0] = width;
  state.canvasDimensions[1] = height;
  gameCanvas.width = width;
  gameCanvas.height = height;
  gameGl.viewport(0, 0, width, height);
}

function isNumber(thing: any) {
  return typeof thing === "number";
}

function tick(timestamp: number) {
  let diff: number = timestamp - lastTimestamp;
  if (diff > MAX_TIME_BETWEEN_FRAMES) diff = (1 / 60) * 1000;
  if (diff < 0 || isNaN(diff) || !isNumber(diff)) {
    requestAnimationFrame(tick);
    return;
  }

  lastTimestamp = timestamp;
  if (!state.paused) state.currentTime += diff * state.playbackSpeed;
  const clock = state.currentTime;
  requestAnimationFrame(tick);

  if (router.loadingPage && router.loadingPage.loaded) {
    router.loadingPage.tick(clock);
  } else if (router.currentRoute && router.currentRoute.loaded) {
    router.currentRoute.tick(clock);
  }
}

const otherKeyDown$ = keyDown$.pipe(
  filter((e: KeyboardEvent) => otherKeys.includes(e.key)),
  map(e => e.key)
);

otherKeyDown$.subscribe(() => (state.paused = !state.paused));

function getAspectRatio(canvas) {
  return canvas.clientWidth / canvas.clientHeight;
}

requestAnimationFrame(tick);

if (process.env.NODE_ENV === "production") {
  init({
    dsn: "https://ad5f41165a6245d5b4ccad7ef5c99faa@o66203.ingest.sentry.io/5365385"
  });
}
