import { fromEvent, Subscription } from "rxjs";
import { Howler } from "howler";

import html, { styles } from "./settings-menu-html";
import { GamePage } from "../interfaces";
import TitleMenu from "./title-menu";
import SettingsSaver from "../settings-saver";

export default class SettingsMenu implements GamePage {
  public menu: HTMLElement;
  public loaded: boolean;
  public tornDown: boolean;
  public routerId: string;
  public name: string = "settings";

  private titleMenu: TitleMenu;
  private subscriptions: Subscription[] = [];
  private settings: SettingsSaver;

  constructor() {
    this.menu = document.createElement("div");
    this.menu.insertAdjacentHTML("beforeend", html);
    this.loaded = false;
    this.tornDown = false;
    this.titleMenu = new TitleMenu();
    this.routerId = "";
    this.settings = new SettingsSaver();

    const params = new URLSearchParams(location.search);
    const path = params.get("back-link");

    const link = this.menu.querySelector("#js-settings-back-link");
    link.setAttribute("href", path);
  }

  configurePrograms() {
    this.titleMenu.configurePrograms();
  }

  configureMenus() {
    const soundOn = this.menu.querySelector("#js-sound-on");
    const soundOff = this.menu.querySelector("#js-sound-off");

    if (this.settings.soundOn) {
      Howler.mute(false);
      soundOn.classList.remove(styles["hidden"]);
      soundOff.classList.add(styles["hidden"]);
    } else {
      Howler.mute(true);
      soundOn.classList.add(styles["hidden"]);
      soundOff.classList.remove(styles["hidden"]);
    }
  }

  tearDown() {
    console.log("Tearing down settings menu...");
    this.tornDown = true;
    this.titleMenu.tearDown();
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  tick(timestamp: number) {
    this.titleMenu.tick(timestamp);
  }

  async load(params) {
    await this.titleMenu.load(params);
    this.configureMenus();

    const soundToggle = this.menu.querySelectorAll(".js-sound-toggle");
    const soundToggle$ = fromEvent(soundToggle, "click").subscribe(() => {
      this.settings.soundOn = !this.settings.soundOn;
      this.configureMenus();
    });

    this.subscriptions.push(soundToggle$);

    this.loaded = true;
  }

  start() {}
}
