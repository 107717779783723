import s from "./title-menu.css";

export const styles = s;

export default `
<canvas id="js-rain" class="${s["rain"]}"></canvas>
<div class="${s["title-wrapper"]}">
  <div class="${s["fire-wrapper"]}">
  <div class="${s["super-early-access-wrapper"]}"><div class="${s["super-early-access"]}"><div>DEMO!</div></div></div>
    <canvas class="${s["fire"]}" id="js-shadow-text" width="640" height="100"></canvas>
    <canvas class="${s["fire"]}" id="js-fire-text" width="640" height="100"></canvas>
  </div>

  <div class="${s["title-menu-options"]}">
    <div class="${s["title-menu-option"]}" tabindex="0"><a href="/minetown?new-game">NEW GAME</a></div>
    <div class="${s["title-menu-option"]} ${s["title-menu-option--disabled"]}" tabindex="1" id="js-load-game">
      LOAD GAME
    </div>
    <div class="${s["title-menu-option"]}" tabindex="2"><a href="/encourage-matt">ENCOURAGE MATT</a></div>
    <div class="${s["title-menu-option"]}" tabindex="3"><a href="https://www.mtmckenna.com">QUIT</a></div>
  </div>
 <div class="${s["copyright"]}">&copy; 2020 Matt</div>
</div>
`;
