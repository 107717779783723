// prettier-ignore
export const QUAD_POSITIONS = [
  -0.5, +0.5, 0.0,
  -0.5, -0.5, 0.0,
  +0.5, +0.5, 0.0,
  -0.5, -0.5, 0.0,
  +0.5, -0.5, 0.0,
  +0.5, +0.5, 0.0,
];

// prettier-ignore
export const SCREEN_POSITIONS = [
  -1.0, +1.0, 0.0,
  -1.0, -1.0, 0.0,
  +1.0, +1.0, 0.0,
  -1.0, -1.0, 0.0,
  +1.0, -1.0, 0.0,
  +1.0, +1.0, 0.0,
];

// prettier-ignore
export const SCREEN_TEXTURE_COORDS = [
  0, 1,
  0, 0,
  1, 1,
  0, 0,
  1, 0,
  1, 1
];

// prettier-ignore
export const QUAD_NORMALS = [
  0.0, 0.0, 1.0,
  0.0, 0.0, 1.0,
  0.0, 0.0, 1.0,
  0.0, 0.0, 1.0,
  0.0, 0.0, 1.0,
  0.0, 0.0, 1.0,
];

// prettier-ignore
export const QUAD_TEXTURE_COORDS = [
  0, 0,
  0, 1,
  1, 0,
  0, 1,
  1, 1,
  1, 0
];

// prettier-ignore
export const FUDGED_QUAD_TEXTURE_COORDS = [
  0.00, 0.00,
  0.00, 0.95,
  0.95, 0.00,
  0.00, 0.95,
  0.95, 0.95,
  0.95, 0.00
];

// https://gamedev.stackexchange.com/questions/74420/seamless-tilemap-rendering-borderless-adjacent-images
const T_TX_MIN = 0.1;
const T_TX_MAX = 0.9;
// prettier-ignore
export const TILE_TEXTURE_COORDS = [
  T_TX_MIN, T_TX_MIN,
  T_TX_MIN, T_TX_MAX,
  T_TX_MAX, T_TX_MIN,
  T_TX_MIN, T_TX_MAX,
  T_TX_MAX, T_TX_MAX,
  T_TX_MAX, T_TX_MIN
];

export const QUAD_WIDTH = 1;

export const NUM_VERTS_PER_QUAD = QUAD_POSITIONS.length / 3;
