import s from "./settings-menu.css";
export const styles = s;

export default `
<div class="${s["settings-wrapper"]}">
  <div class="${s["title"]}"><h1>SETTINGS</h1></div>
    <div class="${s["settings-menu-item"]} js-sound-toggle" id="js-sound-on">&#10003; SOUND IS ON</div>
    <div class="${s["settings-menu-item"]} js-sound-toggle" id="js-sound-off">&times; SOUND IS OFF</div>
  <div class="${s["title-menu-options"]}">
    <div class="${s["title-menu-option"]}" tabindex="0">
      <a id="js-settings-back-link" href="">GO BACK</a>
    </div>
  </div>
</div>`;
