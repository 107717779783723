import s from "./thats-it.css";

export default `
<div class="${s["dialogue-box"]}">
  <div class="${s["text-wrapper"]}">
    <div class="${s["text"]}">
      Matt: And that's it so far! Feel free to walk around and talk to NPCs. Click the link below to
      encourage me to keep working on the game!
    </div>
    <div class="${s["link-wrapper-wrapper"]}">
      <div class="${s["title-menu-option"]} ${s["link-wrapper"]}" tabindex="0">
        <a class="js-encourage-link" href="/encourage-matt">ENCOURAGE MATT</a>
      </div>
    </div>
  </div>
</div>
`;
