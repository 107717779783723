import { BehaviorSubject } from "rxjs";

import { vec2, mat4 } from "gl-matrix";

import Sprite from "../sprite";
import { loadTexture } from "../helpers/webgl-helpers";

import spriteSheetImageUrl from "../../assets/sprites/chara4.png";
import spriteSheetNormalImageUrl from "../../assets/sprites/chara4-n.png";

import { Directions } from "../interfaces";

export default class LordEgg extends Sprite {
  tileIndex: number;
  tileSize: vec2;
  tileSetDimensions: vec2;
  texture: WebGLTexture | null;
  textureUrl: string;
  normalTexture: WebGLTexture | null;
  animations: { [key: string]: number[] };
  animationName$: BehaviorSubject<string>;
  modelMatrix: number[];
  name: string;
  animationSpeed = 550;

  constructor(gl: WebGLRenderingContext) {
    super(gl);
    this.modelMatrix = <number[]>mat4.create();
    this.tileSize = [26, 36];
    this.tileSetDimensions = [312, 288];
    this.tileIndex = 0;
    this.textureUrl = spriteSheetImageUrl;
    this.name = "lord-egg";
    this.animations = {
      left: [21, 22, 23],
      up: [45, 46, 47],
      down: [9, 10, 11],
      right: [33, 34, 35],
      downIdle: [10],
      upIdle: [46],
      leftIdle: [22],
      rightIdle: [34]
    };
    this.setScale(this.tileSize[0] / this.tileSize[1], 1);
    this.setDirection(Directions.DownIdle);
  }

  async load() {
    const { gl } = this;
    this.texture = await loadTexture(gl, spriteSheetImageUrl);
    this.normalTexture = await loadTexture(gl, spriteSheetNormalImageUrl);
    return true;
  }
}
