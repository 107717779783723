import { GamePage } from "../interfaces";
import TitleMenu from "./title-menu";

import html, { styles } from "./encourage-matt-menu-html";

export default class EncourageMattMenu implements GamePage {
  public menu: HTMLElement;
  public loaded: boolean;
  public tornDown: boolean;
  public routerId: string;
  public name: string = "encourage-matt";

  private titleMenu: TitleMenu;

  constructor() {
    this.menu = document.createElement("div");
    this.menu.classList.add(styles["encourage-wrapper"]);
    this.menu.insertAdjacentHTML("beforeend", html);
    this.loaded = false;
    this.tornDown = false;
    this.titleMenu = new TitleMenu();
    this.routerId = "";
  }

  configurePrograms() {
    this.titleMenu.configurePrograms();
  }

  tearDown() {
    console.log("Tearing down encourage menu...");
    this.tornDown = true;
    this.titleMenu.tearDown();
  }

  tick(timestamp: number) {
    this.titleMenu.tick(timestamp);
  }

  async load(params) {
    await this.titleMenu.load(params);
    this.loaded = true;
  }

  start() {}
}
