import { mat4, quat, vec2, vec3, vec4 } from "gl-matrix";
import { BehaviorSubject, concat, defer, fromEvent, merge, Subject, Observable, timer } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  pluck,
  takeWhile,
  takeUntil,
  tap,
  withLatestFrom
} from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";

import {
  ActionType,
  BattleCharacter,
  BattleAction,
  Directions,
  GamePage,
  RainDrop,
  Positionable
} from "./interfaces";
import SpriteRenderer from "./sprite-renderer";
import AnimationQueue from "./animation-queue";
import { dimensionsMaintainingAspectWithMax, gamePageBounds } from "./helpers/math-helpers";
import { unprojectPoint } from "./helpers/input-helpers";
import { hasClass } from "./helpers/style-helpers";
import { clamp, range } from "./helpers/math-helpers";
import { updateRain, resizeRain } from "./weather";
import { MAX_DIMENSION } from "./index";
import { characterTimer$, getFilterWithLatestFromOperator } from "./battle-observables";
import html, { styles as s } from "./templates/atb-html";
import KingPresident from "./sprites/king-president";
import Henchperson from "./sprites/henchperson";
import RocksBackground from "./sprites/rocks-background";
import titleScreenImageUrl from "../assets/textures/title-screen.png";
import Twarn from "./twarn";

import {
  fillItemMenu,
  fillMagicMenu,
  fillMetaOrbMenu,
  fillSinkMenu,
  setDisplayNone,
  setHide,
  setSelectable,
  setSelected,
  setReady,
  unsetSelectable,
  unsetReady,
  unsetHide,
  unsetDisplayNone,
  unsetSelected
} from "./atb/stylers";

import {
  compiledProgram,
  cacheAttributeLocations,
  cacheUniformLocations,
  generateTbn,
  loadTexture,
  setOffsetsAttribDirect,
  setNormalsAttribDirect,
  setPositionsAttribDirect,
  setBitangentsAttribDirect,
  setIndicesBufferDirect,
  setTangentsAttribDirect,
  setTextureCoordsAttribDirect,
  setOpacitiesAttribDirect,
  setDrawToScreen,
  resetContext,
  resizeFrameBuffer,
  setSelectedsAttribDirect,
  setCentersAttribDirect,
  setHalfPixelTextureCoords
} from "./helpers/webgl-helpers";

import {
  QUAD_POSITIONS,
  QUAD_TEXTURE_COORDS,
  NUM_VERTS_PER_QUAD,
  SCREEN_POSITIONS
} from "./models";

import { state } from "./index";

import TILE_VERT_SHADER from "./shaders/tile-vertex.glsl";
import FLOOR_FRAG_SHADER from "./shaders/floor-fragment.glsl";

import BACKGROUND_VERT_SHADER from "./shaders/background-vertex.glsl";
import BACKGROUND_FRAG_SHADER from "./shaders/background-fragment.glsl";

import ORB_VERT_SHADER from "./shaders/orb-vertex.glsl";
import ORB_FRAG_SHADER from "./shaders/orb-fragment.glsl";

import floorTextureUrl from "../assets/textures/rock-floor.png";
import floorNormalTextureUrl from "../assets/textures/rock-floor-n.png";

const FLOOR_UNIFORM_NAMES = [
  "uModelMatrix",
  "uViewMatrix",
  "uProjectionMatrix",
  "uSpriteSampler",
  "uNormalSampler",
  "uLightPos",
  "uBillboard"
];

const FLOOR_ATTRIBUTE_NAMES = [
  "aVertexPosition",
  "aVertexNormal",
  "aVertexTangent",
  "aVertexBitangent",
  "aTextureCoord",
  "aCenter",
  "aOpacity",
  "aSelected"
];

const BATTLE_BACKGROUND_ATTRIBUTE_NAMES = ["aVertexPosition"];
const BATTLE_BACKGROUND_UNIFORM_NAMES = ["uResolution", "uTime", "uEffect", "uOpacity"];

const ORB_ATTRIBUTE_NAMES = ["aVertexPosition", "aTextureCoord", "aOffset", "aVertexNormal"];
const ORB_UNIFORM_NAMES = [
  "uTime",
  "uExplodeTime",
  "uTextureSampler",
  "uOpacity",
  "uProjectionMatrix",
  "uViewMatrix",
  "uModelMatrix"
];

const screenPositions = new Float32Array(SCREEN_POSITIONS);

const numTiles = 500;
const numTilesPerRow = 35;
const MAX_CHARACTER_DISTANCE = 5;

const { resize$ } = state.o$;

const battleProgramName = "battleSprite";

const paddingList = [
  [320, 0.0],
  [768, 0.05],
  [1024, 0.1],
  [Infinity, 0.2]
];

export default class Battle implements GamePage {
  public tornDown: boolean;
  public name: string = "battle";
  public loaded: boolean;
  public routerId: string;
  public menu?: HTMLElement;

  public heroes: BattleCharacter[];
  public enemies: BattleCharacter[];
  public characters: BattleCharacter[];
  public battleActions: BattleAction[];

  // Rain
  skyRgba: vec4;
  rainCanvas: HTMLCanvasElement;
  rainDropPos: Array<RainDrop>;
  opacity: number;

  protected gameCanvas: HTMLCanvasElement;
  protected gameGl: WebGLRenderingContext;
  protected vertexTangents: Float32Array;
  protected vertexBitangents: Float32Array;
  protected dayLevel: number = 1.0;
  protected spriteRenderer: SpriteRenderer;
  protected vertexPositions: Float32Array;
  protected textureCoords: Float32Array;
  protected vertexNormals: Float32Array;
  protected vertexOpacities: Float32Array;
  protected vertexSelecteds: Float32Array;
  protected vertexCenters: Float32Array;
  protected fbs: WebGLFramebuffer[];
  protected layerTextures: WebGLTexture[];

  private floorTexture: WebGLTexture;
  private floorNormalTexture: WebGLTexture;
  public lightPositions: Float32Array;
  public lightColors: Float32Array;
  public lightDropoffs: Float32Array;
  private kingPresident: KingPresident;
  private orbTexture: WebGLTexture;
  private henchperson: Henchperson;
  private battleTime$: Subject<number>;
  private currentHero$: BehaviorSubject<BattleCharacter>;
  private trackedThing$: BehaviorSubject<vec3>;
  private action$: BehaviorSubject<BattleAction>;
  private sink$: BehaviorSubject<BattleCharacter>;
  private battleState$: BehaviorSubject<"won" | "lost" | "active">;
  private lookAtTwarn: Twarn;
  private lookAtPos: vec3;
  private center: Positionable;
  private animationQueue: AnimationQueue;
  private canvasDimensions: Float32Array;
  private backgroundEffect: number;
  private backgroundOpacity: number;
  private orbVertexPositions: Float32Array;
  private orbNormals: Float32Array;
  private orbIndexData: Uint16Array;
  private orbTextureCoords: Float32Array;
  private orbModelMatrix: mat4;
  private orbRotation: quat;
  private orbOpacity: number;
  private orbScale: vec3;
  private orbPosition: vec3;
  private orbOffsets: Float32Array;
  private orbExplodeTime: number;

  constructor(heroes: BattleCharacter[], enemies: BattleCharacter[]) {
    this.heroes = heroes;
    this.enemies = enemies;
    this.animationQueue = new AnimationQueue();
    this.gameCanvas = <HTMLCanvasElement>document.getElementById("game");
    this.gameGl = this.gameCanvas.getContext("webgl", { alpha: false });
    const { gameGl } = this;

    this.floorTexture = gameGl.createTexture();
    this.fbs = [gameGl.createFramebuffer(), gameGl.createFramebuffer()];
    this.layerTextures = [gameGl.createTexture(), gameGl.createTexture()];
    this.vertexPositions = new Float32Array(numTiles * QUAD_POSITIONS.length);
    this.textureCoords = new Float32Array(numTiles * QUAD_TEXTURE_COORDS.length);
    this.vertexOpacities = new Float32Array(numTiles * QUAD_POSITIONS.length);
    this.vertexSelecteds = new Float32Array(numTiles * NUM_VERTS_PER_QUAD).fill(0.0);
    this.vertexCenters = new Float32Array(numTiles * NUM_VERTS_PER_QUAD * 3).fill(0.0);
    this.lightPositions = new Float32Array(12 * 3).fill(-1);
    this.lightColors = new Float32Array(12 * 4).fill(-1);
    this.lightDropoffs = new Float32Array(12).fill(1);
    this.lightPositions.set([numTilesPerRow / 2, 2.5, 0.2]);
    this.lightColors.set([1.0, 1.0, 1.0, 1.0]);
    this.lightDropoffs.set([0.8]);
    this.canvasDimensions = new Float32Array(2);
    this.backgroundEffect = 0.0;
    this.backgroundOpacity = 0.0;

    const tempOrbVertexPositions = [];
    const tempOrbNormals = [];
    const tempOrbTextureCoords = [];
    const tempOrbIndexData = [];
    configOrbData(tempOrbIndexData, tempOrbVertexPositions, tempOrbTextureCoords, tempOrbNormals);
    this.orbIndexData = new Uint16Array(tempOrbIndexData);
    this.orbVertexPositions = new Float32Array(tempOrbVertexPositions);
    this.orbNormals = new Float32Array(tempOrbVertexPositions);
    this.orbTextureCoords = new Float32Array(tempOrbTextureCoords);
    this.orbModelMatrix = mat4.create();
    this.orbRotation = quat.create();
    this.orbOpacity = 0.0;
    this.orbScale = vec3.fromValues(1, 1, 1);
    this.orbPosition = vec3.create();
    this.orbExplodeTime = -1;
    this.orbOffsets = new Float32Array(
      range(tempOrbVertexPositions.length / 3).map(() => 5 * Math.random())
    );

    this.center = {
      pos3: [numTilesPerRow / 2, 1, -10.5],
      get pos(): vec2 {
        return [this.pos3[0], this.pos3[1]];
      },
      size: [0, 0],
      updatePos(x: number, y: number) {
        this.pos3[0] = x;
        this.pos3[1] = y;
      },
      interactable: false
    };

    this.lookAtPos = vec3.clone(this.center.pos3);
    this.lookAtTwarn = new Twarn(this.lookAtPos, this.lookAtPos, state.currentTime, {
      duration: 500
    }).onUpdate(updatedVector => {
      vec3.copy(this.lookAtPos, updatedVector);
    });

    const lightBag = {
      lightDropoffs: this.lightDropoffs,
      lightColors: this.lightColors,
      lightPositions: this.lightPositions,
      dayLevel: 0.8,
      checkOutOfBounds: false
    };

    this.spriteRenderer = new SpriteRenderer(this.gameGl, battleProgramName, lightBag, {
      billboard: false
    });

    this.currentHero$ = new BehaviorSubject(null).pipe(distinctUntilChanged()) as BehaviorSubject<
      BattleCharacter
    >;
    this.action$ = new BehaviorSubject(null);
    this.trackedThing$ = new BehaviorSubject(this.center.pos3);
    this.sink$ = new BehaviorSubject(null);
    this.battleTime$ = new Subject();
    this.battleState$ = new BehaviorSubject("active");

    this.configureGrid();
    this.configureRain();

    resize$.pipe(takeWhile(() => !this.tornDown)).subscribe(this.resize);

    const victory$ = this.battleTime$.pipe(
      map(() => this.enemies.every(c => c.hp <= 0)),
      distinctUntilChanged(),
      filter(won => won)
    );

    const defeat$ = this.battleTime$.pipe(
      map(() => this.heroes.every(c => c.hp <= 0)),
      distinctUntilChanged(),
      filter(lost => lost)
    );

    victory$.subscribe(() => this.battleState$.next("won"));
    defeat$.subscribe(() => this.battleState$.next("lost"));

    this.battleState$.subscribe(value => {
      if (value === "won") {
        state.battle.state = value;
        state.battle.callback();
        state.router.pop();
      } else {
        console.log("battle state changed to a non-winning value :(", value);
      }
    });
  }

  private configureRain() {
    // Rain
    this.rainCanvas = document.createElement("canvas");
    this.rainCanvas.style.height = "100%";
    this.rainCanvas.style.width = "100%";
    this.rainCanvas.style.position = "fixed";
    this.menu = document.createElement("div");
    this.menu.append(this.rainCanvas);
    this.menu.insertAdjacentHTML("beforeend", html);

    this.skyRgba = [0.1, 0.1, 0.1, 1];
    this.opacity = 1;
    this.rainDropPos = new Array(250).fill({
      x: -1,
      y: -1,
      length: 10,
      deltaY: 0,
      sinAngle: 0,
      cosAngle: 0,
      fallSpeed: 0
    });
  }
  private configureGrid() {
    // Build up our grid
    for (let i = 0; i < numTiles; i++) {
      const quad = [...QUAD_POSITIONS];

      for (let j = 0; j < quad.length; j = j + 3) {
        const y = Math.floor(i / numTilesPerRow);
        quad[j + 0] += i % numTilesPerRow; // x
        quad[j + 1] += y; // y
        quad[j + 2] += 0; // z (doesn't move)
      }

      this.textureCoords.set(QUAD_TEXTURE_COORDS, i * QUAD_TEXTURE_COORDS.length);
      this.vertexPositions.set(quad, i * QUAD_POSITIONS.length);
    }

    const quadLength = QUAD_POSITIONS.length;
    const rotation = -Math.PI / 2.5;

    for (let i = 0; i < numTiles; i++) {
      for (let j = 0; j < quadLength; j = j + 3) {
        const index = i * quadLength + j;
        const v = vec3.create();
        const x = this.vertexPositions[index + 0];
        const y = this.vertexPositions[index + 1];
        const z = this.vertexPositions[index + 2];
        vec3.set(v, x, y, z);
        vec3.rotateX(v, v, [numTiles / 2, 0, 0], rotation);

        this.vertexPositions[index + 0] = v[0];
        this.vertexPositions[index + 1] = v[1];
        this.vertexPositions[index + 2] = v[2];
      }
    }

    const tbn = generateTbn();
    const numVertices = this.vertexPositions.length / 3;
    this.vertexBitangents = new Float32Array(numVertices * tbn.bitangent.length);
    this.vertexTangents = new Float32Array(numVertices * tbn.tangent.length);
    this.vertexNormals = new Float32Array(numVertices * tbn.normal.length);
    this.vertexOpacities.fill(1);

    for (let i = 0; i < numVertices; i++) {
      this.vertexTangents.set(tbn.tangent, i * tbn.tangent.length);
      this.vertexBitangents.set(tbn.bitangent, i * tbn.bitangent.length);
      this.vertexNormals.set(tbn.normal, i * tbn.normal.length);
    }
  }

  get readyHeroes() {
    return this.heroes.filter(hero => hero.hp > 0 && hero.wait === 100);
  }

  async load(params: URLSearchParams) {
    const { gameGl } = this;
    this.floorTexture = await loadTexture(gameGl, floorTextureUrl);
    this.floorNormalTexture = await loadTexture(gameGl, floorNormalTextureUrl);
    this.loaded = true;

    const kingPresident = new KingPresident(gameGl);
    this.kingPresident = kingPresident;
    kingPresident.setScale(2, 2);
    kingPresident.updatePos3(numTilesPerRow / 2, 0.5, -10.5);
    kingPresident.setDirection(Directions.Left);
    await kingPresident.load();

    const henchperson = new Henchperson(gameGl);
    this.henchperson = henchperson;
    henchperson.setScale(2, 2);
    henchperson.updatePos3(
      numTilesPerRow / 2,
      this.kingPresident.pos3[1],
      this.kingPresident.pos3[2]
    );
    henchperson.setDirection(Directions.Right);
    await henchperson.load();

    const numBackgrounds = 12;
    const backgroundScale = 10;
    const backgroundOffset = (numBackgrounds * backgroundScale) / 2;

    for (let i = 0; i < numBackgrounds; i++) {
      const rocksBackground = new RocksBackground(gameGl);
      rocksBackground.setScale(backgroundScale, backgroundScale);
      rocksBackground.updatePos3(i * rocksBackground.size[0] - backgroundOffset, 9.5, -30);
      await rocksBackground.load();
      this.spriteRenderer.add(rocksBackground);
    }

    this.spriteRenderer.add(kingPresident);
    this.spriteRenderer.add(henchperson);
    this.spriteRenderer.sort();
    this.resize();

    this.heroes = [
      {
        id: uuidv4(),
        shortName: "K PREZ",
        longName: "KING PRESIDENT",
        sprite: this.kingPresident,
        attack: 1,
        hp: 9999,
        maxHp: 9999,
        mp: 9999,
        maxMp: 9999,
        wait: 40,
        magic: [
          {
            id: uuidv4(),
            name: "FIRE",
            damage: 10,
            mpDrain: 10,
            type: ActionType.Magic,
            effect: () => {}
          }
        ],
        items: [
          {
            id: uuidv4(),
            name: "POTION",
            damage: 0,
            effect: c => (c.hp = Math.min(c.maxHp, c.hp + 150)),
            type: ActionType.Item
          }
        ],
        metaorb: {
          id: uuidv4(),
          name: "TITLE SCREEN",
          damage: 100,
          type: ActionType.Metaorb,
          effect: () => {}
        },
        disabledMenus: [ActionType.Magic, ActionType.Item, ActionType.Attack],
        side: "heroes"
      }
    ];

    this.enemies = [
      {
        id: uuidv4(),
        shortName: "HENCH4",
        longName: "HENCHPERSON 4",
        sprite: this.henchperson,
        attack: 1,
        hp: 1,
        maxHp: 1,
        mp: 1,
        maxMp: 1,
        wait: 10,
        magic: [],
        items: [],
        side: "enemies"
      }
    ];

    this.orbTexture = await loadTexture(gameGl, titleScreenImageUrl);

    this.characters = [...this.heroes, ...this.enemies];
    this.battleActions = [this.heroes[0].metaorb, ...this.heroes[0].magic, ...this.heroes[0].items];
  }

  start() {
    this.populateHeroes();
  }

  configurePrograms() {
    const { gameGl } = this;

    // Floor
    const floorProgram = compiledProgram(gameGl, TILE_VERT_SHADER, FLOOR_FRAG_SHADER);
    state.programWrappers.floor = {
      program: floorProgram,
      cache: { program: floorProgram, attributes: {}, enabled: {}, uniforms: {} },
      buffers: {
        opacities: gameGl.createBuffer(),
        positions: gameGl.createBuffer(),
        normals: gameGl.createBuffer(),
        textureCoords: gameGl.createBuffer(),
        tangents: gameGl.createBuffer(),
        bitangents: gameGl.createBuffer(),
        selecteds: gameGl.createBuffer(),
        centers: gameGl.createBuffer()
      }
    };

    cacheAttributeLocations(gameGl, "floor", floorProgram, state, FLOOR_ATTRIBUTE_NAMES);
    cacheUniformLocations(gameGl, "floor", floorProgram, state, FLOOR_UNIFORM_NAMES);

    // Background
    const battleBackgroundProgram = compiledProgram(
      gameGl,
      BACKGROUND_VERT_SHADER,
      BACKGROUND_FRAG_SHADER
    );

    state.programWrappers.battleBackground = {
      program: battleBackgroundProgram,
      cache: { program: battleBackgroundProgram, attributes: {}, enabled: {}, uniforms: {} },
      buffers: { positions: gameGl.createBuffer() }
    };

    cacheAttributeLocations(
      gameGl,
      "battleBackground",
      battleBackgroundProgram,
      state,
      BATTLE_BACKGROUND_ATTRIBUTE_NAMES
    );

    cacheUniformLocations(
      gameGl,
      "battleBackground",
      battleBackgroundProgram,
      state,
      BATTLE_BACKGROUND_UNIFORM_NAMES
    );

    // Orbs
    const orbsProgram = compiledProgram(gameGl, ORB_VERT_SHADER, ORB_FRAG_SHADER);

    state.programWrappers.orbs = {
      program: orbsProgram,
      cache: { program: orbsProgram, attributes: {}, enabled: {}, uniforms: {} },
      buffers: {
        positions: gameGl.createBuffer(),
        textureCoords: gameGl.createBuffer(),
        index: gameGl.createBuffer(),
        offsets: gameGl.createBuffer(),
        normals: gameGl.createBuffer()
      }
    };

    cacheAttributeLocations(gameGl, "orbs", orbsProgram, state, ORB_ATTRIBUTE_NAMES);
    cacheUniformLocations(gameGl, "orbs", orbsProgram, state, ORB_UNIFORM_NAMES);
  }

  tearDown() {
    const { gameGl } = this;

    resetContext(gameGl);
    this.tornDown = true;
  }

  tick(time: number) {
    if (!this.loaded) return;

    if (!state.paused) {
      this.battleTime$.next(time);
      this.update(time);
      quat.rotateY(this.orbRotation, this.orbRotation, 0.01);
    }

    this.render(time);
  }

  populateHeroes() {
    const heroMenu: HTMLElement = document.querySelector("#js-hero-menu");
    const sinkMenu: HTMLElement = document.querySelector("#js-sink-menu");
    const confirmAction: HTMLElement = document.querySelector("#js-confirm-action");
    const confirmMenu: HTMLElement = document.querySelector("#js-confirm-menu");
    const subMenus = document.querySelectorAll(".js-sub-menu");
    const menuLinks = heroMenu.querySelectorAll(".js-menu-link");
    const secondaryBacks = document.querySelectorAll(".js-secondary-back");
    const sinkBack = document.querySelectorAll(".js-sink-back");
    const confirmBack = document.querySelectorAll(".js-confirm-back");
    const confirmYes = document.querySelectorAll(".js-confirm-yes");
    const notTornDownOperator = takeWhile(() => !this.tornDown);
    const heroBack = heroMenu.querySelector(".js-back");

    fromEvent(heroBack, "click")
      .pipe()
      .subscribe(() => {
        setHide(heroMenu);
        console.log("hero back click");
        this.currentHero$.next(null);
        this.action$.next(null);
        this.trackedThing$.next(this.center.pos3);
      });

    fromEvent(menuLinks, "click")
      .pipe(notTornDownOperator, pluck("target", "dataset", "menuName"))
      .subscribe((name: string) => {
        const menu = document.querySelector(`#js-${name}-menu`);
        unsetHide(menu);
      });

    fromEvent(secondaryBacks, "click")
      .pipe(notTornDownOperator, pluck("target", "dataset", "menuName"))
      .subscribe((name: string) => {
        const menu = document.querySelector(`#js-${name}-menu`);
        this.action$.next(null);
        setHide(menu);
      });

    fromEvent(sinkBack, "click")
      .pipe(notTornDownOperator)
      .subscribe(() => {
        this.action$.next(null);
        setHide(sinkMenu);
      });

    fromEvent(confirmBack, "click")
      .pipe(notTornDownOperator)
      .subscribe(() => {
        this.sink$.next(null);
        setHide(confirmMenu);
      });

    fromEvent(document, "click")
      .pipe(
        notTornDownOperator,
        pluck("target"),
        filter((el: HTMLElement) => hasClass(el, "js-action")),
        filter((el: HTMLElement) => hasClass(el, s["selectable"]))
      )
      .subscribe(action => {
        const id = action.dataset["id"];
        const battleAction = this.battleActions.find(ba => ba.id === id);
        console.log(id);
        this.action$.next(battleAction);
      });

    fromEvent(document, "click")
      .pipe(
        notTornDownOperator,
        pluck("target"),
        filter((el: HTMLElement) => hasClass(el, "js-sink")),
        filter((el: HTMLElement) => hasClass(el, s["selectable"]))
      )
      .subscribe(sink => {
        const id = sink.dataset["id"];
        const character = this.characters.find(hero => hero.id === id);
        this.sink$.next(character);
      });

    fromEvent(confirmYes, "click")
      .pipe(notTornDownOperator, withLatestFrom(this.currentHero$, this.action$, this.sink$))
      .subscribe(([_, source, action, sink]) => {
        console.log(`${source.longName} will do ${action.name} to ${sink.longName}`);

        const scale$ = defer(() => {
          const maxX = source.sprite.scale[0];
          const minX = -maxX;
          const speed = 0.1;
          let floor = 1.0;

          const nonParticipantSprites = this.spriteRenderer.sprites.filter(
            sprite => sprite.name !== source.sprite.name && sprite.name !== sink.sprite.name
          );

          const turnOnBackground$ = defer(() => {
            this.backgroundEffect = 1.0;
          });

          const fadeFloor$ = this.battleTime$.pipe(
            takeWhile(() => floor > 0.0),
            tap(() => {
              floor -= 0.01;
              this.vertexOpacities.fill(floor);
            })
          );

          const backgroundOpacity$ = this.battleTime$.pipe(
            takeWhile(() => this.backgroundOpacity < 1.0),
            tap(() => {
              this.backgroundOpacity += 0.01;
            })
          );

          const spriteOpacities$ = this.battleTime$.pipe(
            takeWhile(() => nonParticipantSprites.every(sprite => sprite.opacity > 0)),
            tap(() =>
              nonParticipantSprites.forEach(sprite => {
                sprite.opacity -= 0.01;
              })
            )
          );

          const back$ = this.battleTime$.pipe(
            takeWhile(() => source.sprite.scale[0] >= minX),
            tap(() => {
              source.sprite.scale[0] = source.sprite.scale[0] - speed;
            })
          );

          const front$ = this.battleTime$.pipe(
            takeWhile(() => source.sprite.scale[0] <= maxX),
            tap(() => {
              source.sprite.scale[0] = source.sprite.scale[0] + speed;
            })
          );

          const backToFront$ = concat(back$, front$);
          const background$ = merge(
            turnOnBackground$,
            fadeFloor$,
            backgroundOpacity$,
            spriteOpacities$,
            timer(1500)
          );

          const moveOrb$ = defer(() => {
            vec3.set(this.orbScale, 0.1, 0.1, 0.1);
            vec3.copy(this.orbPosition, source.sprite.pos3);
            this.trackedThing$.next(this.orbPosition);
            return this.battleTime$.pipe(
              takeUntil(timer(2000)),
              tap(() => {
                mat4.fromRotationTranslationScale(
                  this.orbModelMatrix,
                  this.orbRotation,
                  this.orbPosition,
                  this.orbScale
                );
              })
            );
          });

          const moveOrbToCenter$ = defer(() => {
            const startTime = state.currentTime;
            const endTime = startTime + 2000;
            const pos = vec3.create();
            vec3.copy(pos, this.center.pos3);
            pos[1] += 2;
            return this.battleTime$.pipe(
              takeWhile(() => !vec3.equals(this.orbPosition, pos)),
              tap(timestamp => {
                const t = clamp(1 - (endTime - timestamp) / (endTime - startTime), 0, 1);
                vec3.lerp(this.orbPosition, this.orbPosition, pos, t);
                vec3.set(
                  this.lookAtPos,
                  this.orbPosition[0],
                  this.orbPosition[1],
                  this.lookAtPos[2]
                );
                mat4.fromRotationTranslationScale(
                  this.orbModelMatrix,
                  this.orbRotation,
                  this.orbPosition,
                  this.orbScale
                );
              })
            );
          });

          const rotateOrb$ = defer(() => {
            return this.battleTime$.pipe(
              takeUntil(timer(750)),
              tap(() => {
                mat4.fromRotationTranslationScale(
                  this.orbModelMatrix,
                  this.orbRotation,
                  this.orbPosition,
                  this.orbScale
                );
              })
            );
          });

          const moveOrbToSink$ = defer(() => {
            const startTime = state.currentTime;
            const endTime = startTime + 2000;
            const pos = vec3.create();
            vec3.copy(pos, sink.sprite.pos3);
            return this.battleTime$.pipe(
              takeWhile(() => !vec3.equals(this.orbPosition, pos)),
              tap(timestamp => {
                const t = clamp(1 - (endTime - timestamp) / (endTime - startTime), 0, 1);
                vec3.lerp(this.orbPosition, this.orbPosition, pos, t);
                vec3.set(
                  this.lookAtPos,
                  this.orbPosition[0],
                  this.orbPosition[1],
                  this.lookAtPos[2]
                );
                mat4.fromRotationTranslationScale(
                  this.orbModelMatrix,
                  this.orbRotation,
                  this.orbPosition,
                  this.orbScale
                );
              })
            );
          });

          const explodeOrb$ = defer(() => {
            this.orbExplodeTime = state.currentTime;
          });

          const orbSize = 0.75;
          const showOrb$ = this.battleTime$.pipe(
            takeWhile(() => this.orbOpacity < 1.0 || this.orbScale[0] < orbSize),
            tap(() => {
              this.orbOpacity += 0.01;
              vec3.set(
                this.orbScale,
                Math.min(this.orbScale[0] + 0.01, orbSize),
                Math.min(this.orbScale[1] + 0.01, orbSize),
                Math.min(this.orbScale[2] + 0.01, orbSize)
              );
            })
          );

          const hideOrb$ = this.battleTime$.pipe(
            takeWhile(() => this.orbOpacity > 0.0),
            tap(() => {
              this.orbOpacity -= 0.01;
            })
          );

          return concat(
            background$,
            backToFront$,
            merge(showOrb$, moveOrb$),
            moveOrbToCenter$,
            rotateOrb$,
            moveOrbToSink$,
            merge(explodeOrb$, rotateOrb$),
            merge(rotateOrb$, concat(hideOrb$, timer(750)))
          );
        });

        const fadeCharacterFunc$ = (character: BattleCharacter) => {
          return defer(() => {
            character.sprite.selected = 2.0;
            return this.battleTime$.pipe(
              takeWhile(() => character.sprite.opacity > 0.0),
              tap(() => {
                character.sprite.opacity -= 0.005;
              })
            );
          });
        };

        const doEffects$ = defer(() => {
          action.effect(sink);
          const finalHp = clamp(sink.hp - action.damage, 0, sink.hp);
          const setHp$ = defer(() => {
            sink.hp = finalHp;
          });

          if (finalHp <= 0) {
            return concat(fadeCharacterFunc$(sink), timer(1000), setHp$);
          } else {
            return setHp$;
          }
        });

        const animation$ = concat(scale$, doEffects$);
        this.animationQueue.add(animation$, source);

        // Hide Menus
        const visibleMenus = Array.from(subMenus)
          .filter(subMenu => getComputedStyle(subMenu).left.indexOf("-") === -1)
          .reverse();
        visibleMenus.forEach((menu, i) => {
          setTimeout(() => {
            setHide(menu);
          }, 120 * i);
        });
      });

    this.currentHero$.subscribe(hero => {
      if (!hero) {
        console.log("No hero...");
        this.heroes.forEach(hero => {
          hero.sprite.selected = 0.0;
          unsetSelected(hero.nameEl);
        });
        return;
      }
      hero.sprite.selected = 1.0;
      setSelected(hero.nameEl);
      unsetHide(heroMenu);
      this.populateSecondaryMenus(hero);
      this.trackedThing$.next(hero.sprite.pos3);
    });

    this.action$.subscribe(action => {
      if (!action) {
        console.log("No action...");
        return;
      }
      unsetHide(sinkMenu);
      fillSinkMenu(sinkMenu, [...this.enemies]);
    });

    this.sink$
      .pipe(withLatestFrom(this.currentHero$, this.action$))
      .subscribe(([sink, source, action]) => {
        if (!sink) {
          console.log("No sink...");
          return;
        }

        confirmAction.innerHTML = `Have ${source.longName} use ${action.name} on ${sink.longName}?`;
        unsetHide(confirmMenu);
      });

    this.trackedThing$.subscribe(trackedThing => {
      this.lookAtTwarn.restart(
        this.lookAtPos,
        [trackedThing[0], trackedThing[1], this.lookAtPos[2]],
        state.currentTime
      );
    });

    this.heroes.forEach((hero, index) => {
      const nameEl: HTMLElement = document.querySelector(`#js-hero-name-${index}`);
      const statsEl: HTMLElement = document.querySelector(`#js-hero-${index}-stats`);
      if (!nameEl || !statsEl) return;
      nameEl.textContent = hero.shortName;
      hero.nameEl = nameEl;
      hero.statsEl = new StatsEl(statsEl, nameEl);
      hero.statsEl.hpEl.innerHTML = `${hero.hp} <span class="${s["display-hidden-medium"]}">/ ${hero.maxHp}</span>`;
      hero.statsEl.mpEl.innerHTML = `${hero.mp} <span class="${s["display-hidden-medium"]}">/ ${hero.maxMp}</span>`;
      hero.statsEl.barEl.style.opacity = "1";
      hero.timer$ = characterTimer$(hero, this.battleTime$).pipe(notTornDownOperator) as Observable<
        number
      >;

      hero.states$ = {
        dead$: hero.timer$.pipe(
          notTornDownOperator,
          map(() => hero.hp === 0),
          distinctUntilChanged()
        ),
        ready$: hero.timer$.pipe(
          notTornDownOperator,
          map(() => hero.wait === 100),
          map(ready => (hero.hp > 0 ? ready : false)),
          distinctUntilChanged()
        )
      };

      const { ready$, dead$ } = hero.states$;

      hero.timer$.subscribe((wait: number) => {
        hero.wait = wait;
      });

      ready$.subscribe((ready: boolean) => {
        if (ready) {
          setReady(hero.statsEl.nameEl);
          setSelectable(hero.statsEl.nameEl);
        } else {
          unsetReady(hero.statsEl.nameEl);
          unsetSelectable(hero.statsEl.nameEl);
        }
      });

      const heroReadyOperator = getFilterWithLatestFromOperator(ready$, v => v);
      const heroAliveOperator = getFilterWithLatestFromOperator(dead$, v => !v);

      fromEvent(nameEl, "click")
        .pipe(
          notTornDownOperator,
          heroReadyOperator,
          heroAliveOperator,
          pluck("target", "dataset", "index"),
          map((index: string) => parseInt(index))
        )
        .subscribe((index: number) => {
          const battleHero = this.heroes[index];
          this.currentHero$.next(battleHero);
        });
    });
  }

  populateSecondaryMenus(hero: BattleCharacter) {
    const magicMenu: HTMLElement = document.querySelector("#js-magic-menu");
    const metaOrbMenu: HTMLElement = document.querySelector("#js-metaorb-menu");
    const itemMenu: HTMLElement = document.querySelector("#js-item-menu");
    this.enableAllSecondaryOptions();
    this.disableSecondaryOptions(hero);
    fillMetaOrbMenu(metaOrbMenu, [hero.metaorb]);
    fillMagicMenu(magicMenu, hero.magic);
    fillItemMenu(itemMenu, hero.items);
  }

  enableAllSecondaryOptions() {
    unsetDisplayNone(document.querySelectorAll(".js-secondary-menu-option"));
  }

  disableSecondaryOptions(hero: BattleCharacter) {
    if (!hero.disabledMenus) return;
    Object.keys(hero.disabledMenus).forEach(key => {
      const type = hero.disabledMenus[key];
      setDisplayNone(document.querySelectorAll(`[data-secondary='${type}']`));
    });
  }

  resetMatrices(forceLookAtCenter = false) {
    const { viz } = state;
    const {
      projectionMatrix,
      fieldOfView,
      zNear,
      zFar,
      viewProjectionMatrix,
      viewMatrix,
      inverseViewProjectionMatrix
    } = viz;
    this.resetViewMatrix(forceLookAtCenter);
    mat4.perspective(projectionMatrix, fieldOfView, state.aspect, zNear, zFar);
    mat4.multiply(viewProjectionMatrix, projectionMatrix, viewMatrix);
    mat4.invert(inverseViewProjectionMatrix, viewProjectionMatrix);
  }

  resetViewMatrix(forceLookAtCenter = false) {
    const { viz } = state;
    const { viewMatrix, camera, up } = viz;
    const { vector: camVector } = camera;
    mat4.identity(viewMatrix);

    const thingToLookAt = forceLookAtCenter ? this.center.pos3 : this.lookAtPos;

    mat4.lookAt(viewMatrix, camVector, thingToLookAt, up);
  }

  render(timestamp: number) {
    const { gameGl } = this;
    this.resetMatrices(true);
    this.updateCharacterPositions();
    this.resetMatrices(false);

    setDrawToScreen(gameGl);
    gameGl.clearColor(0.1, 0.1, 0.1, 0.0);
    gameGl.clearDepth(1.0);
    gameGl.enable(gameGl.BLEND);
    gameGl.blendFunc(gameGl.ONE, gameGl.ONE_MINUS_SRC_ALPHA);
    gameGl.clear(gameGl.COLOR_BUFFER_BIT);
    this.renderBackground(timestamp);
    this.renderFloor(timestamp);
    gameGl.clear(gameGl.DEPTH_BUFFER_BIT);
    this.renderSprites(timestamp);
    this.renderOrbs(timestamp);
    this.renderHeroStats();
    this.gameGl.flush();
  }

  renderHeroStats() {
    this.heroes.forEach(hero => {
      updateCharacterStats(hero);
    });
  }

  update(time: number) {
    this.updateCameraPosition();
    this.lookAtTwarn.update(time);
    updateRain(this);
  }

  updateCharacterPositions() {
    const { viz } = state;
    const { inverseViewProjectionMatrix, camera } = viz;
    const z = this.kingPresident.pos3[2];
    const width = window.innerWidth;
    const paddingPercentage = paddingList.find(paddingPair => width < paddingPair[0])[1];
    const bounds = gamePageBounds(inverseViewProjectionMatrix, camera.vector, z);

    const padding = (bounds.right - bounds.left) * paddingPercentage;

    const rightXAtEdge = bounds.right - this.kingPresident.size[0] / 2 - padding;
    const leftXAtEdge = padding + bounds.left + this.henchperson.size[0] / 2;
    const charXDist = rightXAtEdge - leftXAtEdge;
    const xDist = Math.min(MAX_CHARACTER_DISTANCE, charXDist);
    const leftX = this.center.pos3[0] - xDist / 2;
    const rightX = this.center.pos3[0] + xDist / 2;
    this.henchperson.updatePos(leftX, this.henchperson.pos[1]);
    this.kingPresident.updatePos(rightX, this.kingPresident.pos[1]);

    // const pos = vec3.create();
    // vec3.copy(pos, this.center.pos3);
    // mat4.fromRotationTranslationScale(this.orbModelMatrix, this.orbRotation, pos, this.orbScale);
  }

  updateCameraPosition() {
    const { viz } = state;
    const { camera } = viz;
    const { vector: camVector } = camera;

    let cameraY = camVector[1];
    const atb = document.getElementById("js-atb");
    if (atb) {
      const rect = atb.getBoundingClientRect();
      const point: vec2 = [0, rect.y];
      const coords = unprojectPoint(
        document.body,
        point,
        0,
        viz.inverseViewProjectionMatrix,
        camVector
      );
      const y = -coords[1];
      cameraY += y;
    }

    camVector[0] = numTilesPerRow / 2;
    camVector[1] = cameraY;
    camVector[2] = 2;
  }

  renderBackground(timestamp: number) {
    const { gameGl } = this;
    const { programWrappers } = state;
    const { battleBackground: programWrapper } = programWrappers;
    const { program } = programWrapper;
    const { buffers } = programWrapper;
    const { cache } = programWrapper;
    setDrawToScreen(gameGl);
    gameGl.useProgram(program);
    setPositionsAttribDirect(gameGl, "battleBackground", state, buffers.positions, screenPositions);
    gameGl.uniform2fv(cache.uniforms.uResolution, this.canvasDimensions);
    gameGl.uniform1f(cache.uniforms.uTime, timestamp);
    gameGl.uniform1f(cache.uniforms.uEffect, this.backgroundEffect);
    gameGl.uniform1f(cache.uniforms.uOpacity, this.backgroundOpacity);
    gameGl.drawArrays(gameGl.TRIANGLES, 0, screenPositions.length / 3);
  }

  renderSprites(timestamp: number) {
    this.spriteRenderer.sprites.forEach(sprite => sprite.update(timestamp));
    this.spriteRenderer.renderNoGarbage(timestamp);
  }

  renderOrbs(timestamp: number) {
    const { gameGl, orbModelMatrix } = this;
    const { programWrappers } = state;
    const { orbs: programWrapper } = programWrappers;
    const { program } = programWrapper;
    const { buffers } = programWrapper;
    const { cache } = programWrapper;
    const { viz } = state;
    const { projectionMatrix, viewMatrix } = viz;

    setDrawToScreen(gameGl);
    gameGl.useProgram(program);

    setPositionsAttribDirect(gameGl, "orbs", state, buffers.positions, this.orbVertexPositions);
    setNormalsAttribDirect(gameGl, "orbs", state, buffers.positions, this.orbNormals);
    setIndicesBufferDirect(gameGl, buffers.index, this.orbIndexData);
    setTextureCoordsAttribDirect(
      gameGl,
      "orbs",
      state,
      buffers.textureCoords,
      this.orbTextureCoords
    );
    setOffsetsAttribDirect(gameGl, "orbs", state, buffers.offsets, this.orbOffsets);

    const explodeTime = this.orbExplodeTime === -1 ? -1 : timestamp - this.orbExplodeTime;

    gameGl.uniform1f(cache.uniforms.uTime, timestamp);
    gameGl.uniform1f(cache.uniforms.uOpacity, this.orbOpacity);
    gameGl.activeTexture(gameGl.TEXTURE0);
    gameGl.bindTexture(gameGl.TEXTURE_2D, this.orbTexture);
    gameGl.uniform1i(cache.uniforms.uSpriteSampler, 0);
    gameGl.uniform1f(cache.uniforms.uExplodeTime, explodeTime);
    gameGl.uniformMatrix4fv(cache.uniforms.uProjectionMatrix, false, projectionMatrix);
    gameGl.uniformMatrix4fv(cache.uniforms.uModelMatrix, false, orbModelMatrix);
    gameGl.uniformMatrix4fv(cache.uniforms.uViewMatrix, false, viewMatrix);

    gameGl.frontFace(gameGl.CW);
    gameGl.enable(gameGl.CULL_FACE);
    gameGl.cullFace(gameGl.BACK);
    gameGl.drawElements(gameGl.TRIANGLES, this.orbIndexData.length, gameGl.UNSIGNED_SHORT, 0);
    gameGl.disable(gameGl.CULL_FACE);
    gameGl.frontFace(gameGl.CCW);
  }

  renderFloor(timestamp: number) {
    const { gameGl } = this;
    const { programWrappers } = state;
    const { floor } = programWrappers;
    const { program, cache, buffers } = floor;
    const { viz } = state;
    const { projectionMatrix, modelMatrix, viewMatrix } = viz;
    const {
      vertexNormals,
      vertexBitangents,
      vertexPositions,
      vertexTangents,
      textureCoords,
      vertexOpacities,
      vertexCenters,
      vertexSelecteds
    } = this;
    gameGl.useProgram(program);

    gameGl.uniformMatrix4fv(cache.uniforms.uProjectionMatrix, false, projectionMatrix);
    gameGl.uniformMatrix4fv(cache.uniforms.uModelMatrix, false, modelMatrix);
    gameGl.uniformMatrix4fv(cache.uniforms.uViewMatrix, false, viewMatrix);

    gameGl.activeTexture(gameGl.TEXTURE0);
    gameGl.bindTexture(gameGl.TEXTURE_2D, this.floorTexture);

    gameGl.activeTexture(gameGl.TEXTURE1);
    gameGl.bindTexture(gameGl.TEXTURE_2D, this.floorNormalTexture);

    gameGl.uniform1i(cache.uniforms.uSpriteSampler, 0);
    gameGl.uniform1i(cache.uniforms.uNormalSampler, 1);
    gameGl.uniform1f(cache.uniforms.uTime, timestamp);
    gameGl.uniform1f(cache.uniforms.uBillboard, 0.0);

    gameGl.uniform3fv(cache.uniforms.uLightPos, [numTilesPerRow / 2, 1, -2]);

    setNormalsAttribDirect(gameGl, "floor", state, buffers["normals"], vertexNormals);
    setTangentsAttribDirect(gameGl, "floor", state, buffers["tangents"], vertexTangents);
    setBitangentsAttribDirect(gameGl, "floor", state, buffers["bitangents"], vertexBitangents);
    setPositionsAttribDirect(gameGl, "floor", state, buffers["positions"], vertexPositions);
    setTextureCoordsAttribDirect(gameGl, "floor", state, buffers["textureCoords"], textureCoords);
    setOpacitiesAttribDirect(gameGl, "floor", state, buffers.opacities, vertexOpacities);
    setSelectedsAttribDirect(gameGl, "floor", state, buffers.selecteds, vertexSelecteds);
    setCentersAttribDirect(gameGl, "floor", state, buffers.centers, vertexCenters);

    gameGl.drawArrays(gameGl.TRIANGLES, 0, vertexPositions.length / 3);
  }

  resize = () => {
    const dimensions = dimensionsMaintainingAspectWithMax(
      MAX_DIMENSION,
      state.aspect,
      window.innerWidth,
      window.innerHeight
    );
    const width = dimensions[0];
    const height = dimensions[1];

    this.canvasDimensions.set([width, height]);
    resizeRain(this, width, height);
  };
}

function updateCharacterStats(character: BattleCharacter) {
  character.statsEl.barFillingEl.style.width = `${character.wait}%`;
}

export class StatsEl {
  nameEl: HTMLElement;
  statsEl: HTMLElement;
  hpEl: HTMLElement;
  mpEl: HTMLElement;
  barEl: HTMLElement;
  barFillingEl: HTMLElement;
  constructor(statsEl: HTMLElement, nameEl: HTMLElement) {
    this.statsEl = statsEl;
    this.nameEl = nameEl;
    this.hpEl = statsEl.querySelector(".js-hp");
    this.mpEl = statsEl.querySelector(".js-mp");
    this.barEl = statsEl.querySelector(".js-progress-bar");
    this.barFillingEl = statsEl.querySelector(".js-progress-bar-filling");
  }
}

// https://gist.github.com/davidbitton/1094320
function configOrbData(
  indexData: number[],
  vertexPositionData: number[],
  textureCoordData: number[],
  normalData: number[]
) {
  const radius = 1.0;
  const latitudeBands = 15;
  const longitudeBands = 15;

  // Calculate sphere vertex positions, normals, and texture coordinates.
  for (let latNumber = 0; latNumber <= latitudeBands; ++latNumber) {
    let theta = (latNumber * Math.PI) / latitudeBands;
    let sinTheta = Math.sin(theta);
    let cosTheta = Math.cos(theta);

    for (let longNumber = 0; longNumber <= longitudeBands; ++longNumber) {
      let phi = (longNumber * 2 * Math.PI) / longitudeBands;
      let sinPhi = Math.sin(phi);
      let cosPhi = Math.cos(phi);

      let x = cosPhi * sinTheta;
      let y = cosTheta;
      let z = sinPhi * sinTheta;

      let u = 1 - longNumber / longitudeBands;
      let v = 1 - latNumber / latitudeBands;

      vertexPositionData.push(radius * x);
      vertexPositionData.push(radius * y);
      vertexPositionData.push(radius * z);

      normalData.push(x);
      normalData.push(y);
      normalData.push(z);

      textureCoordData.push(u);
      textureCoordData.push(v);
    }
  }

  // Calculate sphere indices.
  for (let latNumber = 0; latNumber < latitudeBands; ++latNumber) {
    for (let longNumber = 0; longNumber < longitudeBands; ++longNumber) {
      let first = latNumber * (longitudeBands + 1) + longNumber;
      let second = first + longitudeBands + 1;

      indexData.push(first);
      indexData.push(second);
      indexData.push(first + 1);

      indexData.push(second);
      indexData.push(second + 1);
      indexData.push(first + 1);
    }
  }
}
