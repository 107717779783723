import { vec4 } from "gl-matrix";

import { RainDrop } from "interfaces";
import { randomNumberBetween } from "./helpers/math-helpers";

export function updateRain(context: RainContext) {
  const { rainCanvas } = context;
  const rainCtx = rainCanvas.getContext("2d");

  const skyRgb = Math.max(context.opacity - 0.5, 0.1);
  const skyRgbCss = skyRgb * 255;
  const rainRgbCss = Math.min(skyRgbCss + 100, 255);

  vec4.set(context.skyRgba, skyRgb, skyRgb, skyRgb, 1.0);
  const skyColor = `rgb(${skyRgbCss}, ${skyRgbCss}, ${skyRgbCss})`;
  const rainColor = `rgb(${rainRgbCss}, ${rainRgbCss}, ${rainRgbCss})`;

  rainCtx.globalAlpha = context.opacity;
  rainCtx.fillStyle = skyColor;

  rainCtx.globalAlpha = 1.0;
  rainCtx.strokeStyle = rainColor;
  rainCtx.lineWidth = 1;
  rainCtx.clearRect(0, 0, rainCanvas.width, rainCanvas.height);

  rainCtx.beginPath();

  context.rainDropPos.forEach(drop => {
    const { x, y, length, cosAngle, sinAngle, fallSpeed } = drop;
    const updatedDeltaY = drop.deltaY + fallSpeed;
    const dropDeltaLength = updatedDeltaY / cosAngle;
    drop.deltaY = updatedDeltaY;
    const x1 = x + dropDeltaLength * sinAngle;
    const y1 = y + drop.deltaY;
    const x2 = x1 + length * sinAngle;
    const y2 = y1 + length * cosAngle;

    rainCtx.moveTo(x1, y1);
    rainCtx.lineTo(x2, y2);
    if (y1 > window.innerHeight) drop.deltaY = 0;
  });

  rainCtx.stroke();
}

export function resizeRain(context: RainContext, width: number, height) {
  const { rainCanvas } = context;

  rainCanvas.height = height;
  rainCanvas.width = width;
  let maxRainDropLength = height / 10;
  const minRainDropLength = height / 50;
  const maxRainDropSpeed = height / 40;
  const minRainDropSpeed = height / 60;

  context.rainDropPos = context.rainDropPos.map(() => {
    const angle = randomNumberBetween(-Math.PI / 7.5, -Math.PI / 9);
    const length = randomNumberBetween(minRainDropLength, maxRainDropLength);
    const fallSpeed = Math.max((length / maxRainDropLength) * maxRainDropSpeed, minRainDropSpeed);
    return {
      x: randomNumberBetween(-width * 2, width * 2),
      y: randomNumberBetween(-height, 0),
      angle,
      sinAngle: Math.sin(angle),
      cosAngle: Math.cos(angle),
      length,
      deltaY: 0,
      fallSpeed
    };
  });
}

interface RainContext {
  rainCanvas: HTMLCanvasElement;
  opacity: number;
  skyRgba: vec4;
  rainDropPos: Array<RainDrop>;
}
