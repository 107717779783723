import { Settings } from "./interfaces";

const DEFAULT_SETTINGS = {
  soundOn: true
};

export default class SettingsSaver implements Settings {
  get settingsFromLocalStorage(): Settings {
    let settings = { ...DEFAULT_SETTINGS };
    try {
      settings = JSON.parse(localStorage.getItem("settings") || "{}");
    } catch (e) {
      console.error(`Error parsing settings: ${e}`);
      settings = { ...DEFAULT_SETTINGS };
    }
    return settings;
  }

  get soundOn() {
    const value = this.settingsFromLocalStorage.soundOn;
    if (value === undefined) return true;
    return !!value;
  }

  set soundOn(on: boolean) {
    const settings = this.settingsFromLocalStorage;
    settings.soundOn = on;
    this.saveSettings(settings);
  }

  saveSettings(settings: Settings) {
    localStorage.setItem("settings", JSON.stringify(settings));
  }
}
