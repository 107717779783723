import { Howler, Howl } from "howler";
import SettingsSaver from "./settings-saver";

export default class AudioPlayback {
  public static soundBag: SoundBag = {};
  private static instance: AudioPlayback;

  public static getInstance(): AudioPlayback {
    if (!AudioPlayback.instance) {
      AudioPlayback.instance = new AudioPlayback();
      const settings = new SettingsSaver();
      if (settings.soundOn) {
        Howler.mute(false);
      } else {
        Howler.mute(true);
      }
    }

    return AudioPlayback.instance;
  }

  add(name: string, url: string, options = {}) {
    if (!AudioPlayback.soundBag[name])
      AudioPlayback.soundBag[name] = new Howl({ src: [url], ...options });
  }

  play(name: string) {
    const sound = AudioPlayback.soundBag[name];
    if (!sound) {
      console.error(`No sound with name ${name}!`);
      return;
    }

    sound.play();
  }

  stopAll() {
    Object.values(AudioPlayback.soundBag).forEach((howl: Howl) => {
      howl.stop();
    });
  }
}

interface SoundBag {
  [key: string]: Howl;
}
