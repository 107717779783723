import { mat4, vec2, vec3 } from "gl-matrix";

import { unprojectPoint } from "./input-helpers";
import { Bounds } from "../interfaces";

export function randomFloatBetween(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function randomIntBetween(min: number, max: number) {
  return Math.floor(randomFloatBetween(min, max + 1));
}

export function oneOrMinusOne() {
  return Math.round(Math.random()) * 2 - 1;
}

export function round(num: number, decimals = 2) {
  const mult = Math.pow(10, decimals);
  return Math.floor(num * mult) / mult;
}

export function deg2rad(deg: number) {
  return (deg * Math.PI) / 180;
}

export function rad2deg(rad: number) {
  return (rad * 180) / Math.PI;
}

export function randomNumberBetween(min, max) {
  return Math.random() * (max - min) + min;
}

export function overlaps(x1, y1, width1, height1, x2, y2, width2, height2) {
  return x1 + width1 > x2 && x1 < x2 + width2 && y1 + height1 > y2 && y1 < y2 + height2;
}

export function pointInRect(x, y, x1, y1, x2, y2) {
  return x > x1 && x < x2 && Math.abs(y) > Math.abs(y1) && Math.abs(y) < Math.abs(y2);
}

export function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max);
}

export function lerp(start: number, end: number, t: number): number {
  return start * (1 - t) + end * t;
}

export function dimensionsMaintainingAspectWithMax(max, aspect, width, height): vec2 {
  const w = Math.min(max, width);
  const h = Math.min(max / aspect, height);
  return [w, h];
}

export function range(number) {
  return Array.from(Array(number).keys());
}

export function gamePageBounds(
  inverseViewProjectionMatrix: mat4,
  camera: vec3,
  z: number = 0
): Bounds {
  const topLeft = unprojectPoint(document.body, [0, 0], z, inverseViewProjectionMatrix, camera);

  const bottomRight = unprojectPoint(
    document.body,
    [window.innerWidth, window.innerHeight],
    z,
    inverseViewProjectionMatrix,
    camera
  );

  return {
    left: topLeft[0],
    top: topLeft[1],
    right: bottomRight[0],
    bottom: bottomRight[1]
  };
}
